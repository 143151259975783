// Unpublished Work © 2020-2024 Deere & Company.

import {get} from '@deere/translation-service';
import {capitalizeFirstLetter} from 'Utils/translation-utils';
import {MY_JOHN_DEERE} from 'Ui/constants/replace-constants';
import {NAME_EXISTS} from 'Common/constants/errors';

function createMultiWordTranslations(translations) {
    const onEquipSettings = translations.APPLICATION_NAME_SETTINGS.replace('{0}', 'OnEquip');
    const onLaborSettings = translations.APPLICATION_NAME_SETTINGS.replace('{0}', 'OnLabor');
    const manageMyjdSettings = translations.ONLINK_MANAGE_CONFIGURATION.replace('{0}', MY_JOHN_DEERE);
    const accountMismatchError = translations.ONLINK_ACCOUNT_MISMATCH_ERROR.replace('{0}', MY_JOHN_DEERE);

    return {
        '2 Colors': translations.ONLINK_2_COLORS_CUT_STYLE,
        '24-hour Irrigation': translations.ONLINK_24_HOUR_IRRIGATION,
        '24-hour Transfer': translations.ONLINK_24_HOUR_TRANSFER,
        '50/50 - Away Left': translations.ONLINK_50_50_AWAY_LEFT,
        '50/50 - Away Right': translations.ONLINK_50_50_AWAY_RIGHT,
        'Acidfying Agent': translations.ONLINK_ACIDIFYING_AGENT,
        Adjuvant: translations.ONLINK_ADJUVANT,
        'Advance Lease Payment': translations.ONLINK_ADVANCED_LEASE_PAYMENT,
        'Air Temp': translations.ONLINK_AIR_TEMP,
        Approaches: translations.APPROACHES,
        'Approaches Special': translations.ONLINK_APPROACHES_SPEC,
        Apps: translations.APPROACHES,
        'Archived - Not in inventory': translations.ONLINK_ARCHIVED_STATUS,
        Available: translations.AVAILABLE,
        Backtrack: translations.ONLINK_BACKTRACK_CUT_STYLE,
        BioStimulant: translations.ONLINK_BIOSTIMULANT,
        'Bunker Faces': translations.ONLINK_BUNKER_FACES,
        'Bunker Faces Special': translations.ONLINK_BUNKER_FACES_SPEC,
        'Chem Cost': translations.ONLINK_CHEM_COST,
        'Chemical Category Cost': translations.ONLINK_CHEMICAL_CATEGORY_COST,
        'Circle - Clockwise': translations.ONLINK_CIRCLE_CLOCKWISE,
        'Circle - Counter Clockwise': translations.ONLINK_CIRCLE_COUNTER_CLOCKWISE,
        'Clipping Yield': translations.CLIPPING_YIELD,
        'Clipping Yield - Volume': translations.ONLINK_CLIPPING_YIELD_VOLUME,
        'Clipping Yield - Weight': translations.ONLINK_CLIPPING_YIELD_WEIGHT,
        Clockwise: translations.ONLINK_CLOCKWISE_CLEANUP_PASS,
        Collars: translations.COLLARS,
        Colorant: translations.ONLINK_COLORANT,
        'Combination Product': translations.ONLINK_COMBINATION_PRODUCT,
        Connected: translations.ONLINK_CONNECTED_STATE,
        Contour: translations.ONLINK_CONTOUR_CUT_STYLE,
        Cost: translations.COST,
        Course: translations.ONLINK_COURSE,
        completed: translations.COMPLETED_STATUS,
        'Counter Clockwise': translations.ONLINK_COUNTER_CLOCKWISE_CLEANUP_PASS,
        'Cultural Practice': translations.ONLINK_CULTURAL_PRACTICE,
        'Daily Electrical Consumption': translations.ONLINK_DAILY_ELECTRICAL_CONSUMPTION,
        'Daily Electrical Cost': translations.ONLINK_DAILY_ELECTRICAL_COST,
        'Daily Flow': translations.ONLINK_DAILY_FLOW,
        'Daily Fuel': translations.ONLINK_DAILY_FUEL,
        'Daily Revenue': translations.ONLINK_DAILY_REVENUE,
        'Daily Water Cost': translations.ONLINK_DAILY_WATER_COST,
        Declined: translations.DECLINED,
        Decommissioned: translations.ONLINK_DECOMISSIONED_STATUS,
        'Dew Point': translations.WEATHER_DEW_POINT,
        Double: translations.ONLINK_DOUBLE_CUT_STYLE,
        'Driving Range': translations.ONLINK_DRIVING_RANGE,
        EC: translations.ONLINK_EC,
        'Energy Use & Cost': translations.ONLINK_ENERGY_USE_COST,
        Equipment: translations.EQUIPMENT_LIST,
        'Equipment Availability': translations.ONLINK_EQUIPMENT_AVAILABILITY,
        'Equipment Cost': translations.ONLINK_EQUIPMENT_COST,
        'Equipment Monthly': translations.ONLINK_MONTHLY,
        'Equipment Yearly': translations.ONLINK_YEARLY,
        'Estimated Hours': translations.ONLINK_EST_LABOR_HOURS,
        'Estimated Labor Cost': translations.ONLINK_EST_LABOR_COST,
        Evapotranspiration: translations.ONLINK_EVAPOTRANSPIRATION,
        Events: translations.MACHINE_INSIGHTS_EVENTS,
        'F355 Equivalents': 'F355',
        Fairways: translations.FAIRWAYS,
        'Fairways Special': translations.ONLINK_FAIRWAYS_SPEC,
        'Fert Cost': translations.ONLINK_FERT_COST,
        Fertilizer: translations.FERTILIZER,
        'Fertilizer & Chemical': translations.ONLINK_FERTILIZER_AND_CHEMICAL,
        'Fertilizer & Chemical Monthly': translations.ONLINK_MONTHLY,
        'Fertilizer & Chemical Yearly': translations.ONLINK_YEARLY,
        'Fertilizer Category Cost': translations.ONLINK_FERTILIZER_CATEGORY_COST,
        'Field Analyzer': translations.tool_field_analyzer_new,
        'Final Lease Payment': translations.ONLINK_FINAL_LEASE_PAYMENT,
        'Firmness - F355 Equivalents': `${translations.Firmness} - F355`,
        'Firmness - Gravities': `${translations.Firmness} - ${translations.ONLINK_GRAVITIES}`,
        Fuel: translations.DIESEL,
        'Fuel Cost': translations.ONLINK_DIESEL_COST,
        'Fuel Price': translations.ONLINK_DIESEL_PRICE,
        'Fuel and Gas': translations.ONLINK_DIESEL_AND_GAS.toUpperCase(),
        Fungicide: translations.FUNGICIDE,
        Gas: translations.GAS,
        'Gas Cost': translations.ONLINK_GAS_COST,
        'Gas Price': translations.ONLINK_GAS_PRICE,
        'Geo Notes': translations.ONLINK_GEO_NOTES,
        Gravities: translations.ONLINK_GRAVITIES,
        'Green Speed': translations.ONLINK_GREEN_SPEED,
        Greens: translations.GREENS,
        'Greens Special': translations.ONLINK_GREENS_SPEC,
        'Green to Tee': translations.ONLINK_GREEN_TO_TEE_CLEANUP_PASS,
        'Growth Regulator': translations.GROWTH_REGULATOR,
        'HOC Approach': translations.APPROACHES,
        'HOC Approach Spec': translations.ONLINK_APPROACHES_SPEC,
        'HOC Approaches': translations.APPROACHES,
        'HOC Approaches Spec': translations.ONLINK_APPROACHES_SPEC,
        'HOC Approaches Special': translations.ONLINK_APPROACHES_SPEC,
        'HOC Apps': translations.APPROACHES,
        'HOC Bunker Faces': translations.ONLINK_BUNKER_FACES,
        'HOC Bunker Faces Spec': translations.ONLINK_BUNKER_FACES_SPEC,
        'HOC Bunker Faces Special': translations.ONLINK_BUNKER_FACES_SPEC,
        'HOC Bunker Surrounds': translations.ONLINK_BUNKER_SURROUNDS,
        'HOC Bunker Surrounds Spec': translations.ONLINK_BUNKER_SURROUNDS_SPEC,
        'HOC Bunker Surrounds Special': translations.ONLINK_BUNKER_SURROUNDS_SPEC,
        'HOC Collars': translations.COLLARS,
        'HOC Collars Spec': translations.ONLINK_COLLARS_SPEC,
        'HOC Collars Special': translations.ONLINK_COLLARS_SPEC,
        'HOC Fairways': translations.FAIRWAYS,
        'HOC Fairways Spec': translations.ONLINK_FAIRWAYS_SPEC,
        'HOC Fairways Special': translations.ONLINK_FAIRWAYS_SPEC,
        'HOC Greens': translations.GREENS,
        'HOC Greens Spec': translations.ONLINK_GREENS_SPEC,
        'HOC Greens Special': translations.ONLINK_GREENS_SPEC,
        'HOC Intermediate': translations.INTERMEDIATE,
        'HOC Intermediate Spec': translations.ONLINK_INTERMEDIATE_SPEC,
        'HOC Intermediate Special': translations.ONLINK_INTERMEDIATE_SPEC,
        'HOC Other': translations.OTHER,
        'HOC Other Spec': translations.ONLINK_OTHER_SPEC,
        'HOC Other Special': translations.ONLINK_OTHER_SPEC,
        'HOC Rough': translations.ROUGH,
        'HOC Rough Spec': translations.ONLINK_ROUGH_SPEC,
        'HOC Rough Special': translations.ONLINK_ROUGH_SPEC,
        'HOC Settings': translations.ONLINK_HEIGHTS_OF_CUTS_HISTORY,
        'HOC Surrounds': translations.ONLINK_BUNKER_SURROUNDS,
        'HOC Tees': translations.TEES,
        'HOC Tees Spec': translations.ONLINK_TEES_SPEC,
        'HOC Tees Special': translations.ONLINK_TEES_SPEC,
        'Height of Cut': translations.OTHER,
        Herbicide: translations.HERBICIDE,
        Humidity: translations['Observation.HUMIDITY'],
        Imports: translations.ONLINK_IMPORTS,
        Fleet: translations.ONLINK_FLEET,
        'In Shop': translations.ONLINK_IN_SHOP_STATUS,
        'In Use': translations.ONLINK_IN_USE_STATUS,
        Insecticide: translations.INSECTICIDE,
        Intermediate: translations.INTERMEDIATE,
        Invalid: translations.ONLINK_INVALID_STATE,
        'Irrigation Dry Run': translations.ONLINK_IRRIGATION_DRY_RUN,
        'Irrigation Elec Day Cost': translations.ONLINK_IRRIGATION_ELEC_DAY_COST,
        'Irrigation Elec Night Cost': translations.ONLINK_IRRIGATION_ELEC_NIGHT_COST,
        'Irrigation Pump': translations.ONLINK_IRRIGATION_PUMP,
        JDLink: translations.Jdlink,
        'Labor Cost': translations.ONLINK_LABOR_COST,
        'Labor Hours': translations.ONLINK_LABOR_HOURS,
        'Labor Monthly': translations.ONLINK_MONTHLY,
        'Labor Performance Monthly': translations.ONLINK_LABOR_PERFORMANCE_MONTHLY,
        'Labor Performance Yearly': translations.ONLINK_LABOR_PERFORMANCE_YEARLY,
        'Labor Yearly': translations.ONLINK_YEARLY,
        'Lease Payment': translations.ONLINK_LEASE_PAYMENT,
        'Machine Analyzer': translations.tool_machine_analyzer,
        Membership: translations.ONLINK_MEMBERSHIP,
        Miscellaneous: translations.MISCELLANEOUS,
        Natives: translations.ONLINK_NATIVES,
        'Night Rate': translations.ONLINK_NIGHT_RATE,
        Nitrogen: translations.ONLINK_NITROGEN,
        'Nitrogen, TGR & PGR': translations.ONLINK_NITROGEN_TGR_PGR,
        'Not Scheduled': translations.ONLINK_NOT_SCHEDULED,
        Notifications: translations.FIELD_NOTIFICATIONS,
        ONLINK_ACCOUNT_MISMATCH_ERROR: accountMismatchError,
        ONLINK_MANAGE_MYJD_CONFIGURATION: manageMyjdSettings,
        Off: translations.ONLINK_OFF,
        'OnEquip Settings': onEquipSettings,
        'OnLabor Settings': onLaborSettings,
        'Operations Center': translations.MY_OPERATIONS,
        Other: translations.OTHER,
        'Out of Service': translations.ONLINK_OUT_OF_SERVICE_STATUS,
        'Overtime Cost': translations.ONLINK_OVERTIME_COST,
        'Overtime Hours': translations.ONLINK_OVERTIME_HOURS,
        ordered: translations.ONLINK_ORDERED,
        PGR: translations.ONLINK_PGR,
        Partners: translations.organization_partners,
        Parts: translations.PARTS,
        'Peak Rate': translations.ONLINK_PEAK_RATE,
        pending: translations.PENDING_TELEMATICS_STATE,
        Performance: translations.ONLINK_PERFORMANCE,
        'Performance & Elements': translations.ONLINK_PERFORMANCE_ELEMENTS,
        'Playing Conditions': translations.ONLINK_PLAYING_CONDITIONS,
        Precipitation: translations.weather_PRECIPITATION,
        'Price per Round': translations.ONLINK_DAILY_PRICE_PER_ROUND,
        'Property Tax': translations.ONLINK_PROPERTY_TAX,
        Purchase: translations.SALES_SYSTEM_TRANSACTION_PURCHASE,
        received: translations.my_team_received,
        'Regular Cost': translations.ONLINK_REGULAR_COST,
        'Regular Hours': translations.ONLINK_REGULAR_HOURS,
        Repellant: translations.ONLINK_REPELLANT,
        Rough: translations.ROUGH,
        'Rough Special': translations.ONLINK_ROUGH_SPEC,
        'Rounds Played': translations.ONLINK_ROUNDS_PLAYED,
        'Rounds Played Month': translations.ONLINK_MONTHLY,
        'Rounds Played Year': translations.ONLINK_YEARLY,
        'Sales Tax': translations.ONLINK_SALES_TAX,
        'Seed Treatment': translations.ONLINK_SEED_TREATMENT,
        Service: translations.ONLINK_SERVICE,
        'Sick Day': translations.ONLINK_SICK_DAY,
        Single: translations.ONLINK_SINGLE_CUT_STYLE,
        'Single - Green to Tee': translations.ONLINK_SINGLE_GREEN_TO_TEE,
        'Single - Tee to Green': translations.ONLINK_SINGLE_TEE_TO_GREEN,
        'Sky Cover': translations.ONLINK_SKY_COVER,
        'Soil Catalyst': translations.ONLINK_SOIL_CATALYST,
        'Soil Conditions': translations.ONLINK_SOIL_CONDITIONS,
        'Soil Moisture': translations.SOIL_MOISTURE,
        'Soil Temp': translations.ONLINK_SOIL_TEMP,
        Speed: translations.ONLINK_GREEN_SPEED,
        Surrounds: translations.ONLINK_SURROUNDS,
        'Surrounds Special': translations.ONLINK_BUNKER_SURROUNDS_SPEC,
        'System Settings': translations.ONLINK_SYSTEM_SETTINGS,
        TGR: translations.ONLINK_TGR,
        Tees: translations.TEES,
        'Tees Special': translations.ONLINK_TEES_SPEC,
        'Tee to Green': translations.ONLINK_TEE_TO_GREEN_CLEANUP_PASS,
        'Total Revenue': translations.ONLINK_TOTAL_REVENUE,
        'Transfer Dry Run': translations.ONLINK_TRANSFER_DRY_RUN,
        'Transfer Pump': translations.ONLINK_TRANSFER_PUMP,
        'Usage Elec Day Cost': translations.ONLINK_USAGE_ELEC_DAY_COST,
        'Usage Elec Night Cost': translations.ONLINK_USAGE_ELEC_NIGHT_COST,
        Users: translations.ONLINK_USERS,
        Unassigned: translations.ONLINK_UNASSIGNED,
        Vacation: translations.ONLINK_VACATION,
        Water: translations.WATER,
        'Water Cost': translations.ONLINK_WATER_COST,
        'Water Flow': translations.ONLINK_FLOW,
        'Water Irrigation': translations.ONLINK_WATER_IRRIGATION,
        'Water Management': translations.WATER_MANAGEMENT,
        'Water Monthly': translations.ONLINK_MONTHLY,
        'Water Usage': translations.ONLINK_WATER_USAGE,
        'Water Yearly': translations.ONLINK_YEARLY,
        'Wind Speed': translations.WIND_SPEED,
        Yield: translations.CLIPPING_YIELD,
        basket: translations.ONLINK_BASKETS,
        'degrees C': translations.ONLINK_DEGREES_CELSIUS,
        'degrees F': translations.ONLINK_DEGREES_FAHRENHEIT,
        feet: translations.STANDARD_UOM_FOOT,
        gallons: translations.GALLONS,
        gravities: translations.ONLINK_GRAVITIES,
        hours: translations.HOURS,
        inches: capitalizeFirstLetter(translations.INCHES),
        kWh: translations.ONLINK_KWH,
        kilograms: translations.KILOGRAMS,
        kph: translations.KILOMETERS_PER_HOUR_UNIT,
        kwh: translations.ONLINK_KWH,
        liters: translations.STANDARD_UOM_LITER,
        m: translations.meters_uom,
        millimeters: translations.ONLINK_MILLIMETERS,
        mm: translations.MM,
        mph: translations.weather_mph,
        [NAME_EXISTS]: translations.ONLINK_MANUFACTURER_NAME_EXISTS,
        percent: translations.ONLINK_PERCENT,
        pounds: translations.POUNDS,
        quarts: translations.QUARTS,
        Idle: translations.ONLINK_IDLING,
        Working: translations.ONLINK_WORKING,
        Transport: translations.ONLINK_TRANSPORT,
        Charging: translations.ONLINK_CHARGING,
        'Not Charging': translations.ONLINK_NOT_CHARGING,
        Playbooks: translations.ONLINK_PLAYBOOKS,
        'Open Playbooks': translations.ONLINK_OPEN_PLAYBOOKS,
        Workboards: translations.ONLINK_WORKBOARDS,
        Maintenance: translations.ONLINK_MAINTENANCE
    };
}

async function loadTranslations() {
    const translations = await get('onlink');

    return {
        ...translations,
        ...createMultiWordTranslations(translations)
    };
}

export {
    loadTranslations
};
