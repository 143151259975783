// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {clsx} from 'clsx';
import {fetchEffectData} from 'Utils/react-utils';
import {getMowingPatternImageForWorkboard} from 'OnLabor/common/utils/mowing-pattern-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import {TRUE} from 'Ui/constants/membership-properties';

function createMowingPatterns(mowingPatternCompositeImages, translations, tileOpacityOn) {
    return mowingPatternCompositeImages.map((mowingPattern) => {
        const area = translations[mowingPattern.area] || mowingPattern.area;
        const cutStyle = translations[mowingPattern.cuttingStyle] || mowingPattern.cuttingStyle;
        const direction = translations[mowingPattern.direction] || mowingPattern.direction;
        const direction2 = mowingPattern.direction2 ? ` (${mowingPattern.direction2})` : '';

        return (
            <div
                className='mowing-pattern-section'
                key={mowingPattern.equipmentAreaId}
            >
                <div
                    className={clsx('mowing-pattern-text-section', {
                        'opacity-on': tileOpacityOn,
                        'opacity-off': !tileOpacityOn
                    })}
                >
                    <div className='work-item-details-text-line medium-section-title'>
                        {area}
                    </div>
                    <div className='work-item-details-text-line bold-body-text-section-title'>
                        {`${cutStyle} - ${direction}${direction2}`}
                    </div>
                </div>
                <img
                    className='mowing-pattern-image'
                    src={mowingPattern.imgSrc}
                />
            </div>
        );
    });
}

function MowingDirections(props) {
    const {
        membershipProperties,
        workboard,
        translations
    } = props;

    const [loading, setLoading] = React.useState(true);
    const [mowingPatternCompositeImages, setMowingPatternCompositeImages] = React.useState([]);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const mowingPatternImages = await getMowingPatternImageForWorkboard(workboard);

        if (isMounted()) {
            setMowingPatternCompositeImages(mowingPatternImages);
            setLoading(false);
        }
    }), [workboard]);

    const tileOpacityOn = membershipProperties?.workboard_tile_opacity === TRUE;
    const fontSizeNotes = membershipProperties?.workboard_presentation_font_size_notes || '100%';
    const membershipNote = membershipProperties?.workboard_presentation_note;

    const noteClassName = clsx('note', {
        'opacity-on': tileOpacityOn,
        'opacity-off': !tileOpacityOn
    });

    return (
        <LoadingWrapper
            className='dashboard-loading-icon'
            loading={loading}
            size='50px'
        >
            <div className={'mowing-direction-sidebar'}>
                {
                    membershipNote &&
                    <div
                        className={noteClassName}
                        style={{
                            fontSize: fontSizeNotes
                        }}
                    >
                        {membershipNote}
                    </div>
                }
                {
                    workboard?.note &&
                    <div
                        className={noteClassName}
                        style={{
                            fontSize: fontSizeNotes
                        }}
                    >
                        {workboard.note}
                    </div>
                }
                {
                    createMowingPatterns(mowingPatternCompositeImages, translations, tileOpacityOn)
                }
            </div>
        </LoadingWrapper>
    );
}

MowingDirections.propTypes = {
    membershipProperties: PropTypes.object,
    translations: PropTypes.translations,
    workboard: PropTypes.object
};

export default MowingDirections;
