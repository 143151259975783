// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {BasicDialog} from '@deere/basic-dialog';
import {Stepper} from '@deere/isg.component-library/lib/Stepper';
import AddWorkboardSummary from 'OnLabor/workboard/add-workboard-forms/add-workboard-summary';
import AssignmentsFormWrapper from 'Ui/features/workboard-wizard/assignments/assignments-form-wrapper';
import ConfirmationMessage from 'OnLabor/workboard/common/confirmation-message';
import CutsAndDirectionsForm from 'OnLabor/workboard/add-workboard-forms/cuts-and-directions-form';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import SelectJobsForm from 'OnLabor/workboard/add-workboard-forms/select-jobs-form';
import SelectJobsFormReadOnly from 'OnLabor/workboard/edit-workboard-readonly-forms/select-jobs-readonly-form';
import SelectOperatorsForm from 'OnLabor/workboard/add-workboard-forms/select-operators-form';
import WorkboardDetailsForm from 'OnLabor/workboard/add-workboard-forms/workboard-details-form';
import WorkboardDialogActions from 'OnLabor/workboard/add-workboard-forms/workboard-dialog-actions';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {useSave} from 'Ui/react-hooks/use-save';
import {joinClassNames} from 'Utils/html-utils';
import {fetchEffectData, useDeepEffect, useDeepMemo} from 'Utils/react-utils';
import {DATE_FORMATS, formatTime, MANUAL_DATA_TIME_FORMAT} from 'Utils/time-utils';
import {getCutHistory} from 'Services/membership-service';
import {deleteWorkboard} from 'Services/work-board-service';
import {fetchJobTemplates} from 'OnLabor/workboard/utils/fetch-job-templates';
import {fetchOperators} from 'OnLabor/workboard/utils/fetch-operators';
import {initializeEditableWorkboard} from 'OnLabor/workboard/utils/workboard-initialize';
import {saveExistingWorkboard, saveNewWorkboard} from 'OnLabor/workboard/utils/workboard-save';
import dialogTypes from 'Ui/components/common/dialog-types';
import {ONLINK_CREATION_FLOW, WORKBOARD_REFACTOR} from 'Common/constants/feature-toggles';
import {MOWING_PATTERNS} from 'OnLabor/workboard/constants/mowing-patterns';
import {JOB} from 'OnLabor/workboard/constants/workboard-creation-flows';
import {
    WORKBOARD_STEP,
    WORKBOARD_STEPS_BY_JOB,
    WORKBOARD_STEPS_BY_USER
} from 'OnLabor/workboard/constants/workboard-steps';
import {clsx} from 'clsx';
import moment from 'moment';
import {TOAST_TYPE} from '@deere/toast';
import {isEqual} from 'lodash';
import {MANAGE_WORKBOARDS} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';

const DAYS30 = 30;

function getForms(workboardCreationFlow, readOnly) {
    const selectJobsComponent = readOnly ? SelectJobsFormReadOnly : SelectJobsForm;

    return {
        '0': WorkboardDetailsForm,
        '1': CutsAndDirectionsForm,
        '2': workboardCreationFlow === JOB ? selectJobsComponent : SelectOperatorsForm,
        '3': AssignmentsFormWrapper,
        '4': AddWorkboardSummary
    };
}

function checkForInvalidInputs(newStep, setActiveStep, invalidInputs, invalidSteps) {
    if (invalidInputs.size === 0 || !invalidSteps.has(newStep)) {
        setActiveStep(newStep);
    }
}

function hasMissingOperatorsOrJobs(invalidInputs) {
    for (const key of invalidInputs.keys()) {
        if (key.startsWith('operator') || key.startsWith('job')) {
            return true;
        }
    }

    return false;
}

function getInitialWorkboardValues(workboard, membership) {
    if (workboard) {
        return {
            date: workboard.startTime,
            name: workboard.name,
            note: workboard.note
        };
    }

    const defaultDate = membership.properties.workboard_default_start_time ?
        moment().startOf('day').set('hour', membership.properties.workboard_default_start_time) :
        moment();

    return {
        date: defaultDate.format(MANUAL_DATA_TIME_FORMAT),
        name: '',
        note: ''
    };
}

function getConstants(invalidInputs, workboard, membership, translations, featureToggles) {
    const mowingPatterns = useDeepMemo(() => {
        if (workboard) {
            return workboard.mowingPatterns;
        }

        const filteredMowingDirections = membership.mowingDirections.filter(({inactive}) => !inactive);

        return MOWING_PATTERNS.map((mowingPattern) => {
            const mowingDirection = filteredMowingDirections
                .find(({areasApplied}) => areasApplied.includes(mowingPattern.area));

            return {
                area: mowingPattern.area,
                direction: mowingDirection?.direction,
                equipmentAreaId: mowingPattern.equipmentAreaId
            };
        });
    }, [membership.mowingDirections]);

    const invalidSteps = React.useMemo(() => {
        const invalid = new Set();

        if (hasMissingOperatorsOrJobs(invalidInputs)) {
            invalid.add(WORKBOARD_STEP.four);
        }

        if (invalidInputs.has('name')) {
            invalid.add(WORKBOARD_STEP.one).add(WORKBOARD_STEP.two).add(WORKBOARD_STEP.three).add(WORKBOARD_STEP.four);
        }

        return invalid;
    }, [invalidInputs]);

    const creationFlow = featureToggles[ONLINK_CREATION_FLOW] ? workboard?.creationFlow : null;
    const workboardCreationFlow = creationFlow || membership.properties.workboard_creation_flow || JOB;
    const workboardSteps = workboardCreationFlow === JOB ? WORKBOARD_STEPS_BY_JOB : WORKBOARD_STEPS_BY_USER;

    return {
        initialValue: {
            ...getInitialWorkboardValues(workboard, membership),
            jobs: {},
            mowingPatterns,
            workItemsByOperator: {},
            userOrder: []
        },
        invalidSteps,
        steps: workboardSteps.map((workboardStep) => ({
            name: translations[workboardStep]
        })),
        workboardCreationFlow
    };
}

function setUpInitialValues({
    invalidInputs,
    membership,
    translations,
    workboards,
    initialWorkboardId,
    featureToggles
}) {
    const workboard = React.useMemo(
        () => workboards.find((workboard) => workboard.workboardId === initialWorkboardId),
        [workboards, initialWorkboardId]
    );

    const {
        initialValue,
        invalidSteps,
        steps,
        workboardCreationFlow
    } = getConstants(invalidInputs, workboard, membership, translations, featureToggles);

    const [values, setValues] = React.useState(initialValue);

    return {
        initialValue,
        invalidSteps,
        setValues,
        steps,
        values,
        workboard,
        workboardCreationFlow
    };
}

function initializeStateAndRefs({
    invalidInputs,
    membership,
    translations,
    workboards,
    initialWorkboardId,
    featureToggles
}) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [cutHistory, setCutHistory] = React.useState(null);
    const [dialogType, setDialogType] = React.useState({
        isCopy: false,
        isEdit: Boolean(initialWorkboardId)
    });

    const [fleetEquipment, setFleetEquipment] = React.useState({
        equipment: [],
        fleetId: null
    });

    const [workItemsByWorkboardMap, setWorkItemsByWorkboardMap] = React.useState(() => new Map());
    const [loading, setLoading] = React.useState({
        existingWorkboard: true,
        fleetEquipment: true,
        operators: false,
        templates: false
    });

    const [operators, setOperators] = React.useState({
        allOperators: [],
        operatorsMasterList: []
    });

    const [templates, setTemplates] = React.useState([]);

    const [collapseStatus, setCollapseStatus] = React.useState(new Map());

    const [workboardId, setWorkboardId] = React.useState(initialWorkboardId);

    const [showHistoryWidget, setShowHistoryWidget] = React.useState(true);

    const {
        initialValue,
        invalidSteps,
        setValues,
        steps,
        values,
        workboardCreationFlow
    } = setUpInitialValues({
        invalidInputs,
        membership,
        translations,
        workboards,
        initialWorkboardId,
        featureToggles
    });

    return {
        activeStep,
        collapseStatus,
        cutHistory,
        dialogType,
        fleetEquipment,
        initialValuesRef: React.useRef(initialValue),
        invalidSteps,
        loading,
        operators,
        setActiveStep,
        setCollapseStatus,
        setCutHistory,
        setDialogType,
        setFleetEquipment,
        setLoading,
        setOperators,
        setShowHistoryWidget,
        setTemplates,
        setValues,
        setWorkItemsByWorkboardMap,
        setWorkboardId,
        showHistoryWidget,
        steps,
        templates,
        values,
        workboardId,
        workboardCreationFlow,
        workItemsByWorkboardMap
    };
}

function getStepsToDisable(invalidInputs) {
    return invalidInputs.size === 0 ?
        '' :
        clsx('invalid-inputs', {
            'disable-all-steps': invalidInputs.has('name'),
            'disable-step-5': hasMissingOperatorsOrJobs(invalidInputs)
        });
}

function getDialogTitle(dialogType, translations, hasMyJdPermissions) {
    if (dialogType.isCopy) {
        return translations.ONLINK_COPY_WORKBOARD;
    }
    const editWorkboardTitle = hasMyJdPermissions ? translations.ONLINK_EDIT_WORKBOARD : translations.ONLINK_WORKBOARD;

    return dialogType.isEdit ? editWorkboardTitle : translations.ONLINK_ADD_WORKBOARD;
}

function AddWorkboardDialog(props) {
    const {
        addToast,
        closeAddWorkboardDialog,
        closeConfirmation,
        invalidInputs,
        membership,
        onClose,
        openConfirmation,
        setValid,
        translations,
        workboards,
        workboardId: initialWorkboardId,
        featureToggles,
        myJdPermissions,
        isMigrated
    } = props;

    const {
        activeStep,
        collapseStatus,
        cutHistory,
        dialogType,
        fleetEquipment,
        initialValuesRef,
        invalidSteps,
        loading,
        operators,
        setActiveStep,
        setCollapseStatus,
        setCutHistory,
        setDialogType,
        setFleetEquipment,
        setLoading,
        setOperators,
        setShowHistoryWidget,
        setTemplates,
        setValues,
        setWorkItemsByWorkboardMap,
        setWorkboardId,
        showHistoryWidget,
        steps,
        templates,
        values,
        workboardCreationFlow,
        workboardId,
        workItemsByWorkboardMap
    } = initializeStateAndRefs({
        invalidInputs,
        membership,
        translations,
        workboards,
        initialWorkboardId,
        featureToggles
    });

    const hasMyJdPermissions = React.useMemo(() => {
        return isAuthorized({
            myJdPermissions: [MANAGE_WORKBOARDS]
        }, {
            isMigrated,
            myJdPermissions
        });
    }, [isMigrated, myJdPermissions]);

    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const savePayload = {
            membership,
            operators: operators.operatorsMasterList,
            templates,
            values,
            workboardCreationFlow
        };

        const {
            hasError,
            workboardResult
        } = dialogType.isEdit ?
            await saveExistingWorkboard({
                ...savePayload,
                initialValues: initialValuesRef.current,
                workboardId
            }) :
            await saveNewWorkboard(savePayload);

        if (hasError) {
            addToast({
                message: translations.ONLINK_CREATE_WORKBOARD_ERROR_MESSAGE,
                persist: true,
                type: TOAST_TYPE.ERROR
            });
        }

        setWorkboardId(workboardResult.workboardId);
        return workboardResult.workboardId;
    }, {
        disabled: loading.existingWorkboard || loading.operators || loading.templates || invalidSteps.has(activeStep + 1),
        invalidInputs
    });

    function closeBothDialogs() {
        closeConfirmation();
        closeAddWorkboardDialog();
    }

    function deleteActiveWorkboard() {
        openConfirmation({
            message: (
                <ConfirmationMessage
                    message={translations.ONLINK_DELETE_ENTRY_WARNING}
                    translations={translations}
                />
            ),
            onCancel: closeConfirmation,
            async onContinue() {
                await deleteWorkboard(workboardId);

                if (onClose) {
                    onClose();
                }

                closeBothDialogs();
            },
            showLoadingIcon: true,
            title: translations.ONLINK_DELETE_ENTRY,
            translations
        });
    }

    function cancelActiveWorkboard() {
        if (isEqual(initialValuesRef.current, values)) {
            closeAddWorkboardDialog();
        } else {
            openConfirmation({
                message: (
                    <ConfirmationMessage
                        message={translations.ONLINK_CANCEL_WORKBOARD_CONFIRMATION}
                        translations={translations}
                    />
                ),
                onCancel: closeConfirmation,
                onContinue() {
                    closeBothDialogs();
                },
                showLoadingIcon: true,
                title: translations.ONLINK_CANCEL_ENTRY,
                translations
            });
        }
    }

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading((prevLoading) => ({
            ...prevLoading,
            existingWorkboard: true
        }));

        const endTime = formatTime(new Date(), DATE_FORMATS.day);
        const startTime = formatTime(moment().subtract(DAYS30, 'days'), DATE_FORMATS.day);

        const promises = [
            getCutHistory(startTime, endTime),
            fetchOperators({
                date: values.date,
                isMounted,
                setOperators
            })
        ];

        if (dialogType.isEdit) {
            promises.push(
                initializeEditableWorkboard({
                    initialValuesRef,
                    isMounted,
                    setActiveStep,
                    setLoading,
                    setValues,
                    translations,
                    workboardCreationFlow,
                    workboardId,
                    closeAddWorkboardDialog
                })
            );
        }

        const [{cutHistory}] = await Promise.all(promises);

        if (isMounted()) {
            setLoading((prevLoading) => ({
                ...prevLoading,
                existingWorkboard: false
            }));
            setCutHistory(cutHistory);
        }
    }), [workboardId]);

    useDeepEffect(() => fetchEffectData(async (isMounted) => {
        setLoading((prevLoading) => ({
            ...prevLoading,
            templates: true
        }));

        await fetchJobTemplates({
            deletedJobs: values.jobs[translations.ONLINK_DELETED_JOBS],
            isMounted,
            setTemplates,
            translations
        });

        if (isMounted()) {
            setLoading((prevLoading) => ({
                ...prevLoading,
                templates: false
            }));
        }
    }), [values.jobs[translations.ONLINK_DELETED_JOBS]?.length]);

    const StepperForm = getForms(workboardCreationFlow, !hasMyJdPermissions)[activeStep];

    const addWorkboardClassName = featureToggles[WORKBOARD_REFACTOR] ? 'add-workboard-dialog-full-width' : 'add-workboard-dialog';

    return (
        <BasicDialog
            className={joinClassNames(`${addWorkboardClassName} onlink-dialog`, getStepsToDisable(invalidInputs))}
            closeHandler={cancelActiveWorkboard}
            show={true}
            title={getDialogTitle(dialogType, translations, hasMyJdPermissions)}
        >
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading.existingWorkboard || isSaving}
                size='50px'
            >
                <Stepper
                    activeStep={activeStep}
                    className={clsx({
                        stepOneActive: activeStep === WORKBOARD_STEP.zero
                    })}
                    handleStep={(newStep) => checkForInvalidInputs(newStep, setActiveStep, invalidInputs, invalidSteps)}
                    steps={steps}
                />
                <StepperForm
                    collapseStatus={collapseStatus}
                    cutHistory={cutHistory}
                    featureToggles={featureToggles}
                    fleetEquipment={fleetEquipment.equipment}
                    fleetId={fleetEquipment.fleetId}
                    isEdit={dialogType.isEdit}
                    loading={loading}
                    membership={membership}
                    operators={operators}
                    readOnly={!hasMyJdPermissions}
                    setActiveStep={setActiveStep}
                    setCollapseStatus={setCollapseStatus}
                    setFleetEquipment={setFleetEquipment}
                    setLoading={setLoading}
                    setOperators={setOperators}
                    setShowHistoryWidget={setShowHistoryWidget}
                    setTemplates={setTemplates}
                    setValid={setValid}
                    setValues={setValues}
                    setWorkItemsByWorkboardMap={setWorkItemsByWorkboardMap}
                    showHistoryWidget={showHistoryWidget}
                    templates={templates}
                    translations={translations}
                    values={values}
                    workItemsByWorkboardMap={workItemsByWorkboardMap}
                    workboardCreationFlow={workboardCreationFlow}
                    workboardId={workboardId}
                    workboards={workboards}
                />
                <footer className='add-workboard-dialog-footer'>
                    <WorkboardDialogActions
                        activeStep={activeStep}
                        cancelActiveWorkboard={cancelActiveWorkboard}
                        closeAddWorkboardDialog={closeAddWorkboardDialog}
                        closeConfirmation={closeConfirmation}
                        deleteActiveWorkboard={deleteActiveWorkboard}
                        dialogType={dialogType}
                        disableSave={disableSave}
                        featureToggles={featureToggles}
                        hasMyJdPermissions={hasMyJdPermissions}
                        initialValuesRef={initialValuesRef}
                        invalidInputs={invalidInputs}
                        invalidSteps={invalidSteps}
                        membership={membership}
                        onClose={onClose}
                        openConfirmation={openConfirmation}
                        saveFunc={saveFunc}
                        setActiveStep={setActiveStep}
                        setDialogType={setDialogType}
                        setLoading={setLoading}
                        setValues={setValues}
                        steps={steps}
                        translations={translations}
                        values={values}
                        workboardCreationFlow={workboardCreationFlow}
                    />
                </footer>
            </LoadingWrapper>
        </BasicDialog>
    );
}

AddWorkboardDialog.propTypes = {
    addToast: PropTypes.func,
    closeAddWorkboardDialog: PropTypes.func,
    closeConfirmation: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    invalidInputs: PropTypes.instanceOf(Set),
    isMigrated: PropTypes.bool,
    membership: PropTypes.membership,
    myJdPermissions: PropTypes.myJdPermissions,
    onClose: PropTypes.func,
    openConfirmation: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    workboardId: PropTypes.string,
    workboards: PropTypes.arrayOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membership: state.membership,
        translations: state.translations,
        myJdPermissions: state.account.myJdPermissions,
        isMigrated: state.membership.isMigrated
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        },
        closeAddWorkboardDialog() {
            dispatch(closeDialog(dialogTypes.ADD_WORKBOARD_DIALOG));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(AddWorkboardDialog));
