// Unpublished Work © 2022-2024 Deere & Company.

import {getWorkboard} from 'Services/work-board-service';
import {MANUAL_DATA_TIME_FORMAT} from 'Utils/time-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {JOB} from 'OnLabor/workboard/constants/workboard-creation-flows';
import {WORKBOARD_STEP} from 'OnLabor/workboard/constants/workboard-steps';
import moment from 'moment';
import {TRUE} from 'Ui/constants/membership-properties';
import {cloneDeep, maxBy, sortBy} from 'lodash';
import {v4} from 'uuid';

function initializeActiveStep(workboard) {
    const missingCuts = workboard.mowingPatterns.every(({cuttingStyle}) => isNullOrUndefined(cuttingStyle));

    if (missingCuts) {
        return WORKBOARD_STEP.one;
    } else if (workboard.jobs.length === 0) {
        return WORKBOARD_STEP.two;
    }
    return WORKBOARD_STEP.three;
}

function sanitizeWorkItem(workItem, job, isCopy, shouldCopyEstimatedToActual) {
    const {
        jobTemplate,
        jobTemplateId,
        seq
    } = job;

    if (isNullOrUndefined(workItem.equipment)) {
        workItem.equipment = [];
    }

    workItem.jobTemplateId = jobTemplateId;
    workItem.jobSeq = seq;
    if (isCopy) {
        workItem.actualDuration = shouldCopyEstimatedToActual ? workItem.estDuration : null;
        workItem.archivedWorkItemId = workItem.workItemId;
        workItem.workItemId = v4();
    }

    if (jobTemplate) {
        workItem.jobTemplate = jobTemplate;

        jobTemplate.equipmentTypes?.forEach((equipmentType) => {
            const missingEquipmentType = workItem.equipment
                .every(({equipmentTypeId}) => equipmentTypeId !== equipmentType.equipmentTypeId);

            if (missingEquipmentType) {
                workItem.equipment.push({
                    equipmentTypeId: equipmentType.equipmentTypeId,
                    equipmentType: equipmentType.name
                });
            }
        });
    }
}

function initializeJobs(workboard, translations) {
    return workboard.jobs.reduce((jobsByCategory, job) => {
        const {
            jobTemplate,
            workItems
        } = job;

        job.workItems = sortBy(workItems, 'seq');

        const jobCategory = jobTemplate ?
            jobTemplate.jobCategory :
            translations.ONLINK_DELETED_JOBS;

        if (!jobsByCategory[jobCategory]) {
            jobsByCategory[jobCategory] = [];
        }

        jobsByCategory[jobCategory].push(job);

        return jobsByCategory;
    }, {});
}

function copyUserOrder(userOrder, workItemsByOperator) {
    const clonedUserOrder = cloneDeep(userOrder);

    clonedUserOrder?.forEach((userSeq) => {
        userSeq.workItems.forEach((workItemSeq) => {
            const copiedWorkItem = workItemsByOperator[userSeq.userId].find((workItem) => workItem.archivedWorkItemId === workItemSeq.workItemId);

            workItemSeq.workItemId = copiedWorkItem.workItemId;
        });
    });

    return clonedUserOrder;
}

function verifyUserOrder(userOrder, workItemsByOperator) {
    const clonedUserOrder = cloneDeep(userOrder);

    Object.keys(workItemsByOperator).forEach((appUserId) => {
        let userSeq = clonedUserOrder.find((user) => user.userId === appUserId);

        if (!userSeq) {
            const userWithLargestSeq = maxBy(clonedUserOrder, 'seq');

            userSeq = {
                userId: appUserId,
                seq: userWithLargestSeq?.seq ? userWithLargestSeq.seq + 1 : 1,
                workItems: []
            };

            clonedUserOrder.push(userSeq);
        }

        workItemsByOperator[appUserId].forEach((operatorWorkItem) => {
            const existingSeq = userSeq.workItems.find((workItemSeq) => workItemSeq.workItemId === operatorWorkItem.workItemId);

            if (!existingSeq) {
                const workItemWithLargestSeq = maxBy(userSeq.workItems, 'seq');

                userSeq.workItems.push({
                    workItemId: operatorWorkItem.workItemId,
                    seq: workItemWithLargestSeq?.seq ? workItemWithLargestSeq.seq + 1 : 1
                });
            }
        });
    });

    return clonedUserOrder;
}

function initializeUserOrder(workItemsByOperator, userOrder, workboardCreationFlow, isCopy) {
    if (workboardCreationFlow === JOB) {
        return userOrder;
    }

    if (isCopy) {
        return copyUserOrder(userOrder, workItemsByOperator);
    }

    if (userOrder) {
        return verifyUserOrder(userOrder, workItemsByOperator);
    }

    return Object.keys(workItemsByOperator).map((appUserId, index) => ({
        userId: appUserId,
        seq: index + 1,
        workItems: workItemsByOperator[appUserId].map((workItem, index) => ({
            workItemId: workItem.workItemId,
            seq: index + 1
        }))
    }));
}

function initializeWorkItemsByOperator(workboard) {
    return sortBy(workboard.jobs, 'seq').reduce((workItemsByOperator, job) => {
        const {workItems} = job;

        sortBy(workItems, 'seq').forEach((workItem) => {
            if (!workItemsByOperator[workItem.appUserId]) {
                workItemsByOperator[workItem.appUserId] = [];
            }

            workItemsByOperator[workItem.appUserId].push(workItem);
        });

        return workItemsByOperator;
    }, {});
}

function initializeWorkboardFlow(workboard, userOrder, translations, workboardCreationFlow, isCopy, shouldCopyEstimatedToActual) {
    workboard.jobs.forEach((job) => {
        job.workItems.forEach((workItem) => sanitizeWorkItem(workItem, job, isCopy, shouldCopyEstimatedToActual));
    });

    const workItemsByOperator = initializeWorkItemsByOperator(workboard);

    return {
        ...workboard,
        date: workboard.startTime,
        jobs: initializeJobs(workboard, translations),
        userOrder: initializeUserOrder(workItemsByOperator, userOrder, workboardCreationFlow, isCopy),
        workItemsByOperator: workboardCreationFlow === JOB ? {} : workItemsByOperator
    };
}

async function initializeEditableWorkboard({
    initialValuesRef,
    isMounted,
    setActiveStep,
    setValues,
    translations,
    workboardCreationFlow,
    workboardId
}) {
    const {
        userOrder, workboard
    } = await getWorkboard(workboardId);

    if (isMounted()) {
        const updatedActiveStep = initializeActiveStep(workboard);
        const editableWorkboardData = initializeWorkboardFlow(workboard, userOrder, translations, workboardCreationFlow, false);

        setValues(editableWorkboardData);
        setActiveStep(updatedActiveStep);
        initialValuesRef.current = editableWorkboardData;
    }
}

async function getCopiedWorkboardData({
    saveFunc,
    translations,
    values,
    workboardCreationFlow,
    membership
}) {
    const shouldCopyEstimatedToActual = membership.properties.workboard_copy_estimated_to_actual === TRUE;

    const nextDay = moment(values.date).add(1, 'day').format(MANUAL_DATA_TIME_FORMAT);

    const isCopy = true;

    const savedWorkboardId = await saveFunc();

    const {
        userOrder, workboard
    } = await getWorkboard(savedWorkboardId);

    return {
        ...initializeWorkboardFlow(workboard, userOrder, translations, workboardCreationFlow, isCopy, shouldCopyEstimatedToActual),
        date: nextDay
    };
}

export {
    getCopiedWorkboardData,
    initializeEditableWorkboard
};
