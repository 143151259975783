// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import Stack from '@mui/material/Stack';
import EditLink from 'Ui/components/common/data-table/edit-link';

const SHARED_LINK_PROPS = {
    sx: {
        color: 'primary.contrastText',
        ':hover': {
            color: 'primary.contrastText',
            cursor: 'pointer'
        },
        ':visited': {
            color: 'primary.contrastText'
        },
        ':link': {
            color: 'primary.contrastText'
        }
    },
    rel: 'noreferrer'
};

function RedesignFooter(props) {
    const {
        copyrightWithYear,
        hideContent,
        openContactUsDialog,
        openFeedbackDialog,
        openTermsAndConditionsDialog,
        translations
    } = props;

    const {footerLinks} = window.props;

    return (
        <Stack
            alignItems='center'
            bgcolor='primary.main'
            color='primary.contrastText'
            direction='column'
            lineHeight={1.231}
            padding='15px 10px'
            spacing={2}
        >
            <span>{copyrightWithYear}</span>
            {
                !hideContent &&
                <Stack
                    direction='row'
                    flexWrap='wrap'
                    justifyContent='center'
                    spacing={4}
                >
                    <EditLink
                        {...SHARED_LINK_PROPS}
                        onClick={() => openContactUsDialog({
                            byPhoneUrl: footerLinks.byPhoneUrl,
                            contactUsUrl: footerLinks.contactUsUrl,
                            translations
                        })}
                    >
                        {translations.footer_contactUs}
                    </EditLink>
                    <EditLink
                        {...SHARED_LINK_PROPS}
                        href={footerLinks.aboutUsUrl}
                        target='_blank'
                    >
                        {translations.ONLINK_ABOUT_US}
                    </EditLink>
                    <EditLink
                        {...SHARED_LINK_PROPS}
                        onClick={() => openFeedbackDialog({
                            translations
                        })}
                    >
                        {translations.footer_feedback}
                    </EditLink>
                    <EditLink
                        {...SHARED_LINK_PROPS}
                        href={footerLinks.privacyAndDataUrl}
                        target='_blank'
                    >
                        {translations.ONLINK_PRIVACY_AND_DATA}
                    </EditLink>
                    <EditLink
                        {...SHARED_LINK_PROPS}
                        id={'walkme-anchor'}
                        onClick={() => openTermsAndConditionsDialog({
                            translations
                        })}
                    >
                        {translations.terms_of_use_link}
                    </EditLink>
                </Stack>
            }
        </Stack>
    );
}

RedesignFooter.propTypes = {
    copyrightWithYear: PropTypes.string,
    hideContent: PropTypes.bool,
    openContactUsDialog: PropTypes.func,
    openFeedbackDialog: PropTypes.func,
    openTermsAndConditionsDialog: PropTypes.func,
    translations: PropTypes.translations
};

export default RedesignFooter;
