// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import Typography from '@mui/material/Typography';
import {getHoc} from 'Services/hoc-service';
import {DATE_FORMATS} from 'Utils/time-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {formatParts, filterAndFormatServiceTaskTypes, formatServiceTypes} from 'Utils/multiselect/service-multiselect-mappers';
import serviceGroupConstants from 'OnEquip/equipment/service-form/utils/service-group-constants';
import equipmentAreaTranslations from 'Ui/constants/equipment-area-translations';
import serviceStatusCollectionConstants from 'Ui/constants/service-status-collections-constants';
import moment from 'moment';
import {sortBy} from 'lodash';
import {convertToFormattedUoM} from 'Utils/unit-conversion-utils';
import {INCHES} from 'Common/constants/data-unit-constants';

export function getStatusList(status) {
    return serviceStatusCollectionConstants[status] || serviceStatusCollectionConstants.defaults;
}

export function mapFormValuesToServiceData(values, allData) {
    const partsData = values.partsList.map((part) => {
        const quantity = parseInt(part.quantity, 10);

        return {
            ...part,
            isUsed: quantity !== 0,
            quantity
        };
    });

    const formattedExistingServices = values.serviceTasks.map((serviceTask) => ({
        ...serviceTask,
        title: serviceTask.serviceTask
    }));
    const allServiceTasks = [...allData.serviceTasksOptions, ...formattedExistingServices];
    const saveServiceTasksData = values.serviceTasksSelectedIds.map((serviceTaskId) => {
        const fullService = allServiceTasks.find(({serviceTaskTypeId}) => serviceTaskTypeId === serviceTaskId);
        const isChecked = values.serviceTasksCheckedIds.some((serviceTasksCheckedId) => serviceTasksCheckedId === serviceTaskId);

        return {
            ...fullService,
            isUsed: isChecked,
            serviceTask: fullService.title
        };
    });

    const userData = values.assignedTo.map((userId) => {
        let user = allData.serviceAssignableUsers.find(({id}) => id === userId);

        if (isNullOrUndefined(user)) {
            const userFromValues = values.users?.find(({appUserId}) => appUserId === userId);

            user = userFromValues && {
                ...userFromValues,
                id: userFromValues.appUserId
            };
        }

        return {
            appUserId: user.id,
            firstName: user.firstName,
            isUsed: true,
            lastName: user.lastName
        };
    });

    if (values.editableNote) {
        values.notes.push({
            noteTime: Date.now(),
            note: values.editableNote
        });
    }

    return {
        ...values,
        actualDuration: parseInt(values.actualDuration, 10),
        atHours: parseInt(values.atHours, 10),
        ...values.dueAtDate && {
            atDateSelect: moment(values.dueAtDate).format(DATE_FORMATS.day)
        },
        dateScheduled: moment(values.serviceDate).format(DATE_FORMATS.day),
        partsList: partsData,
        estDuration: parseInt(values.estDuration, 10),
        serviceTasks: saveServiceTasksData,
        users: userData,
        totalHours: values.totalHours !== '' ? values.totalHours : null
    };
}

export function mapServiceDataToFormValues(service) {
    const partsList = service.partsList || [];
    const formattedParts = formatParts(partsList);

    const serviceTasksCheckedIds = [];
    const serviceTasksSelectedIds = [];

    const serviceTasks = service.serviceTasks || [];

    serviceTasks.forEach((serviceTask) => {
        serviceTasksSelectedIds.push(serviceTask.serviceTaskTypeId);

        if (serviceTask.isUsed) {
            serviceTasksCheckedIds.push(serviceTask.serviceTaskTypeId);
        }
    });

    const users = service.users || [];
    const sortedUsers = sortBy(users, 'firstName');
    const dueAtDate = service.atDateSelect ? moment(service.atDateSelect) : undefined;

    return {
        ...service,
        dueAtDate,
        dueAtDateRequired: Boolean(dueAtDate),
        serviceDate: moment(service.dateScheduled || new Date()),
        serviceGroup: service.serviceGroup || serviceGroupConstants.maintenance,
        serviceTasksCheckedIds,
        serviceTasksSelectedIds,
        assignedTo: sortedUsers.map(({appUserId}) => appUserId),
        partsList: formattedParts,
        serviceTasks,
        totalHours: service.totalHours ? service.totalHours.toFixed(1) : ''
    };
}

export function mapTypeDataToInputData(users, equipment, serviceTypes, serviceTasks, translations, serviceTypeId) {
    const userNoLongerAvailableWarning = (
        <Typography
            color='#f00'
            component='span'
        >
            {`(${translations.ONLINK_USER_NOT_AVAILABLE})`}
        </Typography>
    );

    const mappedServiceAssignableUsers = users
        .filter(({inactive}) => inactive === false)
        .map((user) => {
            const multiselectItem = {
                ...user,
                id: user.appUserId,
                title: `${user.firstName} ${user.lastName}`
            };

            return user.deleted ? {
                ...multiselectItem,
                extraContent: userNoLongerAvailableWarning
            } : multiselectItem;
        });

    const mappedEquipment = equipment.map((item) => ({
        ...item,
        id: item.equipmentId,
        title: `${item.equipmentName} (${item.manufacturerName} ${item.modelName})`
    }));

    const mappedServiceTypes = formatServiceTypes(serviceTypes, serviceTypeId);
    const mappedServiceTasks = filterAndFormatServiceTaskTypes(serviceTasks, []);

    return {
        mappedEquipment,
        mappedServiceAssignableUsers,
        mappedServiceTypes,
        mappedServiceTasks
    };
}

export async function updateHoc(equipmentList, equipmentId, serviceDate, membership, setHoc, translations) {
    const equipmentAreaId = equipmentList.find(({id}) => id === equipmentId)?.equipmentAreaId;

    if (equipmentAreaId) {
        const {equipmentAreas} = await getHoc(moment(serviceDate).format(DATE_FORMATS.day));
        const equipmentArea = equipmentAreas.find((equipmentArea) => equipmentArea.equipmentAreaId === equipmentAreaId);

        const value = equipmentArea.hocValue || equipmentArea.specialValue;

        if (value) {
            const dataType = equipmentArea.dataType || 'other';

            const hocObject = {
                equipmentArea: translations[equipmentAreaTranslations[dataType]],
                value: convertToFormattedUoM(value, {
                    dataType,
                    dataUnit: INCHES,
                    translations,
                    unitOfMeasure: membership.unitOfMeasure,
                    unitOfMeasureOverrides: membership.unitOfMeasureOverrides
                })
            };

            return setHoc(hocObject);
        }
    }

    return setHoc(null);
}

export function verifyEditableService(initialValues, membershipEquipment, setIsViewableService, setCurrentEquipment) {
    const equipment = membershipEquipment.find(({id}) => id === initialValues.equipmentId);

    equipment ? setCurrentEquipment(equipment) : setIsViewableService(false);
}

export function updateActualEngineHours(equipmentList, equipmentId, setValues, setCurrentEquipment) {
    const equipment = equipmentList.find(({id}) => id === equipmentId);

    setCurrentEquipment(equipment);
    setValues((prevValues) => ({
        ...prevValues,
        totalHours: equipment?.totalHours ? equipment.totalHours.toFixed(1) : ''
    }));
}
