// Unpublished Work © 2021-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import {createUiAxiosClient} from 'Utils/ui-axios-client';
import {filterSuccessfulResponse, reduceResponseData} from 'Common/utils/response-mapper';

async function getMyJDProfile() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/myJohnDeere/users/currentUser/profile');

    return response.data;
}

async function getMyJDUserOrganizations() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/myJohnDeere/users/currentUser/organizations');

    return response.data;
}

async function getMyJDUserMachines() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/myJohnDeere/users/currentUser/machines');

    return response.data
        .filter(filterSuccessfulResponse)
        .reduce(reduceResponseData, []);
}

async function getMyJDUserId() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/myJohnDeere/users/currentUser');

    return response.data;
}

async function getMyJDMakeConnectionsAccess() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/myJohnDeere/users/currentUser/makeConnectionsAccess');

    return response.data;
}

async function getMyJdUserLinksByAppUserIds(appUserIds) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/myJohnDeere/users/links', {
        appUserIds
    });

    return response.data;
}

async function getMyJdOperatorLinksByAppUserIds(appUserIds) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/myJohnDeere/operators/links', {
        appUserIds
    });

    return response.data;
}

async function getCurrentUserMyJDLinks() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/myJohnDeere/users/currentUser/linkedAppUsers');

    return response.data;
}

async function getOrganizationStaff(orgId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/myJohnDeere/organizations/${orgId}/staff`);

    return response.data;
}

async function getOrganizationOperators(orgId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/myJohnDeere/organizations/${orgId}/operators`);

    return response.data;
}

async function batchCreateMyJdOperators(operatorInputs) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/myJohnDeere/organizations/operators', operatorInputs);

    return response.data;
}

async function batchUpsertOperatorLinks(operatorLinkInputs) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/myJohnDeere/users/currentUser/operators', operatorLinkInputs);

    return response.data;
}

async function batchUpsertMyJdUserLinks(myJdUserLinkInputs) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/myJohnDeere/users/currentUser/staff', myJdUserLinkInputs);

    return response.data;
}

async function getMyJDOperator(orgId, operatorId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/myJohnDeere/organizations/${orgId}/operator/${operatorId}`);

    return response.data;
}

async function getMyJDUserByOrgId(orgId, userName) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/myJohnDeere/organizations/${orgId}/staff/${userName}`);

    return response.data;
}

export {
    getMyJDProfile,
    getMyJDUserOrganizations,
    getMyJDUserMachines,
    getMyJDUserId,
    getMyJdUserLinksByAppUserIds,
    getMyJdOperatorLinksByAppUserIds,
    getMyJDMakeConnectionsAccess,
    getCurrentUserMyJDLinks,
    getOrganizationStaff,
    getOrganizationOperators,
    batchCreateMyJdOperators,
    batchUpsertOperatorLinks,
    batchUpsertMyJdUserLinks,
    getMyJDOperator,
    getMyJDUserByOrgId
};
