// Unpublished Work © 2021-2024 Deere & Company. All Worldwide Rights Reserved.
// THIS MATERIAL IS THE PROPERTY OF DEERE & COMPANY.
// ALL USE, ALTERATIONS AND/OR REPRODUCTION NOT SPECIFICALLY
// AUTHORIZED BY DEERE & COMPANY IS PROHIBITED.
import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import MyWorkTile from 'OnLabor/my-work/my-work-tile/my-work-tile';
import WorkBoardsTile from 'OnLabor/my-work/work-board-tile/work-boards-tile';
import WorkBoardNoteTile from 'OnLabor/my-work/work-board-note-tile/work-board-note-tile';
import {fetchEffectData, useDeepMemo} from 'Utils/react-utils';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';
import {getWorkboards} from 'Services/membership-service';
import {MYWORK_WORKBOARD_VIEW} from 'Ui/constants/membership-properties';
import {groupBy, sortBy} from 'lodash';
import moment from 'moment';
import {VIEW_WORKBOARDS} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';

function filterWorkboards(workboards, currentOnly) {
    if (currentOnly) {
        const dateSelect = formatTime(new Date(), DATE_FORMATS.day);

        return workboards.filter((workboard) => workboard.dateSelect === dateSelect);
    }

    return workboards;
}

function shouldShowWorkboardTile(workboardViewSetting, hasPermission) {
    const viewWorkboardTurnedOff = workboardViewSetting === MYWORK_WORKBOARD_VIEW.OFF;

    return workboardViewSetting && !viewWorkboardTurnedOff && hasPermission;
}

function MyWork(props) {
    const {
        translations,
        properties,
        membershipId,
        myJdPermissions,
        isMigrated,
        unitOfMeasure,
        featureToggles
    } = props;

    const workboardViewSetting = properties.mywork_workboard_view;
    const currentOnly = workboardViewSetting === MYWORK_WORKBOARD_VIEW.CURRENT;
    const hasPermission = isAuthorized({
        myJdPermissions: VIEW_WORKBOARDS
    }, props);
    const showWorkboardTile = shouldShowWorkboardTile(workboardViewSetting, hasPermission);

    const [loading, setLoading] = React.useState(() => true);
    const [workboards, setWorkboards] = React.useState(() => []);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const now = new Date();
        const {workboards: workboardsData} = await getWorkboards(hasPermission ? undefined : formatTime(now, DATE_FORMATS.day));
        const filteredWorkboards = workboardsData.filter((workboard) => moment(workboard.dateSelect).isSameOrAfter(now, 'day'));
        const sortedFilteredWorkboards = sortBy(filteredWorkboards, 'startTime');

        if (isMounted()) {
            setWorkboards(sortedFilteredWorkboards);
            setLoading(false);
        }
    }), [membershipId]);

    const workboardsByDate = useDeepMemo(() => {
        const filteredWorkboards = filterWorkboards(workboards, currentOnly);

        return groupBy(filteredWorkboards, (workboard) => formatTime(workboard.dateSelect, DATE_FORMATS.day));
    }, [workboards, currentOnly]);

    return (
        <LoadingWrapper
            className='dashboard-loading-icon'
            loading={loading}
            size='50px'
        >
            <WorkBoardNoteTile workboardsByDate={workboardsByDate}/>
            <MyWorkTile
                hasPermission={hasPermission}
                isMigrated={isMigrated}
                membershipId={membershipId}
                myJdPermissions={myJdPermissions}
                properties={properties}
                translations={translations}
                workboards={workboards}
            />
            {
                showWorkboardTile &&
                <WorkBoardsTile
                    featureToggles={featureToggles}
                    membershipId={membershipId}
                    translations={translations}
                    unitOfMeasure={unitOfMeasure}
                    workboardViewSetting={workboardViewSetting}
                    workboardsByDate={workboardsByDate}
                />
            }
        </LoadingWrapper>
    );
}

MyWork.propTypes = {
    featureToggles: PropTypes.featureToggles,
    isMigrated: PropTypes.bool,
    membershipId: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    properties: PropTypes.object,
    translations: PropTypes.translations,
    unitOfMeasure: PropTypes.string
};

export function mapStateToProps(state) {
    return {
        properties: state.membership.properties,
        membershipId: state.membership.membershipId,
        myJdPermissions: state.account.myJdPermissions,
        isMigrated: state.membership.isMigrated,
        unitOfMeasure: state.membership.unitOfMeasure,
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(MyWork);
