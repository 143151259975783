// Unpublished Work © 2022-2024 Deere & Company.

import {getEquipmentExpenses} from 'Services/equipment-service';
import {dateCompare, genericServiceCompare, numericCompare} from 'Ui/models/maintenance';
import {formatDateOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import accounting from 'accounting';
import {EXPENSE_TYPES as equipmentExpenseTypes} from 'Ui/constants/equipment-expense-types-constants';
import {capitalizeFirstLetter} from 'Utils/translation-utils';

export async function getRowData(equipment, translations) {
    if (equipment?.equipmentId) {
        const {equipmentExpenses} = await getEquipmentExpenses(equipment.equipmentId);

        return equipmentExpenses.map((equipmentExpense) => {
            const expenseType = equipmentExpenseTypes[equipmentExpense.expenseType];

            return {
                ...equipmentExpense,
                formattedDateSelect: formatDateOrDefault(equipmentExpense.dateSelect),
                expenseType: capitalizeFirstLetter(translations[expenseType] || expenseType),
                formattedAmount: `$${accounting.formatNumber(equipmentExpense.amount, 2)}`
            };
        });
    }

    return [];
}

function rowAccessor(row) {
    return row;
}

export function getColumns(translations) {
    return [
        {
            Header: translations.DATE,
            accessor(row) {
                return row;
            },
            id: 'formattedDateSelect',
            sortMethod(a, b) {
                return genericServiceCompare(a, b, dateCompare, 'dateSelect');
            },
            Cell(row) {
                return row.original.formattedDateSelect;
            }
        },
        {
            Header: translations.TYPE,
            accessor: 'expenseType'
        },
        {
            Header: translations.ONLINK_AMOUNT,
            accessor: rowAccessor,
            id: 'amount',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            width: 100,
            sortMethod(a, b) {
                return numericCompare(parseInt(a.amount, 10), parseInt(b.amount, 10));
            },
            Cell(row) {
                return row.original.formattedAmount;
            }
        }
    ];
}
