// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import StatusText from 'OnLabor/workboard/common/status-text';
import {getOvertimeStatus} from 'OnLabor/workboard/utils/overtime-status';
import {useDeepMemo} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {formatMinutesToHours} from 'Utils/time-utils';

function getFormattedOperators({
    operators,
    overtimeReachedThreshold,
    overtimeWarningThreshold,
    translations,
    userRoles
}) {
    const roleNameMap = useDeepMemo(() => userRoles.reduce((roleMap, userRole) => {
        roleMap.set(userRole.userRoleId, userRole.title);

        return roleMap;
    }, new Map()), [userRoles]);

    return useDeepMemo(() => operators.map((operator) => {
        const overtimeStatus = getOvertimeStatus(operator, overtimeReachedThreshold, overtimeWarningThreshold);

        return {
            extraContent: (
                <StatusText
                    dangerColor='#f00'
                    status={overtimeStatus}
                    warningColor='#ff9600'
                >
                    {
                        replaceTranslationNames(translations.ONLINK_HOURS_THIS_WEEK, {
                            '0': formatMinutesToHours(operator.minutesWorkedForWeek)
                        })
                    }
                </StatusText>
            ),
            id: operator.appUserId,
            subtitle: roleNameMap.get(operator.userRoleId),
            title: `${operator.firstName} ${operator.lastName}`,
            equipmentTypes: operator.equipmentTypes
        };
    }), [operators, overtimeReachedThreshold, overtimeWarningThreshold, roleNameMap]);
}

export {
    getFormattedOperators
};
