// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';
import {findLast, sortBy} from 'lodash';

function getCurrentWorkboard(workboardsByDate) {
    const now = new Date();
    const currentTime = now.getTime();
    const formattedDate = formatTime(now, DATE_FORMATS.day);

    const currentDayWorkboards = workboardsByDate[formattedDate];

    const sortedWorkboards = sortBy(currentDayWorkboards, (workboard) => new Date(workboard.startTime).getTime());
    const currentWorkboard = findLast(sortedWorkboards, (workboard) => new Date(workboard.startTime).getTime() <= currentTime);

    return currentWorkboard || (sortedWorkboards.length > 0 ? sortedWorkboards[0] : null);
}

function WorkBoardNoteTile(props) {
    const {workboardsByDate} = props;

    const currentWorkboard = React.useMemo(() => getCurrentWorkboard(workboardsByDate), [workboardsByDate]);

    return (
        currentWorkboard?.note?.length > 0 &&
        <div className='dashboard-panel medium-section-title work-board-note'>
            {currentWorkboard.note}
        </div>
    );
}

WorkBoardNoteTile.propTypes = {
    workboardsByDate: PropTypes.object
};

export default WorkBoardNoteTile;
