// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import ValidationInput from 'Ui/components/common/form/validation-input';

function UpsertEquipmentForm(props) {
    const {
        equipmentName,
        serialNumber,
        setEquipmentName,
        setSerialNumber,
        setValid,
        translations
    } = props;

    const requiredInputError = {
        valueMissing: translations.REQUIRED_FIELD_TEXT
    };

    return (
        <div className='settings-group mobile'>
            <ValidationInput
                errors={requiredInputError}
                label={translations.NAME}
                name='equipmentName'
                onChange={(e) => setEquipmentName(e.target.value)}
                required={true}
                setValid={setValid}
                tabIndex={0}
                value={equipmentName}
            />
            <ValidationInput
                errors={requiredInputError}
                label={translations.ONLINK_SERIAL_NUMBER}
                name='serialNumber'
                onChange={(e) => setSerialNumber(e.target.value)}
                required={true}
                setValid={setValid}
                tabIndex={0}
                value={serialNumber}
            />
        </div>
    );
}

UpsertEquipmentForm.propTypes = {
    equipmentName: PropTypes.string,
    serialNumber: PropTypes.string,
    setEquipmentName: PropTypes.func,
    setSerialNumber: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default UpsertEquipmentForm;
