// Unpublished Work © 2021-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';

async function createEquipment(equipmentData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/equipment', equipmentData);

    return response.data;
}

async function updateEquipment(equipmentData, equipmentId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/equipment/${equipmentId}`, equipmentData);

    return response.data;
}

async function deleteEquipment(equipmentId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/equipment/${equipmentId}`);

    return response.data;
}

async function getEquipment(equipmentId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/equipment/${equipmentId}`);

    return response.data;
}

async function getEquipmentHistory(equipmentId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/equipment/${equipmentId}/equipmentHistory`);

    return response.data;
}

async function getEquipmentExpenses(equipmentId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/equipment/${equipmentId}/equipmentExpenses`);

    return response.data;
}

async function getEquipmentJobHistory(equipmentId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/equipment/${equipmentId}/jobHistory`);

    return response.data;
}

async function getEquipmentServiceStatus(equipmentId, serviceStatus) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/equipment/${equipmentId}/serviceStatus`, {
        params: {
            serviceStatus
        }
    });

    return response.data;
}

async function transferEquipment(transferData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/equipment/transfer', transferData);

    return response.data;
}

async function createEquipmentExpenses(equipmentExpenseData) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/equipmentExpenses', equipmentExpenseData);

    return response.data;
}

async function getEquipmentCosts(equipmentId, dateSelect) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/equipment/${equipmentId}/costReport/${dateSelect}`);

    return response.data;
}

async function updateEngineHours(engineHours, membershipId) {
    const axiosClient = createUiAxiosClient();

    const result = await axiosClient.post('/api/equipment/engineHours', {
        engineHours,
        membershipId
    });

    return result.data;
}

export {
    createEquipment,
    updateEquipment,
    deleteEquipment,
    getEquipment,
    getEquipmentHistory,
    getEquipmentExpenses,
    getEquipmentJobHistory,
    getEquipmentServiceStatus,
    transferEquipment,
    createEquipmentExpenses,
    getEquipmentCosts,
    updateEngineHours
};
