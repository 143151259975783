// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import PresentationJobView from 'Ui/features/onlabor/workboards/presentation/presentation-job-view';
import PresentationEmployeeView from 'Ui/features/onlabor/workboards/presentation/presentation-employee-view';
import {groupBy} from 'lodash';
import {
    getAllWorkItems,
    getPresentationSettings
} from 'Ui/features/onlabor/workboards/presentation/utils/presentation-utils';
import {JOBS} from 'OnLabor/workboard/constants/workboard-creation-flows';
import {ONLINK_CREATION_FLOW} from 'Common/constants/feature-toggles';
import {connect} from 'react-redux';
import {USER} from 'Ui/constants/workboard-constants';
import {sortByEmployeeWorkItemsBySeq, sortWorkItemsByUserOrder} from 'OnLabor/workboard/utils/workboard-sort';
import LoadingIcon from 'Ui/components/common/loading-icon';
import {useDeepEffect, fetchEffectData} from 'Utils/react-utils';

function getCorrectComponent(workItems, presentationSettings, featureToggles, workboardData) {
    const byUser = Object.values(groupBy(workItems, 'appUserId'));
    const byJob = presentationSettings.grouping === JOBS;

    if (featureToggles[ONLINK_CREATION_FLOW] && workboardData.creationFlow === USER) {
        const sortedWorkItemsByUser = sortByEmployeeWorkItemsBySeq(byUser, workboardData.userOrder);

        if (byJob) {
            const sortedWorkItems = sortWorkItemsByUserOrder(workItems, workboardData.userOrder);

            return (
                <PresentationJobView
                    properties={presentationSettings}
                    workItems={sortedWorkItems}
                    workItemsByEmployee={sortedWorkItemsByUser}
                />
            );
        }

        return (
            <PresentationEmployeeView
                properties={presentationSettings}
                workItemsByEmployee={sortedWorkItemsByUser}
            />
        );
    }

    if (byJob) {
        return (
            <PresentationJobView
                properties={presentationSettings}
                workItems={workItems}
                workItemsByEmployee={byUser}
            />
        );
    }
    return (
        <PresentationEmployeeView
            properties={presentationSettings}
            workItemsByEmployee={byUser}
        />
    );
}

function JobsContainer(props) {
    const {
        featureToggles,
        workboardData,
        membership,
        translations
    } = props;

    const presentationSettings = getPresentationSettings(membership);

    const [loading, setLoading] = React.useState(() => true);
    const [workItems, setWorkItems] = React.useState(() => []);

    useDeepEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const workItemsWithProfilePic = await getAllWorkItems(workboardData.jobs, translations, {
            ...presentationSettings,
            showPPEIcon: presentationSettings.showPPE
        });

        if (isMounted()) {
            setWorkItems(workItemsWithProfilePic);
            setLoading(false);
        }
    }), [workboardData.jobs, translations, presentationSettings]);

    if (loading) {
        return (
            <div className='main-page-header'>
                <LoadingIcon
                    className={'dashboard-loading-icon'}
                    size='50px'
                />
            </div>
        );
    }

    return getCorrectComponent(workItems, presentationSettings, featureToggles, workboardData);
}

JobsContainer.propTypes = {
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.object,
    translations: PropTypes.translations,
    workboardData: PropTypes.object
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles
    };
}

export default connect(mapStateToProps)(JobsContainer);
