// Unpublished Work © 2020-2024 Deere & Company.

import '@deere/ui-style-baseline/dist/css/style-baseline.css';
import '@deere/icons/lib/icon.scss';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {prefetchIcon} from '@deere/icons';
import InitialPage from 'Ui/components/initial-page';
import {initRum} from 'Services/rum-service';
import {loadTranslations} from 'Services/translations-service';
import {defaultFontFamily} from 'Ui/constants/defaultFontFamily';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    TimeSeriesScale,
    Tooltip
} from 'chart.js';
import 'chartjs-adapter-moment';
import 'Sass/home/dashboard-panel.scss';
import 'Sass/home/dashboard-panel-data.scss';
import 'Sass/home/tiles/maintenance-due.scss';
import 'Sass/data-table/data-table.scss';
import 'Sass/data-table/data-table-content.scss';
import 'Sass/dialogs/default-dialog.scss';
import 'Sass/dialogs/form-dialog.scss';
import 'Sass/dialogs/layout-edit-dialog.scss';
import 'Sass/dialogs/manual-data-dialog.scss';
import 'Sass/dialogs/onlink-dialog.scss';
import 'Sass/dialogs/settings-dialog.scss';
import 'Sass/dialogs/tile-edit-dialog.scss';
import 'Sass/dialogs/terms-and-conditions-dialog.scss';
import 'Sass/dialogs/contact-us-dialog.scss';
import 'Sass/dialogs/edit-mowing-direction.scss';
import 'Sass/main-content.scss';
import 'Sass/basic-table.scss';
import 'Sass/common.scss';
import 'Sass/form-inputs.scss';
import 'Sass/layout-dropdown.scss';
import 'Sass/link-profile.scss';
import 'Sass/onlink-app.scss';
import 'Sass/secondary-navbar.scss';
import 'Sass/tile.scss';
import 'Sass/graph/graph.scss';
import 'Sass/banner.scss';
import 'Sass/footer.scss';
import 'Sass/login-page.scss';
import 'Sass/thresholds.scss';
import 'Sass/map/map.scss';
import 'Sass/onequip/dashboard.scss';
import 'Sass/onequip/equipment-dialog-form.scss';
import 'Sass/onequip/hoc-page.scss';
import 'Sass/onequip/side-navbar.scss';
import 'Sass/onequip/service-form.scss';
import 'Sass/onequip/equipment-details-dialog.scss';
import 'Sass/onequip/inventory.scss';
import 'Sass/onequip/orders.scss';
import 'Sass/onlabor/workboard.scss';
import 'Sass/onlabor/workboard-presentation.scss';
import 'Sass/onlabor/my-work/dashboard.scss';
import 'Sass/onlabor/my-work/work-board-dialog.scss';
import 'Sass/onlabor/my-work/work-item.scss';
import 'Sass/color-input.scss';
import 'Sass/home/tiles/operator-notes.scss';
import 'Sass/onlabor/onlabor-container.scss';
import 'react-phone-input-2/lib/style.css';

prefetchIcon('IconListView');
prefetchIcon('IconOrgSelection');
prefetchIcon('IconPerson');

export default async () => {
    const translations = await loadTranslations();

    initRum(window.props);

    Chart.register(ArcElement, BarElement, CategoryScale, Filler, Legend, LinearScale,
        LineElement, PointElement, TimeScale, TimeSeriesScale, Tooltip);
    Chart.defaults.font.family = defaultFontFamily;

    const root = createRoot(document.getElementById('app-mount'));

    const element = (
        <InitialPage
            {...window.props}
            translations={translations}
        />
    );

    root.render(element);
};
