// Unpublished Work © 2022-2024 Deere & Company.

import {deleteJob, postJob, updateJob} from 'Services/job-service';
import {postWorkboard, putWorkboard} from 'Services/work-board-service';
import {deleteWorkItem, postWorkItem, putWorkItem} from 'Services/work-item-service';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {filterFailedResponse, filterSuccessfulResponse, reduceResponseData} from 'Common/utils/response-mapper';
import {JOB} from 'OnLabor/workboard/constants/workboard-creation-flows';
import {isEqual} from 'lodash';
import {v4} from 'uuid';

const WORKBOARD_DATE_REGEX = /\d{4}(?:-\d{2}){2}/;

async function saveJobAndWorkItems(job, workboardResult, deletedWorkItemIds, replacementMap) {
    job.workboardId = workboardResult.workboardId;

    const {job: jobResult} = await postJob(job);

    return job.workItems?.filter((workItem) => !isNullOrUndefined(workItem.appUserId)).map((workItem) => {
        workItem.jobId = jobResult.jobId;
        workItem.equipment = workItem.equipment ?
            workItem.equipment.filter((equipment) => !isNullOrUndefined(equipment.equipmentId)) :
            [];

        if (deletedWorkItemIds?.includes(workItem.workItemId)) {
            const newWorkItemId = v4();

            replacementMap.set(workItem.workItemId, newWorkItemId);
            workItem.workItemId = newWorkItemId;
        }

        return postWorkItem(workItem);
    });
}

async function deleteJobAndWorkItems(job) {
    const deleteRequests = job.workItems?.map((workItem) => deleteWorkItem(workItem.workItemId, job.jobId));

    await Promise.allSettled(deleteRequests);

    return [deleteJob(job.jobId)];
}

async function iterateJobs(values, jobFunction, workboardResult) {
    const allJobRequests = Object.keys(values.jobs).reduce((jobRequests, area) => {
        const jobPromises = values.jobs[area].map((job) => jobFunction(job, workboardResult));

        return jobRequests.concat(jobPromises);
    }, []);

    const resolvedRequests = await Promise.allSettled(allJobRequests);

    const allWorkItemRequests = resolvedRequests.filter(filterSuccessfulResponse).reduce(reduceResponseData, []);

    const results = await Promise.allSettled(allWorkItemRequests);

    return results.filter(filterFailedResponse);
}

function getJobsForCategory(jobsForCategory, jobCategory) {
    if (!jobsForCategory[jobCategory]) {
        jobsForCategory[jobCategory] = [];
    }

    return jobsForCategory[jobCategory];
}

function convertOperatorWorkItemsToJobs(workItemsByOperator, templates, operators, membership) {
    const operatorsById = operators.reduce((operatorMap, operator) => {
        operatorMap.set(operator.appUserId, operator);

        return operatorMap;
    }, new Map());

    const templatesById = templates.reduce((templateMap, template) => {
        templateMap.set(template.jobTemplateId, template);

        return templateMap;
    }, new Map());

    return Object.values(workItemsByOperator).reduce((jobsByCategory, workItems) => {
        return workItems.reduce((jobsForCategory, workItem) => {
            const template = templatesById.get(workItem.jobTemplateId);
            const jobs = getJobsForCategory(jobsForCategory, template.jobCategory);

            let existingJob = jobs?.find((job) => job.jobTemplateId === workItem.jobTemplateId);
            const isJobChanged = workItem.jobTemplateId !== workItem.jobTemplate?.jobTemplateId;

            if (!existingJob) {
                existingJob = {
                    estDuration: template.estDuration,
                    jobTemplate: template,
                    jobTemplateId: workItem.jobTemplateId,
                    seq: workItem.jobSeq,
                    startTime: membership.properties.workboard_default_start_time,
                    title: template.title,
                    workItems: [],
                    jobId: isJobChanged ? undefined : workItem.jobId
                };

                jobs.push(existingJob);
            }

            if (!existingJob.seq && workItem.jobSeq) {
                existingJob.seq = workItem.jobSeq;
            }

            existingJob.workItems.push({
                ...workItem,
                appUser: operatorsById.get(workItem.appUserId)
            });

            return jobsForCategory;
        }, jobsByCategory);
    }, {});
}

function getValuesToSave({
    membership,
    operators,
    templates,
    values,
    workboardCreationFlow
}) {
    const jobs = workboardCreationFlow === JOB ?
        values.jobs :
        convertOperatorWorkItemsToJobs(values.workItemsByOperator, templates, operators, membership);

    return {
        ...values,
        dateSelect: values.date.match(WORKBOARD_DATE_REGEX)[0].replace(/-/g, ''),
        endTime: values.date,
        jobs,
        membershipId: membership.membershipId,
        startTime: values.date,
        creationFlow: workboardCreationFlow
    };
}

function compareWorkItems(key, initialWorkItem, savedWorkItem) {
    switch (key) {
        case 'appUser':
        case 'jobTemplate':
            break;
        case 'equipment': {
            const filteredInitialEquipment = initialWorkItem.equipment.filter((equipment) => !isNullOrUndefined(equipment.equipmentId));

            return filteredInitialEquipment.length !== savedWorkItem.equipment.length || savedWorkItem.equipment.some(
                (savedEquipment) => !filteredInitialEquipment?.find((initialEquipment) => initialEquipment.equipmentId === savedEquipment.equipmentId)
            );
        }
        default:
            return initialWorkItem[key] !== savedWorkItem[key];
    }

    return false;
}

async function deleteThenCreateWorkItem(workItem, replacementMap) {
    await deleteWorkItem(workItem.workItemId, workItem.jobId);

    const newWorkItemId = v4();

    replacementMap.set(workItem.workItemId, newWorkItemId);

    workItem.workItemId = newWorkItemId;

    return postWorkItem(workItem);
}

function updateWorkItems(initialWorkItems, savedWorkItems, jobId, deletedWorkItemsList, replacementMap) {
    const workItemRequests = [];

    const initialWorkItemsMap = initialWorkItems.reduce((itemsMap, item) => {
        return itemsMap.set(item.workItemId, item);
    }, new Map());

    const savedWorkItemIds = savedWorkItems.map((workItem) => workItem.workItemId);
    const deletedWorkItemIds = Array.from(initialWorkItemsMap.keys()).filter((key) => !savedWorkItemIds.includes(key));

    savedWorkItems.forEach((workItem) => {
        const initialWorkItem = initialWorkItemsMap.get(workItem.workItemId);

        workItem.equipment = workItem.equipment ?
            workItem.equipment.filter((equipment) => !isNullOrUndefined(equipment.equipmentId)) : [];

        if (!initialWorkItem) {
            workItem.jobId = jobId;
            workItemRequests.push(postWorkItem(workItem));
        } else if (workItem.appUserId === initialWorkItem.appUserId) {
            const shouldUpdate = Object.keys(workItem).some((key) => compareWorkItems(key, initialWorkItem, workItem));

            if (shouldUpdate) {
                workItemRequests.push(putWorkItem(workItem.workItemId, workItem.jobId, workItem));
            }
        } else {
            deletedWorkItemsList.push(workItem.workItemId);
            workItemRequests.push(deleteThenCreateWorkItem(workItem, replacementMap));
        }
    });

    deletedWorkItemsList.push(...deletedWorkItemIds);
    deletedWorkItemIds.map((workItemId) => {
        workItemRequests.push(deleteWorkItem(workItemId, initialWorkItemsMap.get(workItemId).jobId));
    });

    return workItemRequests;
}

function createJobsList(jobsByCategory) {
    return Object.keys(jobsByCategory).reduce((jobsList, category) => {
        const jobs = jobsByCategory[category]?.map((job) => {
            return job;
        });

        return jobsList.concat(jobs);
    }, []);
}

function getDeletedWorkItemIds(deletedJobIds, initialJobs) {
    return deletedJobIds.reduce((workItemIds, jobId) => {
        const job = initialJobs.find((initialJob) => initialJob.jobId === jobId);

        job?.workItems.map((workItem) => workItemIds.push(workItem.workItemId));

        return workItemIds;
    }, []);
}

async function updateJobsAndWorkItems(valuesToSave, initialValues, workboardResult, replacementMap) {
    const savedJobs = createJobsList(valuesToSave.jobs);
    const initialJobs = createJobsList(initialValues.jobs);
    const deletedWorkItemIds = [];

    const allRequests = [];

    const initialJobIds = initialJobs.map((job) => job.jobId);
    const savedJobIds = savedJobs.filter((job) => job.jobId !== undefined).map((job) => job.jobId);
    const deletedJobIds = initialJobIds.filter((key) => !savedJobIds.includes(key));

    if (deletedJobIds?.length > 0) {
        deletedWorkItemIds.push(...getDeletedWorkItemIds(deletedJobIds, initialJobs));
    }

    savedJobs.forEach((job) => {
        if (!job.jobId) {
            allRequests.push(saveJobAndWorkItems(job, workboardResult, deletedWorkItemIds, replacementMap));
        } else if (initialJobIds.includes(job.jobId)) {
            const initialJob = initialJobs?.find((initialJob) => initialJob.jobId === job.jobId);
            const initialWorkItems = initialJob?.workItems;

            if (initialJob && initialJob.seq !== job.seq) {
                allRequests.push(updateJob(job.jobId, {
                    seq: job.seq
                }));
            }

            allRequests.push(...updateWorkItems(initialWorkItems, job.workItems, job.jobId, deletedWorkItemIds, replacementMap));
        }
    });

    deletedJobIds.map((jobId) => {
        allRequests.push(deleteJobAndWorkItems(initialJobs.find((job) => job.jobId === jobId)));
    });

    return await Promise.allSettled(allRequests);
}

function shouldUpdateWorkboard(key, valuesToSave, initialValues) {
    switch (key) {
        case 'note':
        case 'name':
        case 'startTime':
        case 'endTime':
            return initialValues[key] !== valuesToSave[key];
        case 'mowingPatterns': {
            const initialMowingPatterns = initialValues.mowingPatterns;
            const savedMowingpatterns = valuesToSave.mowingPatterns;

            return savedMowingpatterns.some((savedMowingPattern) => {
                const initialMowingPattern = initialMowingPatterns.find((mowingPattern) =>
                    mowingPattern.equipmentAreaId === savedMowingPattern.equipmentAreaId);

                return Object.keys(savedMowingPattern).some((key) => initialMowingPattern[key] !== savedMowingPattern[key]);
            });
        }
        case 'userOrder':
            return !isEqual(initialValues.userOrder, valuesToSave.userOrder);
        default:
            return false;
    }
}

async function saveExistingWorkboard({
    initialValues,
    membership,
    operators,
    templates,
    values,
    workboardCreationFlow,
    workboardId
}) {
    const valuesToSave = getValuesToSave({
        membership,
        operators,
        templates,
        values,
        workboardCreationFlow
    });
    const replacementMap = new Map();
    const resolvedRequests = await updateJobsAndWorkItems(valuesToSave, initialValues, initialValues, replacementMap);
    const allWorkItemRequests = resolvedRequests.filter(filterSuccessfulResponse).reduce(reduceResponseData, []);
    const results = await Promise.allSettled(allWorkItemRequests);

    if (replacementMap.size > 0 && valuesToSave?.userOrder) {
        valuesToSave?.userOrder?.map((userItem) => {
            userItem.workItems?.map((item) => {
                if (replacementMap.has(item.workItemId)) {
                    item.workItemId = replacementMap.get(item.workItemId);
                }
            });
        });
    }

    const updateWorkboard = Object.keys(valuesToSave).some((key) => shouldUpdateWorkboard(key, valuesToSave, initialValues));

    const {workboard: workboardResult} = updateWorkboard && await putWorkboard(workboardId, valuesToSave);

    const rejectedResults = results.filter(filterFailedResponse);

    return {
        hasError: rejectedResults.length > 0,
        workboardResult: workboardResult || initialValues
    };
}

async function saveNewWorkboard({
    membership,
    operators,
    templates,
    values,
    workboardCreationFlow
}) {
    const valuesToSave = getValuesToSave({
        membership,
        operators,
        templates,
        values,
        workboardCreationFlow
    });

    const {workboard: workboardResult} = await postWorkboard(valuesToSave);

    const rejectedResults = await iterateJobs(valuesToSave, saveJobAndWorkItems, workboardResult);

    return {
        hasError: rejectedResults.length > 0,
        workboardResult
    };
}

export {
    saveExistingWorkboard,
    saveNewWorkboard
};
