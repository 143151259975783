// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {BasicDialog} from '@deere/basic-dialog';
import ConfirmationDialog from 'Ui/components/dialogs/confirmation-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import SaveContentBar from 'Ui/components/settings/common/save-content-bar';
import ServiceFormInputs from 'OnEquip/equipment/service-form/service-form-inputs';
import ServiceIncompleteTasksDialog from 'OnEquip/equipment/service-form/service-incomplete-tasks-dialog';
import {
    createService,
    updateService,
    getService,
    getServiceTasks,
    getServiceTypes,
    postServiceLog
} from 'Services/maintenance-service';
import {
    getFilteredFleetEquipment,
    getFleetByMembershipId,
    getMembershipUsersByPermission
} from 'Services/membership-service';
import {getModelMaintenance} from 'Services/model-maintenance-service';
import permissionConstants from 'Ui/constants/permission-constants';
import serviceStatusConstants from 'Ui/constants/service-status-constants';
import {EDIT_SERVICE} from 'Common/constants/business-activities';
import logStateConstants from 'OnEquip/equipment/service-form/utils/log-state-constants';
import serviceGroupConstants from 'OnEquip/equipment/service-form/utils/service-group-constants';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {
    getStatusList,
    mapFormValuesToServiceData,
    mapServiceDataToFormValues,
    mapTypeDataToInputData,
    updateActualEngineHours,
    updateHoc,
    verifyEditableService
} from 'Ui/features/onequip/equipment/service-form/service-form-helper';
import {fetchEffectData} from 'Utils/react-utils';
import {redirect as redirectTo} from 'Utils/redirect-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import moment from 'moment';

function initializeBooleanState() {
    const [loading, setLoading] = React.useState(true);
    const [isViewableService, setIsViewableService] = React.useState(true);
    const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
    const [showIncompleteServiceTasksDialog, setIncompleteServiceTasksDialog] = React.useState(false);
    const [postServiceLogOnConfirmation, setPostServiceLogOnConfirmation] = React.useState(false);

    return {
        loading,
        setLoading,
        isViewableService,
        setIsViewableService,
        showConfirmationDialog,
        setShowConfirmationDialog,
        showIncompleteServiceTasksDialog,
        setIncompleteServiceTasksDialog,
        postServiceLogOnConfirmation,
        setPostServiceLogOnConfirmation
    };
}

function initializeState(serviceId, equipmentId) {
    const [values, setValues] = React.useState(() => ({
        actualDuration: '',
        assignedTo: [],
        atHours: '',
        dueAtDate: moment(),
        dueAtDateRequired: !serviceId,
        editableNote: '',
        estDuration: '',
        equipmentId,
        notes: [],
        partsList: [],
        serviceDate: moment(),
        serviceGroup: serviceGroupConstants.maintenance,
        serviceId,
        serviceTasks: [],
        serviceTasksCheckedIds: [],
        serviceTasksSelectedIds: [],
        serviceTypeId: null,
        status: serviceStatusConstants.recommended.id,
        totalHours: ''
    }));

    const [allData, setAllData] = React.useState(() => ({
        equipmentOptions: [],
        serviceAssignableUsers: [],
        serviceTasksOptions: [],
        serviceTypesOptions: []
    }));

    const [modelMaintenanceServiceTaskIds, setModelMaintenanceServiceTaskIds] = React.useState(() => new Set());
    const [statusCollection, setStatusCollection] = React.useState(() => getStatusList(serviceStatusConstants.recommended.id));
    const [hoc, setHoc] = React.useState(null);
    const [editedServiceStatus, setEditedServiceStatus] = React.useState(null);
    const [skippedService, setSkippedService] = React.useState(null);
    const [currentEquipment, setCurrentEquipment] = React.useState(null);

    return {
        values,
        setValues,
        allData,
        setAllData,
        statusCollection,
        setStatusCollection,
        hoc,
        setHoc,
        editedServiceStatus,
        setEditedServiceStatus,
        skippedService,
        setSkippedService,
        fleetIdRef: React.useRef(),
        currentEquipment,
        setCurrentEquipment,
        ...initializeBooleanState(),
        modelMaintenanceServiceTaskIds,
        setModelMaintenanceServiceTaskIds
    };
}

async function getModelMaintenanceTaskIds(modelMaintenanceId) {
    const {modelMaintenance} = await getModelMaintenance(modelMaintenanceId);
    const modelMaintenanceTaskIds = new Set();

    modelMaintenance?.serviceTasks?.forEach(({serviceTaskTypeId}) => {
        modelMaintenanceTaskIds.add(serviceTaskTypeId);
    });

    return modelMaintenanceTaskIds;
}

function getCombinedUsers(service, users) {
    if (isNullOrUndefined(service?.users)) {
        return users;
    }

    const appUserIds = new Set(users.map(({appUserId}) => appUserId));
    const existingUsers = [];

    service.users.forEach((serviceUser) => {
        if (!appUserIds.has(serviceUser.appUserId)) {
            existingUsers.push({
                ...serviceUser,
                deleted: true,
                inactive: false
            });
        }
    });

    return [...users, ...existingUsers];
}

function getCombinedEquipment(service, equipment) {
    if (isNullOrUndefined(service?.equipmentId)) {
        return equipment;
    }

    const {
        equipmentId,
        equipmentName,
        manufacturerName,
        modelName
    } = service;

    const isEquipmentPresent = equipment.some((equipmentData) => equipmentData.equipmentId === equipmentId);

    if (!isEquipmentPresent) {
        equipment.push({
            equipmentId,
            equipmentName,
            manufacturerName,
            modelName
        });
    }

    return equipment;
}

async function getData(serviceId, membershipId, translations) {
    const dataPromises = [
        getServiceTasks(),
        getFilteredFleetEquipment(),
        getServiceTypes(),
        getMembershipUsersByPermission(permissionConstants.ASSIGNED_TO_SERVICE_TICKETS),
        getFleetByMembershipId(membershipId)
    ];

    if (serviceId) {
        dataPromises.push(getService(serviceId));
    }

    const [
        {serviceTaskTypes},
        equipment,
        {serviceTypes},
        {users},
        {fleets},
        editableService
    ] = await Promise.all(dataPromises);

    const existingService = editableService?.service;
    const combinedUsers = getCombinedUsers(existingService, users);
    const combinedEquipment = getCombinedEquipment(existingService, equipment);

    const inputData = {
        editableService: existingService,
        ...mapTypeDataToInputData(combinedUsers, combinedEquipment, serviceTypes, serviceTaskTypes, translations, existingService?.serviceTypeId),
        fleetId: fleets[0].fleetId
    };

    return existingService?.modelMaintenanceId ? {
        ...inputData,
        modelMaintenanceServiceTaskIds: await getModelMaintenanceTaskIds(existingService.modelMaintenanceId)
    } : inputData;
}

async function saveServiceForm(serviceId, values, allData, updateValues) {
    const serviceData = mapFormValuesToServiceData(values, allData);

    const newService = serviceId ?
        await updateService(serviceData, serviceId) :
        await createService(serviceData);

    updateValues(newService.skippedService || newService.service);

    return newService;
}

function ServiceForm(props) {
    const {
        closeDialogHandler,
        equipmentId,
        history,
        invalidInputs,
        membership,
        myJdPermissions,
        onSaveHandler,
        redirect,
        serviceId,
        setValid,
        translations
    } = props;

    const {
        values,
        setValues,
        loading,
        setLoading,
        allData,
        setAllData,
        statusCollection,
        setStatusCollection,
        hoc,
        setHoc,
        isViewableService,
        setIsViewableService,
        editedServiceStatus,
        setEditedServiceStatus,
        showConfirmationDialog,
        setShowConfirmationDialog,
        showIncompleteServiceTasksDialog,
        setIncompleteServiceTasksDialog,
        skippedService,
        setSkippedService,
        postServiceLogOnConfirmation,
        setPostServiceLogOnConfirmation,
        fleetIdRef,
        currentEquipment,
        setCurrentEquipment,
        modelMaintenanceServiceTaskIds,
        setModelMaintenanceServiceTaskIds
    } = initializeState(serviceId, equipmentId);

    async function editableServiceInitialize(editableService, {
        mappedEquipment = allData.equipmentOptions,
        modelMaintenanceServiceTaskIdsData = modelMaintenanceServiceTaskIds
    } = {}) {
        const initialValues = mapServiceDataToFormValues(editableService);

        verifyEditableService(initialValues, mappedEquipment, setIsViewableService, setCurrentEquipment);

        setValues((prevValues) => ({
            ...prevValues,
            ...initialValues
        }));
        setStatusCollection(getStatusList(editableService.status));
        setEditedServiceStatus(editableService.status);
        setModelMaintenanceServiceTaskIds(modelMaintenanceServiceTaskIdsData);

        await updateHoc(mappedEquipment, initialValues.equipmentId, initialValues.serviceDate, membership, setHoc, translations);
    }

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const {
            editableService,
            mappedEquipment,
            mappedServiceAssignableUsers,
            mappedServiceTasks,
            mappedServiceTypes,
            modelMaintenanceServiceTaskIds: modelMaintenanceServiceTaskIdsData,
            fleetId
        } = await getData(values.serviceId, membership.membershipId, translations);

        fleetIdRef.current = fleetId;

        if (editableService && isMounted()) {
            await editableServiceInitialize(editableService, {
                mappedEquipment,
                modelMaintenanceServiceTaskIdsData
            });
        }

        if (isMounted()) {
            setAllData({
                equipmentOptions: mappedEquipment,
                serviceAssignableUsers: mappedServiceAssignableUsers,
                serviceTasksOptions: mappedServiceTasks,
                serviceTypesOptions: mappedServiceTypes
            });

            setLoading(false);

            if (!editableService && values.equipmentId) {
                await updateHoc(mappedEquipment, values.equipmentId, values.serviceDate, membership, setHoc, translations);
                updateActualEngineHours(mappedEquipment, values.equipmentId, setValues, setCurrentEquipment);
            }
        }
    }), []);

    function cancelServiceUpdate() {
        if (closeDialogHandler) {
            closeDialogHandler();
        } else {
            const redirectUrl = redirect.path
                .replace('{membershipId}', membership.membershipId)
                .replace('{fleetId}', fleetIdRef.current)
                .replace('{equipmentId}', equipmentId || values.equipmentId);

            redirectTo(redirectUrl, redirect.reactRedirect ? history : null);
        }
    }

    function closeOnSave() {
        cancelServiceUpdate();

        if (onSaveHandler) {
            onSaveHandler();
        }
    }

    async function serviceFormOnSave(displayConfirmationDialog) {
        if (values.status === serviceStatusConstants.completed.id &&
            editedServiceStatus !== serviceStatusConstants.completed.id &&
            displayConfirmationDialog) {
            setShowConfirmationDialog(true);
        } else {
            if (postServiceLogOnConfirmation) {
                values.status = serviceStatusConstants.completed.id;
                await postServiceLog(values.serviceId, logStateConstants.stop);
            }

            const newService = await saveServiceForm(values.serviceId, values, allData, editableServiceInitialize);

            if (!displayConfirmationDialog && newService.skippedService) {
                setSkippedService(newService.skippedService);
                setIncompleteServiceTasksDialog(true);
            } else {
                closeOnSave();
            }
        }
    }

    const readOnly = React.useMemo(() => !isAuthorized({
        myJdPermissions: EDIT_SERVICE
    }, {
        isMigrated: membership.isMigrated,
        myJdPermissions
    }), [membership.isMigrated, myJdPermissions]);

    return (
        <>
            <BasicDialog
                className='service-form onlink-dialog'
                closeHandler={cancelServiceUpdate}
                show={true}
                title={translations.ONLINK_SERVICE_TICKET}
            >
                {
                    isViewableService ? (
                        <ServiceFormInputs
                            allData={allData}
                            currentEquipment={currentEquipment}
                            editedServiceStatus={editedServiceStatus}
                            hoc={hoc}
                            invalidInputs={invalidInputs}
                            isMigrated={membership.isMigrated}
                            loading={loading}
                            membership={membership}
                            modelMaintenanceServiceTaskIds={modelMaintenanceServiceTaskIds}
                            readOnly={readOnly}
                            serviceId={serviceId}
                            setCurrentEquipment={setCurrentEquipment}
                            setHoc={setHoc}
                            setValid={setValid}
                            setValues={setValues}
                            statusCollection={statusCollection}
                            timerStatusChange={async (newStatusValue) => {
                                if (!values.serviceId || newStatusValue === serviceStatusConstants.in_process.id) {
                                    const createInProcessService = {
                                        ...values,
                                        status: serviceStatusConstants.in_process.id
                                    };

                                    const createdService = await saveServiceForm(values.serviceId, createInProcessService,
                                        allData, editableServiceInitialize);

                                    await postServiceLog(createdService.service.serviceId, logStateConstants.start);
                                }

                                if (newStatusValue === serviceStatusConstants.completed.id) {
                                    setPostServiceLogOnConfirmation(true);
                                    setShowConfirmationDialog(true);
                                }
                            }}
                            translations={translations}
                            values={values}
                        />
                    ) : (
                        <div className='service-form-error-view'>
                            {translations.ONLINK_SERVICE_TICKET_NOT_FOUND}
                        </div>
                    )
                }

                <SaveContentBar
                    disabled={invalidInputs.size > 0 || loading}
                    onCancelClick={cancelServiceUpdate}
                    onSaveClick={() => serviceFormOnSave(true)}
                    readOnly={readOnly}
                    translations={translations}
                />
            </BasicDialog>
            {
                showConfirmationDialog &&
                <ConfirmationDialog
                    cancelTitle={translations.CANCEL}
                    continueTitle={translations.ONLINK_MARK_AS_COMPLETE}
                    message={translations.ONLINK_SERVICE_COMPLETION_MESSAGE}
                    onCancel={() => {
                        setPostServiceLogOnConfirmation(false);
                        setShowConfirmationDialog(false);
                    }}
                    onContinue={() => serviceFormOnSave(false)}
                    title={translations.confirm}
                />
            }
            {
                showIncompleteServiceTasksDialog &&
                <ServiceIncompleteTasksDialog
                    checkListSelectedIds={values.serviceTasksCheckedIds}
                    multiSelectSelectedIds={values.serviceTasksSelectedIds}
                    redirect={cancelServiceUpdate}
                    serviceTasksData={allData.serviceTasksOptions}
                    setValid={setValid}
                    skippedService={skippedService}
                    translations={translations}
                />
            }
        </>
    );
}

ServiceForm.propTypes = {
    closeDialogHandler: PropTypes.func,
    equipmentId: PropTypes.string,
    history: PropTypes.history,
    invalidInputs: PropTypes.instanceOf(Set),
    membership: PropTypes.membership,
    myJdPermissions: PropTypes.myJdPermissions,
    onSaveHandler: PropTypes.func,
    redirect: PropTypes.object,
    serviceId: PropTypes.string,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default FormValidator(ServiceForm);
