// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {MultiselectFilterList} from '@deere/isg.component-library';
import {useDeepMemo} from 'Utils/react-utils';
import {getOvertimeThresholds} from 'OnLabor/workboard/utils/overtime-status';
import {getFormattedOperators} from 'OnLabor/workboard/utils/operator-utils';
import {cloneDeep} from 'lodash';
import dialogTypes from 'Ui/components/common/dialog-types';
import {closeDialog, openDialog} from 'Store/actions/dialogs';

function subtractWorkHoursForOperators(operatorsToDeselect, workItemsByOperator = {}, setValid) {
    operatorsToDeselect.forEach((deselectedOperator) => {
        workItemsByOperator[deselectedOperator.appUserId].forEach((workItem) => {
            setValid(`job-${workItem.workItemId}`, true);

            deselectedOperator.minutesWorkedForWeek -= workItem.actualDuration;
        });
    });
}

function SelectOperatorsForm(props) {
    const {
        closeConfirmation,
        membership,
        openConfirmation,
        operators,
        setOperators,
        setValid,
        setValues,
        translations,
        userRoles,
        values,
        readOnly
    } = props;

    const selectedOperatorIds = useDeepMemo(() => Object.keys(values.workItemsByOperator || {}), [values.workItemsByOperator]);

    function setOperatorsAndValues(ids, deselectedOperatorIds) {
        setOperators((prevOperators) => {
            const clonedOperators = cloneDeep(prevOperators);

            const operatorsToDeselect = clonedOperators.operatorsMasterList
                .filter((operator) => deselectedOperatorIds.has(operator.appUserId));

            subtractWorkHoursForOperators(operatorsToDeselect, values.workItemsByOperator, setValid);

            return clonedOperators;
        });

        setValues((prevValues) => {
            const {
                workItemsByOperator: prevWorkItemsByOperator = {},
                userOrder: prevUserOrder
            } = prevValues;

            const userOrder = [];

            const workItemsByOperator = ids.reduce((workItemsByOperator, appUserId) => {
                if (!workItemsByOperator[appUserId]) {
                    workItemsByOperator[appUserId] = prevWorkItemsByOperator[appUserId] || [];

                    const previousSeq = prevUserOrder.find((user) => user.userId === appUserId);

                    if (previousSeq) {
                        userOrder.push(previousSeq);
                    } else {
                        const allPreviousSeq = prevUserOrder.map((user) => user.seq);
                        const largestExistingSeq = allPreviousSeq.length !== 0 ? Math.max(...allPreviousSeq) : 0;

                        userOrder.push({
                            seq: largestExistingSeq + 1,
                            userId: appUserId,
                            workItems: []
                        });
                    }
                }

                return workItemsByOperator;
            }, {});

            return {
                ...prevValues,
                userOrder,
                workItemsByOperator
            };
        });
    }

    function onOperatorChange(ids) {
        const deselectedOperatorIds = new Set(selectedOperatorIds.filter((appUserId) => !ids.includes(appUserId)));
        const deslectedOperatorHasWorkItem = deselectedOperatorIds.size > 0 &&
            Object.entries(values.workItemsByOperator).some(([id, workItems]) => deselectedOperatorIds.has(id) && workItems.length > 0);

        if (deslectedOperatorHasWorkItem) {
            const message = deselectedOperatorIds.size > 1 ? translations.ONLINK_DESELECT_ALL_OPERATORS_WARNING : translations.ONLINK_REMOVE_OPERATOR_WARNING;

            openConfirmation({
                title: translations.ONLINK_REMOVE_OPERATOR,
                message,
                onContinue: () => {
                    setOperatorsAndValues(ids, deselectedOperatorIds);
                    closeConfirmation();
                },
                onCancel: () => {
                    closeConfirmation();
                }
            });
        } else {
            setOperatorsAndValues(ids, deselectedOperatorIds);
        }
    }

    const {
        overtimeReachedThreshold,
        overtimeWarningThreshold
    } = getOvertimeThresholds(
        membership.properties.workboard_weekly_work_hours,
        membership.properties.workboard_employee_daily_hours_limit
    );

    const operatorItems = getFormattedOperators({
        operators: operators.operatorsMasterList,
        overtimeReachedThreshold,
        overtimeWarningThreshold,
        translations,
        userRoles
    });

    const selectedOperators = operatorItems?.filter((operator) => selectedOperatorIds.includes(operator.id));

    const readOnlyComponent = (<div
        className='add-workboard-form select-form-columns'
    >
        {
            selectedOperators?.length > 0 ? <>
                <div className={'title-1 selected-jobs-title'}> {translations.ONLINK_SELECTED_OPERATORS} </div>
                <div className={'selected-jobs-list'}>
                    {
                        selectedOperators?.map((operator) => {
                            return (
                                <div key={operator.id}>
                                    <div className={'job-title'}>{operator.title}</div>
                                    <div className={'job-subtitle'}>{operator.subtitle}</div>
                                </div>
                            );
                        })
                    }
                </div>
            </> : <div className={'title-1'}>{translations.ONLINK_NO_OPERATORS_SELECTED}</div>
        }
    </div>);

    return readOnly ? readOnlyComponent : (
        <div className='add-workboard-form select-operators-form select-form-columns'>
            <MultiselectFilterList
                emptyListLabel={translations.IWP_SELECT_NO_OPERATORS}
                items={operatorItems}
                noItemMatchLabel={translations.IWP_SELECT_NO_OPERATORS}
                onChange={onOperatorChange}
                selectAllItemLabel={translations.SELECT_ALL}
                selectedIds={selectedOperatorIds}
            />
        </div>
    );
}

SelectOperatorsForm.propTypes = {
    closeConfirmation: PropTypes.func,
    membership: PropTypes.membership,
    openConfirmation: PropTypes.func,
    operators: PropTypes.object,
    readOnly: PropTypes.bool,
    setOperators: PropTypes.func,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    userRoles: PropTypes.arrayOf(PropTypes.object),
    values: PropTypes.object
};

export function mapStateToProps(state) {
    return {
        userRoles: state.role.userRoles
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectOperatorsForm);
