// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip/Tooltip';
import {IconEdit, IconTerminal} from '@deere/icons';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {joinClassNames} from 'Utils/html-utils';
import {createIconFillStyle} from 'Utils/icon-utils';
import {formatStatus, formatTimeOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import {formatModelName} from 'Ui/models/equipment-model';
import equipmentStatusConstants from 'Ui/constants/equipment-status-constants';
import {openDialog as openReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';

const ICON_STYLE = {
    style: {
        height: '100%',
        width: '15px'
    }
};

const ICON_EDIT_STYLE = {
    style: {
        display: 'inline',
        fill: '#fff',
        height: '20px',
        marginRight: '5px',
        width: '20px'
    }
};

const PRIMARY_STYLE = createIconFillStyle('#fff');

function Header(props) {
    const {
        equipment,
        openDialog,
        translations,
        updateEquipment,
        canEditEquipmentList
    } = props;

    if (!equipment) {
        return null;
    }

    const {
        equipmentName,
        lastReportedMtgId,
        manufacturerName,
        serialNumber,
        status,
        totalHours,
        timeLastMeterReading
    } = equipment;

    const modelDescription = formatModelName(equipment);
    const statusConstant = equipmentStatusConstants[status] || {};
    const formattedStatus = formatStatus(equipment, translations);
    const formattedLastMeterReading = formatTimeOrDefault(timeLastMeterReading);

    return (
        <div className='settings-body'>
            <div className='settings-group horizontal'>
                <span className='medium-section-title'>
                    {equipmentName}
                </span>
                {
                    canEditEquipmentList &&
                    <div className='edit-button'>
                        <OnlinkButton
                            className='primary'
                            leftIcon={
                                <IconEdit
                                    iconEdit={ICON_EDIT_STYLE}
                                    primary={PRIMARY_STYLE}
                                />
                            }
                            onClick={() => {
                                openDialog({
                                    equipment,
                                    updateFleetEquipment: updateEquipment
                                });
                            }}
                        >
                            {translations.EDIT}
                        </OnlinkButton>
                    </div>
                }
            </div>
            <div className='settings-group mobile'>
                <span className='body-text-section-title'>
                    {`${manufacturerName} ${modelDescription}`}
                </span>
                <span className='small-body-text last-item'>
                    {`${totalHours.toFixed(
                        1)} ${translations.HOURS.toUpperCase()}`}
                </span>
            </div>
            <div className='settings-group mobile'>
                <span className='small-body-text'>
                    {serialNumber}
                </span>
                <div className={'small-body-text last-item'}>
                    {`${translations.UPDATED}: ${formattedLastMeterReading}`}
                </div>
            </div>
            <div className='settings-group mobile'>
                {
                    lastReportedMtgId &&
                    <div className='terminal-id'>
                        <Tooltip
                            placement='bottom'
                            title={translations.ONLINK_TELEMATICALLY_ENABLED}
                        >
                            <div>
                                <IconTerminal iconTerminal={ICON_STYLE}/>
                            </div>
                        </Tooltip>
                        <span className='small-body-text telematically-enabled-text'>
                            {`${translations.TERMINAL_ID.toUpperCase()}: ${lastReportedMtgId}`}
                        </span>
                    </div>
                }
                <span
                    className={joinClassNames(
                        'small-body-text equipment-status last-item',
                        statusConstant.color
                    )}
                >
                    {formattedStatus}
                </span>
            </div>
        </div>
    );
}

Header.propTypes = {
    canEditEquipmentList: PropTypes.bool,
    equipment: PropTypes.equipment,
    openDialog: PropTypes.func,
    translations: PropTypes.translations,
    updateEquipment: PropTypes.func
};

export function mapDispatchToProps(dispatch) {
    return {
        openDialog(props) {
            dispatch(openReduxDialog(dialogTypes.EQUIPMENT_EDIT_DIALOG, props));
        }
    };
}

export default connect(null, mapDispatchToProps)(Header);
