// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import dialogTypes from 'Ui/components/common/dialog-types';
import ConfirmationDialog from 'Ui/components/dialogs/confirmation-dialog';
import DepartmentEditDialog from 'Ui/components/dialogs/department-edit-dialog';
import EquipmentCreateDialog from 'Ui/components/dialogs/equipment-create-dialog';
import EquipmentEditDialog from 'Ui/components/dialogs/equipment-edit-dialog';
import CheckEquipmentDialog from 'Ui/components/dialogs/check-equipment-dialog';
import HocEditDialog from 'Ui/components/dialogs/hoc-edit-dialog';
import LayoutEditDialog from 'Ui/components/dialogs/layout-edit-dialog';
import MembershipSelectDialog from 'Ui/components/dialogs/membership-select-dialog';
import TileEditDialog from 'Ui/components/dialogs/tile-edit-dialog';
import RoleEditDialog from 'Ui/components/dialogs/role-edit-dialog';
import ThresholdEditDialog from 'Ui/components/dialogs/threshold-edit-dialog';
import BulkEngineHoursDialog from 'Ui/components/dialogs/bulk-engine-hours-dialog';
import PartDialog from 'Ui/components/dialogs/part-dialog';
import ServiceFormDialog from 'Ui/components/dialogs/service-form-dialog';
import EquipmentTransferDialog from 'Ui/components/dialogs/equipment-transfer-dialog';
import AddExpenseDialog from 'Ui/components/dialogs/add-expense-dialog';
import ManualDataDialog from 'Ui/components/manual-data/manual-data-dialog';
import ManualDataEntryDialog from 'Ui/components/manual-data/manual-data-entry-dialog';
import TermsAndConditionsDialog from 'Ui/components/dialogs/terms-and-conditions-dialog';
import ContactUsDialog from 'Ui/components/dialogs/contact-us-dialog';
import CourseNameDialog from 'Ui/components/dialogs/course-name-dialog';
import SensorCreateDialog from 'Ui/components/dialogs/sensor-create-dialog';
import AddWorkboardDialog from 'Ui/components/dialogs/add-workboard-dialog';
import JobCategoryDialog from 'Ui/components/dialogs/job-category-dialog';
import JobDialog from 'Ui/components/dialogs/job-dialog';
import EmployeeStatusDialog from 'Ui/components/dialogs/employee-status-dialog';
import QuickTodoDialog from 'Ui/components/dialogs/quick-todo-dialog';
import AddTimeOffDialog from 'Ui/features/time-off/add-time-off-dialog';
import AddTodoDialog from 'Ui/components/dialogs/add-todo-dialog';
import ManageTodoDialog from 'Ui/components/dialogs/manage-todo-dialog';
import EditMowingDirectionDialog from 'Ui/components/dialogs/edit-mowing-direction-dialog';
import WorkboardPreviewDialog from 'Ui/components/dialogs/workboard-preview-dialog';
import TransferInventoryDialog from 'Ui/components/dialogs/transfer-inventory-dialog';
import PullInventoryDialog from 'Ui/components/dialogs/pull-inventory-dialog';
import StockInventoryDialog from 'Ui/components/dialogs/stock-inventory-dialog';
import PartBinDialog from 'Ui/features/dialogs/part-bin-dialog';
import AddEditPurchaseOrderDialog from 'Ui/features/dialogs/add-edit-purchase-order-dialog/add-edit-purchase-order-dialog';
import PurchaseOrderDialog from 'Ui/features/dialogs/purchase-order-dialog';
import BinLocationDialog from 'Ui/features/dialogs/bin-location-dialog';
import ManufacturerDialog from 'Ui/features/dialogs/manufacturer-dialog';
import ReceiveOrderDialog from 'Ui/features/dialogs/receive-order-dialog';
import PartTypeDialog from 'Ui/features/dialogs/part-type-dialog';
import AddEditMaintenanceDialog from 'Ui/features/dialogs/add-edit-maintenance-dialog';
import DealershipDialog from 'Ui/features/dialogs/dealership-dialog';
import ErrorDialog from 'Ui/features/dialogs/error-dialog';
import FeedbackDialog from 'Ui/features/dialogs/feedback-dialog';
import MigrationConfirmationDialog from 'Ui/features/dialogs/migration-confirmation-dialog';
import WorkItemDetailsDialog from 'OnLabor/my-work/my-work-dialog/work-item-details-dialog';

const DIALOG_COMPONENTS = {
    [dialogTypes.HOC_EDIT_DIALOG]: HocEditDialog,
    [dialogTypes.EQUIPMENT_CREATE_DIALOG]: EquipmentCreateDialog,
    [dialogTypes.EQUIPMENT_EDIT_DIALOG]: EquipmentEditDialog,
    [dialogTypes.CHECK_EQUIPMENT_DIALOG]: CheckEquipmentDialog,
    [dialogTypes.LAYOUT_EDIT_DIALOG]: LayoutEditDialog,
    [dialogTypes.MEMBERSHIP_SELECT_DIALOG]: MembershipSelectDialog,
    [dialogTypes.DEPARTMENT_EDIT_DIALOG]: DepartmentEditDialog,
    [dialogTypes.COURSE_NAME_DIALOG]: CourseNameDialog,
    [dialogTypes.TILE_EDIT_DIALOG]: TileEditDialog,
    [dialogTypes.ROLE_EDIT_DIALOG]: RoleEditDialog,
    [dialogTypes.THRESHOLD_EDIT_DIALOG]: ThresholdEditDialog,
    [dialogTypes.BULK_ENGINE_HOURS_DIALOG]: BulkEngineHoursDialog,
    [dialogTypes.PART_DIALOG]: PartDialog,
    [dialogTypes.SERVICE_FORM_DIALOG]: ServiceFormDialog,
    [dialogTypes.EQUIPMENT_TRANSFER_DIALOG]: EquipmentTransferDialog,
    [dialogTypes.ADD_EXPENSE_DIALOG]: AddExpenseDialog,
    [dialogTypes.MANUAL_DATA_DIALOG]: ManualDataDialog,
    [dialogTypes.MANUAL_DATA_ENTRY_DIALOG]: ManualDataEntryDialog,
    [dialogTypes.CONFIRMATION_DIALOG]: ConfirmationDialog,
    [dialogTypes.TERMS_AND_CONDITIONS_DIALOG]: TermsAndConditionsDialog,
    [dialogTypes.CONTACT_US_DIALOG]: ContactUsDialog,
    [dialogTypes.SENSOR_CREATE_DIALOG]: SensorCreateDialog,
    [dialogTypes.ADD_WORKBOARD_DIALOG]: AddWorkboardDialog,
    [dialogTypes.JOB_CATEGORY_DIALOG]: JobCategoryDialog,
    [dialogTypes.JOB_DIALOG]: JobDialog,
    [dialogTypes.EMPLOYEE_STATUS_DIALOG]: EmployeeStatusDialog,
    [dialogTypes.QUICK_TODO_DIALOG]: QuickTodoDialog,
    [dialogTypes.MANAGE_TODO_DIALOG]: ManageTodoDialog,
    [dialogTypes.ADD_TIME_OFF_DIALOG]: AddTimeOffDialog,
    [dialogTypes.ADD_TODO_DIALOG]: AddTodoDialog,
    [dialogTypes.EDIT_MOWING_DIRECTION_DIALOG]: EditMowingDirectionDialog,
    [dialogTypes.WORKBOARD_PREVIEW_DIALOG]: WorkboardPreviewDialog,
    [dialogTypes.TRANSFER_INVENTORY_DIALOG]: TransferInventoryDialog,
    [dialogTypes.PULL_INVENTORY_DIALOG]: PullInventoryDialog,
    [dialogTypes.STOCK_INVENTORY_DIALOG]: StockInventoryDialog,
    [dialogTypes.PART_BIN_DIALOG]: PartBinDialog,
    [dialogTypes.ADD_EDIT_PURCHASE_ORDER_DIALOG]: AddEditPurchaseOrderDialog,
    [dialogTypes.PURCHASE_ORDER_DIALOG]: PurchaseOrderDialog,
    [dialogTypes.BIN_LOCATION_DIALOG]: BinLocationDialog,
    [dialogTypes.MANUFACTURER_DIALOG]: ManufacturerDialog,
    [dialogTypes.RECEIVE_ORDER_DIALOG]: ReceiveOrderDialog,
    [dialogTypes.PART_TYPE_DIALOG]: PartTypeDialog,
    [dialogTypes.ADD_EDIT_MAINTENANCE_DIALOG]: AddEditMaintenanceDialog,
    [dialogTypes.DEALERSHIP_DIALOG]: DealershipDialog,
    [dialogTypes.ERROR_DIALOG]: ErrorDialog,
    [dialogTypes.FEEDBACK_DIALOG]: FeedbackDialog,
    [dialogTypes.MIGRATION_CONFIRMATION_DIALOG]: MigrationConfirmationDialog,
    [dialogTypes.WORK_ITEM_DETAILS_DIALOG]: WorkItemDetailsDialog
};

function Dialogs(props) {
    const {
        openDialogs, isMigrated
    } = props;

    return Object.keys(DIALOG_COMPONENTS)
        .filter((key) => openDialogs[key])
        .map((key) => {
            const DialogComponent = DIALOG_COMPONENTS[key];

            const {
                props: dialogProps,
                dialogOrder
            } = openDialogs[key];

            return (
                <div
                    className={isMigrated ? `dialog-number-${dialogOrder} jd-green` : `dialog-number-${dialogOrder}`}
                    key={key}
                >
                    <DialogComponent {...dialogProps}/>
                </div>
            );
        });
}

Dialogs.propTypes = {
    isMigrated: PropTypes.bool,
    openDialogs: PropTypes.objectOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        openDialogs: state.dialogs.openDialogs,
        isMigrated: state.membership.isMigrated
    };
}

export default connect(mapStateToProps)(Dialogs);
