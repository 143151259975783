// Unpublished Work © 2021-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';
import {getDateRange} from 'Utils/time-utils';
import moment from 'moment';

async function getMyJDBreadcrumbs(machineId, dateSelected) {
    const axiosClient = createUiAxiosClient();

    const dateRange = getDateRange(dateSelected, 'day', 'day');

    if (dateRange.startDate && dateRange.endDate) {
        const response = await axiosClient.get(`/myJohnDeere/machines/${machineId}/breadcrumbs`, {
            params: dateRange
        });

        return response.data;
    }

    return {};
}

async function getMyJDMachineMeasurements(machines) {
    const axiosClient = createUiAxiosClient();

    const endDate = moment().toISOString();
    const startDate = moment().startOf('day').toISOString();

    const payload = machines.map((machine) => ({
        endDate,
        id: machine.myJDId,
        startDate
    }));

    const response = await axiosClient.post('/myJohnDeere/machines/machineMeasurements', payload);

    return response.data;
}

async function getMyJDMachinesWithBreadcrumbs(machineIds, dateSelected) {
    const axiosClient = createUiAxiosClient();

    const payload = getDateRange(dateSelected, 'day', 'day');

    if (payload.startDate && payload.endDate) {
        payload.machineIds = machineIds;

        const response = await axiosClient.post('/myJohnDeere/machines/hasBreadcrumbs', payload);

        return response.data;
    }

    return {};
}

async function getMyJDMachineBatteryMeasurement(machineId) {
    const axiosClient = createUiAxiosClient();
    const now = moment();

    const endDate = now.toISOString();
    const startDate = now.startOf('day').toISOString();
    const utcOffset = now.format('Z');

    const response = await axiosClient.get(`/api/myJohnDeere/machines/${machineId}/batteryMeasurement`, {
        params: {
            startDate,
            endDate,
            utcOffset
        }
    });

    return response.data;
}

export {
    getMyJDBreadcrumbs,
    getMyJDMachineMeasurements,
    getMyJDMachinesWithBreadcrumbs,
    getMyJDMachineBatteryMeasurement
};
