// Unpublished Work © 2021-2024 Deere & Company.

export const EQUIPMENT_SEARCH_FIELDS = [
    'engineHours',
    'equipmentName',
    'equipmentType',
    'modelName',
    'orgName',
    'serialNumber',
    'subTier',
    'vin'
];

export const USER_SEARCH_FIELDS = [
    'appUserId',
    'email',
    'name',
    'phone',
    'role',
    'status',
    'title'
];
