// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import WorkBoardDialogContent from 'OnLabor/my-work/work-board-dialog/work-board-dialog-content';
import {fetchEffectData} from 'Utils/react-utils';
import {getMowingPatternImageForWorkboard} from 'OnLabor/common/utils/mowing-pattern-utils';
import {capitalizeFirstLetter} from 'Utils/translation-utils';

const groupSize = 2;

function initializeState() {
    const [loading, setLoading] = React.useState(true);
    const [mowingPatternCompositeImages, setMowingPatternCompositeImages] = React.useState([]);

    return {
        loading,
        setLoading,
        mowingPatternCompositeImages,
        setMowingPatternCompositeImages
    };
}

function createMowingPatternGroups(mowingPatternCompositeImages, translations) {
    if (!mowingPatternCompositeImages.length) {
        return (
            <div className='empty-workboard-message'>
                {translations.ONLINK_NO_MOWING_DIRECTIONS_TO_DISPLAY}
            </div>
        );
    }

    const mowingPatternElements = mowingPatternCompositeImages.map((mowingPattern) => {
        const area = translations[mowingPattern.area] || mowingPattern.area;
        const cutStyle = translations[mowingPattern.cuttingStyle] || mowingPattern.cuttingStyle;
        const direction = translations[mowingPattern.direction] || mowingPattern.direction;
        const direction2 = mowingPattern.direction2 ? ` (${mowingPattern.direction2})` : '';
        const cleanupPass = mowingPattern.cleanupPass ? translations[mowingPattern.cleanupPass] : capitalizeFirstLetter(translations.NONE);

        return (
            <div className='mowing-pattern-section'>
                <div className='mowing-pattern-text-section'>
                    <div className='work-item-details-text-line medium-section-title'>
                        {`${area} - ${cutStyle} - ${direction}${direction2}`}
                    </div>
                    <div className='work-item-details-text-line body-text-section-title'>
                        {`${translations.ONLINK_CLEANUP} - ${cleanupPass}`}
                    </div>
                </div>
                <img
                    className='mowing-pattern-image'
                    src={mowingPattern.imgSrc}
                />
            </div>
        );
    });

    const groups = mowingPatternElements.map((e, index) => {
        return index % groupSize === 0 ? mowingPatternElements.slice(index, index + groupSize) : null;
    }).filter((group) => group);

    return groups.map((group, index) => {
        return (
            <div
                className={`settings-group mobile ${!group[1] && 'half-size'}`}
                key={index}
            >
                {group[0]}
                {group[1]}
            </div>
        );
    });
}

function WorkBoardDialogMowingDirections(props) {
    const {
        workboard,
        translations
    } = props;

    const {
        loading,
        setLoading,
        mowingPatternCompositeImages,
        setMowingPatternCompositeImages
    } = initializeState();

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const mowingPatternImages = await getMowingPatternImageForWorkboard(workboard);

        if (isMounted()) {
            setMowingPatternCompositeImages(mowingPatternImages);
            setLoading(false);
        }
    }), [workboard]);

    const mowingPatternGroups = React.useMemo(() => createMowingPatternGroups(mowingPatternCompositeImages, translations), [mowingPatternCompositeImages]);

    return (
        <WorkBoardDialogContent
            loading={loading}
            workboard={workboard}
        >
            {mowingPatternGroups}
        </WorkBoardDialogContent>
    );
}

WorkBoardDialogMowingDirections.propTypes = {
    translations: PropTypes.translations,
    workboard: PropTypes.object
};

export default WorkBoardDialogMowingDirections;
