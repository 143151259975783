// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {BasicDialog} from '@deere/basic-dialog';
import ErrorView from 'Ui/components/dashboard/error-view';
import SaveContentBar from 'Ui/components/settings/common/save-content-bar';
import ServiceForm from 'OnEquip/equipment/service-form/service-form';
import {redirect as redirectTo} from 'Utils/redirect-utils';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {VIEW_SERVICE} from 'Common/constants/business-activities';

function ServiceFormAuthWrapper(props) {
    const {
        closeDialogHandler,
        equipmentId,
        history,
        membership,
        myJdPermissions,
        redirect,
        translations,
        ...otherProps
    } = props;

    const canViewServiceForm = React.useMemo(() => isAuthorized({
        myJdPermissions: VIEW_SERVICE
    }, {
        isMigrated: membership.isMigrated,
        myJdPermissions
    }), [membership.isMigrated, myJdPermissions]);

    const errorView = React.useMemo(() => <ErrorView translations={translations}/>, [translations]);

    const unauthorizedCloseHandler = React.useCallback(() => {
        if (closeDialogHandler) {
            closeDialogHandler();
        } else {
            const redirectUrl = redirect.path
                .replace('{membershipId}', membership.membershipId)
                .replace('{equipmentId}', equipmentId);

            redirectTo(redirectUrl, redirect.reactRedirect ? history : null);
        }
    }, [closeDialogHandler, equipmentId, history, membership.membershipId]);

    return canViewServiceForm ? (
        <ServiceForm
            closeDialogHandler={closeDialogHandler}
            equipmentId={equipmentId}
            history={history}
            membership={membership}
            myJdPermissions={myJdPermissions}
            redirect={redirect}
            translations={translations}
            {...otherProps}
        />
    ) : (
        <BasicDialog
            className='service-form onlink-dialog'
            closeHandler={unauthorizedCloseHandler}
            show={true}
            title={translations.ONLINK_SERVICE_TICKET}
        >
            {errorView}
            <SaveContentBar
                onCancelClick={unauthorizedCloseHandler}
                readOnly={true}
                translations={translations}
            />
        </BasicDialog>
    );
}

ServiceFormAuthWrapper.propTypes = {
    closeDialogHandler: PropTypes.func,
    equipmentId: PropTypes.string,
    history: PropTypes.history,
    membership: PropTypes.membership,
    myJdPermissions: PropTypes.myJdPermissions,
    redirect: PropTypes.object,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membership: state.membership,
        myJdPermissions: state.account.myJdPermissions
    };
}

export default connect(mapStateToProps)(withRouter(ServiceFormAuthWrapper));
