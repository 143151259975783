// Unpublished Work © 2020-2024 Deere & Company.

import {createUiAxiosClient} from 'Utils/ui-axios-client';
import {filterFailedResponse, filterSuccessfulResponse, reduceResponseData} from 'Common/utils/response-mapper';
import {sortBy} from 'lodash';
import {validateFile} from 'Utils/validation-utils';
import {MAP_FLEET_EQUIPMENT_STUB} from 'Ui/constants/map-fleet-equipment-stub-constants';
import equipmentStatus from 'Common/constants/equipment-status';
import {translateEquipmentArea} from 'Utils/translation-utils';

async function getActiveMembership() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership');

    return response.data;
}

async function getMembershipIntegrations() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership/integrations');

    return response.data;
}

async function updateMembershipData(membershipData, membershipId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/memberships/${membershipId}`, membershipData);

    return response.data;
}

async function updateMembershipMowingDirections(mowingDirectionsData, membershipId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/memberships/${membershipId}/mowingDirections`, mowingDirectionsData);

    return response.data;
}

async function getDepartments() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership/departments');

    return {
        ...response.data,
        departments: sortBy(response.data.departments, (department) => {
            return department.name.toLowerCase();
        })
    };
}

async function getEquipmentAreas(translations) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership/equipmentAreas');

    const equipmentAreas = response.data.equipmentAreas.map((equipmentArea) => ({
        ...equipmentArea,
        name: translateEquipmentArea(translations, equipmentArea.name) || equipmentArea.name
    }));
    const sortedEquipmentAreas = sortBy(equipmentAreas, (area) => {
        return area.name.toLowerCase();
    });

    return {
        ...response.data,
        equipmentAreas: sortedEquipmentAreas
    };
}

async function getFleetEquipment() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership/equipment');

    return response.data;
}

async function getFilteredFleetEquipment() {
    const {equipment} = await getFleetEquipment();

    return equipment.filter((equipmentDetails) =>
        ![
            equipmentStatus.archived,
            equipmentStatus.decommissioned,
            equipmentStatus.outOfService
        ].includes(equipmentDetails.status));
}

async function getMapFleetEquipment() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership/equipment/map');

    const failedEquipment = response.data
        .filter(filterFailedResponse)
        .reduce(reduceResponseData, []);
    const successfulEquipment = response.data
        .filter(filterSuccessfulResponse)
        .reduce(reduceResponseData, [])
        .map((fleet) => ({
            ...fleet.onlinkData,
            ...fleet.platformData,
            lng: fleet.platformData.lon
        }));

    return {
        failedEquipment,
        successfulEquipment
    };
}

function getMapFleetEquipmentStub() {
    return MAP_FLEET_EQUIPMENT_STUB;
}

async function getEquipmentTypes() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership/equipmentTypes');

    return {
        ...response.data,
        equipmentTypes: sortBy(response.data.equipmentTypes, (type) => {
            return type.name.toLowerCase();
        })
    };
}

async function getMembershipUsers(dateSelect) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership/users', {
        params: {
            dateSelect
        }
    });

    return response.data;
}
async function getActiveMembershipUsers(dateSelect) {
    const axiosClient = createUiAxiosClient();
    const response = await axiosClient.get('/memberships/currentMembership/users', {
        params: {
            dateSelect
        }
    });
    const users = response?.data?.users;

    return users?.filter(({inactive}) => inactive !== true);
}

async function getMembershipUsersByPermission(permission) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/memberships/currentMembership/usersByPermission/${permission}`);

    return response.data;
}

async function getUserRoles() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership/userRoles');

    return {
        ...response.data,
        userRoles: sortBy(response.data.userRoles, (role) => {
            return role.title.toLowerCase();
        })
    };
}

async function sendFile(file) {
    const formData = new FormData();

    formData.append('filedata', file);

    const axiosClient = createUiAxiosClient();

    try {
        const response = await axiosClient.post('/memberships/currentMembership/logos', formData);

        return response.data;
    } catch (e) {
        throw new Error(e.response.data.message);
    }
}

async function postLogo(file, translations) {
    if (file) {
        validateFile(
            file,
            window.props.imageUploadExtensions,
            translations.ONLINK_IMPORT_FILE_TO_LARGE_ERROR,
            translations.ONLINK_IMPORT_FILE_MUST_BE_IMAGE
        );

        return await sendFile(file);
    }

    throw new Error(translations.ONLINK_IMPORT_NO_FILE_ATTACHED);
}

async function getLogo(membershipId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/memberships/${membershipId}/logos`);

    return response.data;
}

async function updateThreshold(threshold, dataType) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/memberships/currentMembership/thresholds/${dataType}`, threshold);

    return response.data;
}

async function getMaintenanceDue(serviceStatus) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership/fleet/maintenanceDue', {
        params: {
            serviceStatus
        }
    });

    return response.data;
}

async function getFleetByMembershipId(membershipId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/memberships/${membershipId}/fleet`);

    return response.data;
}

async function getInventories() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/memberships/currentMembership/inventories');

    return response.data;
}

async function getInventoriesByMembershipId(membershipId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/memberships/${membershipId}/inventories`);

    return response.data;
}

async function getWorkboards(dateSelect) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/memberships/currentMembership/workboards', {
        params: {
            date: dateSelect
        }
    });

    return response.data;
}

async function getWorkboardsByMembershipId(membershipId, dateSelect) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/memberships/${membershipId}/workboards`, {
        params: {
            date: dateSelect
        }
    });

    return response.data;
}

async function getMembershipHierarchy(membershipId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/memberships/hierarchy', {
        params: {
            membershipId
        }
    });

    return response.data;
}

async function getMembershipHierarchyDealerships(membershipId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/memberships/hierarchy/dealerships', {
        params: {
            membershipId
        }
    });

    return response.data;
}

async function getRootMembershipOfCurrentlySelectedMembership() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/memberships/rootMembership');

    return response.data;
}

async function getAddressFieldsByCountry(country) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/addressFields', {
        params: {
            country
        }
    });

    return response.data;
}

async function getMembershipForms() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/memberships/currentMembership/forms');

    return response.data;
}

async function getMembershipFormsByDateSelectDataGroup(membershipId, dataGroup, dateSelect) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/memberships/currentMembership/formsByDateSelectDataGroup', {
        params: {
            dataGroup,
            dateSelect,
            membershipId
        }
    });

    return response.data;
}

async function getSpecifiedMembershipUsers(membershipId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/memberships/${membershipId}/users`);

    return response.data;
}

async function getEmployeeStatusOptions() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/memberships/currentMembership/employeeStatusOptions');

    return response.data;
}

async function deleteEmployeeStatusOption(employeeStatusOptionId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.delete(`/api/employeeStatusOptions/${employeeStatusOptionId}`);

    return response.data;
}

async function getCutHistory(startDate, endDate) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/memberships/currentMembership/cutHistory', {
        params: {
            startDate,
            endDate
        }
    });

    return response.data;
}

async function createEmployeeStatusOption(employeeStatusOption) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.post('/api/memberships/currentMembership/employeeStatusOptions', employeeStatusOption);

    return response.data;
}

async function updateEmployeeStatusOption(employeeStatusOptionId, employeeStatusOption) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.put(`/api/employeeStatusOptions/${employeeStatusOptionId}`, employeeStatusOption);

    return response.data;
}

async function getTimeOff({
    startDate,
    endDate
}) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/memberships/currentMembership/timeOff', {
        params: {
            startDate,
            endDate
        }
    });

    return response.data;
}

async function getTodoTemplates() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/memberships/currentMembership/todoTemplates');

    return response.data;
}

async function getTodos() {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get('/api/memberships/currentMembership/todos');

    return response.data;
}

async function getMembershipById(membershipId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/memberships/${membershipId}`);

    return response.data;
}

async function getSubscriptionForMembership() {
    const axiosClient = createUiAxiosClient();

    try {
        const response = await axiosClient.get('/api/memberships/currentMembership/subscriptions');

        return response.data;
    } catch {
        return {
            hasSubscription: false
        };
    }
}

async function getMyJDUserOrganization(orgId) {
    const axiosClient = createUiAxiosClient();

    const response = await axiosClient.get(`/api/myJohnDeere/organizations/${orgId}`);

    return response.data;
}

export {
    getActiveMembership,
    getMembershipIntegrations,
    updateMembershipData,
    getDepartments,
    getEquipmentAreas,
    getEquipmentTypes,
    getMapFleetEquipmentStub,
    getFleetEquipment,
    getFilteredFleetEquipment,
    getMapFleetEquipment,
    getMembershipUsers,
    getUserRoles,
    postLogo,
    updateThreshold,
    getMaintenanceDue,
    getFleetByMembershipId,
    getInventories,
    getInventoriesByMembershipId,
    getMembershipUsersByPermission,
    getWorkboards,
    getMembershipHierarchy,
    getMembershipHierarchyDealerships,
    getRootMembershipOfCurrentlySelectedMembership,
    getAddressFieldsByCountry,
    getMembershipForms,
    getMembershipFormsByDateSelectDataGroup,
    getSpecifiedMembershipUsers,
    getEmployeeStatusOptions,
    deleteEmployeeStatusOption,
    createEmployeeStatusOption,
    updateEmployeeStatusOption,
    getCutHistory,
    getTimeOff,
    getTodoTemplates,
    getTodos,
    getMembershipById,
    getWorkboardsByMembershipId,
    getSubscriptionForMembership,
    getLogo,
    getMyJDUserOrganization,
    getActiveMembershipUsers,
    updateMembershipMowingDirections
};
