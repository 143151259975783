// Unpublished Work © 2024 Deere & Company.
import LoadingIcon from 'Ui/components/common/loading-icon';
import Box from '@mui/material/Box';
import {DataTable, FilterChipLayout, SearchField} from '@deere/isg.component-library';
import React from 'react';
import SearchFilter from 'Ui/components/higher-order-components/search-filter';
import PropTypes from 'Utils/prop-type-utils';

function applyFilter(rows, filterKey, filterValue) {
    if (filterKey === 'date') {
        if (!filterValue[0] || !filterValue[1]) {
            return rows;
        }

        return rows.filter((row) => filterValue[0] <= new Date(row[filterKey]) && filterValue[1] >= new Date(row[filterKey]));
    }

    if (filterValue.length === 0) {
        return rows;
    }

    return rows.filter((row) => filterValue.includes(row[filterKey]));
}

function filterRows(rows, appliedFilters) {
    let filteredRows = rows;

    if (appliedFilters) {
        Object.keys(appliedFilters).forEach((appliedFilterKey) => {
            filteredRows = applyFilter(filteredRows, appliedFilterKey, appliedFilters[appliedFilterKey]);
        });
    }

    return filteredRows;
}

function ComponentLibraryDataTableWrapper(props) {
    const {
        appliedFilters,
        applySearch,
        columns,
        filters,
        initialState,
        loading,
        onSearchChange,
        rows,
        search,
        searchFields,
        translations
    } = props;

    if (loading) {
        return (
            <LoadingIcon
                className='table-loading-icon'
                size='50px'
            />
        );
    }

    if (rows.length === 0) {
        return (
            <h1 className='table-no-data'>
                {translations.NO_LOADED_DATA_AVAILABLE}
            </h1>
        );
    }

    const filteredAndSearchedRows = React.useMemo(() => {
        const filteredRows = filterRows(rows, appliedFilters);

        return applySearch(filteredRows, searchFields);
    }, [rows, appliedFilters, search]);

    return (
        <Box
            display='flex'
            flexDirection='column'
            gap='12px'
            height='100%'
        >
            <Box
                display='flex'
            >
                <SearchField
                    onChange={onSearchChange}
                    onClear={() => onSearchChange({
                        target: {
                            value: ''
                        }
                    })}
                    placeholder={translations.SEARCH}
                    value={search}
                />
                <FilterChipLayout
                    className='table-filters'
                    hideMoreFilters={true}
                    hideSearchField={true}
                >
                    {filters}
                </FilterChipLayout>
            </Box>
            {
                filteredAndSearchedRows.length === 0 ?
                    <h1 className='table-no-data'>
                        {translations.NO_LOADED_DATA_AVAILABLE}
                    </h1> :
                    <DataTable
                        columns={columns}
                        data={filteredAndSearchedRows}
                        enableVirtualization
                        initialState={initialState}
                    />
            }
        </Box>
    );
}

ComponentLibraryDataTableWrapper.propTypes = {
    appliedFilters: PropTypes.object,
    applySearch: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.object),
    filters: PropTypes.element,
    initialState: PropTypes.object,
    loading: PropTypes.bool,
    onSearchChange: PropTypes.func,
    rows: PropTypes.arrayOf(PropTypes.object),
    search: PropTypes.string,
    searchFields: PropTypes.arrayOf(PropTypes.string),
    translations: PropTypes.translations
};

export default SearchFilter(ComponentLibraryDataTableWrapper);
