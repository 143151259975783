// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import UpsertEquipmentForm from 'OnEquip/equipment/common/upsert-equipment-form';
import {updateEquipment, updateEquipmentModel} from 'Services/equipment-service';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {fetchEffectData, useLazyRef} from 'Utils/react-utils';
import {getModels} from 'Services/fleet-service';
import {formatModels} from 'Utils/multiselect/service-multiselect-mappers';
import {EDIT_MODEL} from 'Common/constants/feature-toggles';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';

function EquipmentEditDialog(props) {
    const {
        closeDialog,
        equipment,
        featureToggles,
        invalidInputs,
        isMigrated,
        servicePriority,
        setValid,
        translations,
        updateFleetEquipment
    } = props;

    const modelMap = useLazyRef(() => new Map());

    const [equipmentName, setEquipmentName] = React.useState(() => equipment.equipmentName);
    const [isSaving, setIsSaving] = React.useState(() => false);
    const [serialNumber, setSerialNumber] = React.useState(() => equipment.serialNumber);
    const [modelItems, setModelItems] = React.useState(() => []);
    const [selectedModel, setSelectedModel] = React.useState(equipment.modelId);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);
        const {models} = await getModels();

        if (isMounted()) {
            const {fullListModels} = formatModels(models, [], true, equipment.modelId);

            fullListModels.forEach((model) => {
                modelMap.current.set(model.id, model);
            });

            setModelItems(fullListModels);
            setLoading(false);
        }
    }), [equipment]);

    async function onSave() {
        const equipmentData = {
            ...equipment,
            equipmentName,
            serialNumber,
            model: modelMap.current.get(selectedModel)
        };
        const equipmentUpdateCallback = selectedModel === equipment.modelId ? updateEquipment : updateEquipmentModel;

        setIsSaving(true);

        try {
            await equipmentUpdateCallback(equipmentData, equipment.equipmentId);
        } catch (e) {
            setIsSaving(false);

            throw e;
        }

        updateFleetEquipment();
        closeDialog();
    }

    const isServiceInProcess = servicePriority?.find((service) => service.status === 'in_process');
    const shouldDisplayModelSelector = !isServiceInProcess && isMigrated && featureToggles[EDIT_MODEL];

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className={'dropdown-overflow-dialog'}
            closeHandler={closeDialog}
            disableSave={invalidInputs.size > 0 || isSaving}
            footerLoading={isSaving}
            onSave={onSave}
            saveLabel={translations.save}
            title={translations.EDIT_EQUIPMENT}
            translations={translations}
        >
            <LoadingWrapper
                className={'onlink-loading-icon'}
                loading={loading}
                size={'50px'}
            >
                <UpsertEquipmentForm
                    equipmentName={equipmentName}
                    modelItems={modelItems}
                    selectedModel={selectedModel}
                    serialNumber={serialNumber}
                    setEquipmentName={setEquipmentName}
                    setSelectedModel={setSelectedModel}
                    setSerialNumber={setSerialNumber}
                    setValid={setValid}
                    shouldDisplayModelSelector={shouldDisplayModelSelector}
                    translations={translations}
                />
            </LoadingWrapper>
        </FormDialog>
    );
}

EquipmentEditDialog.propTypes = {
    closeDialog: PropTypes.func,
    equipment: PropTypes.equipment,
    featureToggles: PropTypes.featureToggles,
    invalidInputs: PropTypes.instanceOf(Set),
    isMigrated: PropTypes.bool,
    servicePriority: PropTypes.array,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    updateFleetEquipment: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        translations: state.translations,
        featureToggles: state.account.featureToggles,
        isMigrated: state.membership.isMigrated
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.EQUIPMENT_EDIT_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(EquipmentEditDialog));
