// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';

function WorkBoardDialogContent(props) {
    const {
        children,
        workboard,
        loading
    } = props;

    return (
        <div className='work-board-dialog-content'>
            <LoadingWrapper
                className='table-loading-icon'
                loading={loading}
                size='50px'
            >
                {
                    workboard && workboard.note &&
                    <div className='work-board-dialog-note large-section-title'>
                        {workboard.note}
                    </div>
                }
                {children}
            </LoadingWrapper>
        </div>
    );
}

WorkBoardDialogContent.propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool,
    workboard: PropTypes.object
};

export default WorkBoardDialogContent;
