// Unpublished Work © 2020-2024 Deere & Company.

import {isNullOrUndefined} from 'Common/utils/validation-utils';

function filterItem(item) {
    return !isNullOrUndefined(item) && item !== '';
}

async function getLatLongFromAddress(address) {
    const geocoder = new google.maps.Geocoder();
    const location = {
        address
    };

    return new Promise((resolve) => {
        geocoder.geocode(location, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                resolve({
                    latitude: results[0].geometry.location.lat(),
                    longitude: results[0].geometry.location.lng()
                });
            } else {
                resolve({});
            }
        });
    });
}

async function calculateLatLong(addressArr) {
    const address = addressArr.filter(filterItem).join(' ');

    return await getLatLongFromAddress(address);
}

export {
    calculateLatLong
};
