// Unpublished Work © 2022-2024 Deere & Company.

import PropTypes from 'Utils/prop-type-utils';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import ValidationInput from 'Ui/components/common/form/validation-input';
import ValidationDate from 'Ui/components/common/form/validation-date';
import React from 'react';
import {FORM_EXPENSE_TYPES as expenseTypeConstants} from 'Ui/constants/equipment-expense-types-constants';
import {capitalizeFirstLetter, replaceTranslationNames} from 'Utils/translation-utils';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';

function getExpenseTypes(translations) {
    return expenseTypeConstants.map((expenseType) => {
        const expenseTypeLabel = translations[expenseType.label] || expenseType.label;

        return {
            ...expenseType,
            title: capitalizeFirstLetter(expenseTypeLabel)
        };
    });
}

function AddExpenseDialogForm(props) {
    const {
        amount,
        dateSelect,
        expenseType,
        setAmount,
        setDateSelect,
        setExpenseType,
        setValid,
        translations
    } = props;

    const expenseTypes = getExpenseTypes(translations);
    const valueGreaterThanOrEqual = replaceTranslationNames(translations.VALUE_GREATER_THAN_OR_EQUAL, {
        '0': 0
    });

    return (
        <form className='equipment-dialog-form'>
            <div className='settings-group mobile'>
                <ValidationDate
                    dateFormat='L'
                    label={translations.DATE}
                    large={true}
                    locale={getLanguagePreference()}
                    name='dateSelect'
                    onChange={(date) => setDateSelect(date)}
                    required={true}
                    selected={dateSelect}
                    setValid={setValid}
                    tabIndex={0}
                />
                <ValidationMultiSelect
                    className='multiselect'
                    containerHeight={250}
                    hideFilter={true}
                    items={expenseTypes}
                    label={translations.TYPE}
                    multiple={false}
                    name='expenseType'
                    onChange={(ids) => setExpenseType(ids[0])}
                    selectedIds={[expenseType]}
                    setValid={setValid}
                    tabIndex={0}
                />
            </div>
            <ValidationInput
                debounceTimeout={window.props.debounceTimeout}
                errors={{
                    rangeUnderflow: valueGreaterThanOrEqual
                }}
                label={translations.ONLINK_AMOUNT}
                min={0}
                name='amount'
                onChange={(event) => setAmount(event.target.value)}
                required={true}
                setValid={setValid}
                step='any'
                tabIndex={0}
                type='number'
                value={amount}
            />
        </form>
    );
}

AddExpenseDialogForm.propTypes = {
    amount: PropTypes.stringOrNumber,
    dateSelect: PropTypes.object,
    expenseType: PropTypes.string,
    setAmount: PropTypes.func,
    setDateSelect: PropTypes.func,
    setExpenseType: PropTypes.func,
    setValid: PropTypes.func,
    translations: PropTypes.translations
};

export default AddExpenseDialogForm;
