// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import AdditionalActionButton from 'OnEquip/equipment/common/additional-action-button';
import {IconAdd, IconShown, IconSwap, IconWrench} from '@deere/icons';
import {createIconFillStyle} from 'Utils/icon-utils';
import {openDialog as openReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import {DropdownMenu} from '@deere/isg.component-library/lib/DropdownMenu/DropdownMenu';

const ICON_BLUE_COLOR = '#357edd';

const ICON_STYLE = {
    style: {
        height: '20px',
        width: '20px'
    }
};

const TRANSFER_ICON_STYLE = {
    style: {
        height: '12px',
        width: '12px',
        marginLeft: '3px'
    }
};

const ADD_ICON_STYLE = {
    style: {
        display: 'inline',
        fill: ICON_BLUE_COLOR,
        height: '15px',
        paddingLeft: '4px',
        width: '15px'
    }
};

function createEquipmentCallbacks({
    equipment,
    membershipHierarchy,
    openAddExpenseDialog,
    openCheckEquipmentDialog,
    openEquipmentTransferDialog,
    openServiceFormDialog,
    translations,
    updateEquipment
}) {
    return {
        openAddExpenseDialogCallback() {
            openAddExpenseDialog({
                equipment,
                updateFleetEquipment: updateEquipment
            });
        },
        openCheckEquipmentDialogCallback() {
            openCheckEquipmentDialog({
                equipment,
                updateFleetEquipment: updateEquipment
            });
        },
        openEquipmentTransferDialogCallback() {
            openEquipmentTransferDialog({
                equipment,
                membershipHierarchy,
                updateFleetEquipment: updateEquipment
            });
        },
        openServiceFormDialogCallback() {
            openServiceFormDialog({
                equipmentId: equipment.equipmentId,
                onSave: updateEquipment,
                translations
            });
        }
    };
}

function SecondaryHeader(props) {
    const {
        equipment,
        isMobile,
        membershipHierarchy,
        translations,
        tabSpecificComponent,
        userAuth: {
            canModifyFinancialData,
            canEditEquipmentList,
            canTransfer
        }
    } = props;

    if (!equipment) {
        return null;
    }

    const {
        openAddExpenseDialogCallback,
        openCheckEquipmentDialogCallback,
        openEquipmentTransferDialogCallback,
        openServiceFormDialogCallback
    } = createEquipmentCallbacks(props);

    const hasMembershipChildren = membershipHierarchy.children?.length > 0;

    if (isMobile) {
        const items = [
            {
                title: translations.ONLINK_ADD_EXPENSE,
                onClick: openAddExpenseDialogCallback,
                myJdPermissions: canModifyFinancialData,
                requireMembershipChildren: false
            },
            {
                title: translations.ONLINK_SERVICE,
                onClick: openServiceFormDialogCallback,
                myJdPermissions: canEditEquipmentList,
                requireMembershipChildren: false
            },
            {
                title: translations.ONLINK_EQUIPMENT_CHECK,
                onClick: openCheckEquipmentDialogCallback,
                myJdPermissions: canEditEquipmentList,
                requireMembershipChildren: false
            },
            {
                title: translations.TRANSFER,
                onClick: openEquipmentTransferDialogCallback,
                myJdPermissions: canTransfer,
                requireMembershipChildren: true
            }
        ].filter((item) => item.requireMembershipChildren ?
            hasMembershipChildren && item.myJdPermissions :
            item.myJdPermissions
        );

        return (
            <div className='extra-table-options'>
                {
                    tabSpecificComponent
                }
                <div className='right-aligned'>
                    <DropdownMenu
                        PopoverProps={{
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'bottom'
                            },
                            transformOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            }
                        }}
                        buttonTitle={translations.ACTIONS}
                        className='onlink-action-dropdown'
                        items={items}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className='extra-table-options'>
            {
                tabSpecificComponent
            }
            <div className='right-aligned'>
                <div className='table-buttons'>
                    {
                        canModifyFinancialData &&
                        <AdditionalActionButton
                            className='add-expense'
                            label={translations.ONLINK_ADD_EXPENSE}
                            onClick={openAddExpenseDialogCallback}
                        >
                            <IconAdd
                                iconAdd={ADD_ICON_STYLE}
                            />
                        </AdditionalActionButton>
                    }
                    {
                        canEditEquipmentList &&
                        <AdditionalActionButton
                            className='create-service'
                            label={translations.ONLINK_SERVICE}
                            onClick={openServiceFormDialogCallback}
                        >
                            <IconWrench
                                iconWrench={ICON_STYLE}
                                primary={createIconFillStyle('#f6a623')}
                            />
                        </AdditionalActionButton>}
                    {
                        canEditEquipmentList &&
                        <AdditionalActionButton
                            className='check-equipment'
                            label={translations.ONLINK_EQUIPMENT_CHECK}
                            onClick={openCheckEquipmentDialogCallback}
                        >
                            <IconShown
                                iconShown={ICON_STYLE}
                                primary={createIconFillStyle(ICON_BLUE_COLOR)}
                            />
                        </AdditionalActionButton>
                    }
                    {
                        hasMembershipChildren && canTransfer &&
                        <AdditionalActionButton
                            className='transfer-equipment'
                            label={translations.TRANSFER}
                            onClick={openEquipmentTransferDialogCallback}
                        >
                            <IconSwap
                                iconSwap={TRANSFER_ICON_STYLE}
                                primary={createIconFillStyle('#357edd')}
                            />
                        </AdditionalActionButton>
                    }
                </div>
            </div>
        </div>
    );
}

SecondaryHeader.propTypes = {
    equipment: PropTypes.equipment,
    isMobile: PropTypes.bool,
    membershipHierarchy: PropTypes.object,
    openAddExpenseDialog: PropTypes.func,
    openCheckEquipmentDialog: PropTypes.func,
    openEquipmentTransferDialog: PropTypes.func,
    openServiceFormDialog: PropTypes.func,
    tabSpecificComponent: PropTypes.node,
    translations: PropTypes.translations,
    updateEquipment: PropTypes.func,
    userAuth: PropTypes.myJdPermissions
};

export function mapDispatchToProps(dispatch) {
    return {
        openCheckEquipmentDialog(props) {
            dispatch(openReduxDialog(dialogTypes.CHECK_EQUIPMENT_DIALOG, props));
        },
        openEquipmentTransferDialog(props) {
            dispatch(openReduxDialog(dialogTypes.EQUIPMENT_TRANSFER_DIALOG, props));
        },
        openServiceFormDialog(props) {
            dispatch(openReduxDialog(dialogTypes.SERVICE_FORM_DIALOG, props));
        },
        openAddExpenseDialog(props) {
            dispatch(openReduxDialog(dialogTypes.ADD_EXPENSE_DIALOG, props));
        }
    };
}

export default connect(null, mapDispatchToProps)(MediaQuery(MOBILE_MEDIA_QUERY)(SecondaryHeader));
