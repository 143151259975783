// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import OnLinkDialogContentBar from 'Ui/components/common/onlink-dialog/onlink-dialog-content-bar';
import OnlinkButton from 'Ui/components/common/onlink-button';
import {joinClassNames} from 'Utils/html-utils';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import {IconLoading} from '@deere/icons';
import {TOAST_TYPE} from '@deere/toast';

const ICON_LOADING_STYLE = {
    style: {
        height: '16px',
        width: '16px'
    }
};

function SaveContentBar(props) {
    const {
        addToast,
        className,
        disabled,
        loading,
        onCancelClick,
        onSaveClick,
        readOnly,
        translations
    } = props;

    const contentBarClassName = React.useMemo(() => joinClassNames('settings-actions', className), [className]);
    const loadingIcon = React.useMemo(() => loading ? <IconLoading iconLoading={ICON_LOADING_STYLE}/> : null, [loading]);

    return (
        <OnLinkDialogContentBar className={contentBarClassName}>
            <OnlinkButton onClick={onCancelClick}>
                {readOnly ? translations.CLOSE : translations.CANCEL}
            </OnlinkButton>
            {
                !readOnly &&
                <OnlinkButton
                    className='primary'
                    disabled={disabled}
                    leftIcon={loadingIcon}
                    onClick={async (event) => {
                        try {
                            await onSaveClick(event);
                        } catch (err) {
                            addToast({
                                message: translations.ERROR_OCCURRED_TITLE,
                                type: TOAST_TYPE.ERROR
                            });
                        }
                    }}
                >
                    {translations.save}
                </OnlinkButton>
            }
        </OnLinkDialogContentBar>
    );
}

SaveContentBar.propTypes = {
    addToast: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onCancelClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    readOnly: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        }
    };
}

export default connect(null, mapDispatchToProps)(SaveContentBar);
