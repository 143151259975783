// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import SortedMultiSelect from 'Ui/components/common/form/sorted-multi-select';
import UpsertEquipmentForm from 'OnEquip/equipment/common/upsert-equipment-form';
import ValidationMultiSelect from 'Ui/components/common/form/validation-multi-select';
import {fetchEffectData, useLazyRef} from 'Utils/react-utils';
import {createEquipment} from 'Services/equipment-service';
import {getFleetEquipmentById, getModels} from 'Services/fleet-service';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';
import {formatModels} from 'Utils/multiselect/service-multiselect-mappers';

function EquipmentCreateDialog(props) {
    const {
        closeDialog,
        fleetId,
        invalidInputs,
        setValid,
        translations,
        updateFleetEquipment
    } = props;

    const modelMap = useLazyRef(() => new Map());

    const [equipmentName, setEquipmentName] = React.useState(() => '');
    const [isSaving, setIsSaving] = React.useState(() => false);
    const [modelItems, setModelItems] = React.useState(() => []);
    const [selectedModel, setSelectedModel] = React.useState();
    const [serialNumber, setSerialNumber] = React.useState(() => '');

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        const [{models}, {equipment}] = await Promise.all([
            getModels(),
            getFleetEquipmentById(fleetId)
        ]);

        if (isMounted()) {
            const {fullListModels} = formatModels(models, equipment, true);

            fullListModels.forEach((model) => {
                modelMap.current.set(model.id, model);
            });

            setModelItems(fullListModels);
        }
    }), [fleetId]);

    async function onSave() {
        const equipmentData = {
            equipmentName,
            fleetId,
            model: modelMap.current.get(selectedModel),
            serialNumber
        };

        equipmentData.modelId = equipmentData.model.modelId;

        setIsSaving(true);

        try {
            await createEquipment(equipmentData);
        } catch (e) {
            setIsSaving(false);

            throw e;
        }

        updateFleetEquipment();
        closeDialog();
    }

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className={'equipment-create-dialog dropdown-overflow-dialog'}
            closeHandler={closeDialog}
            disableSave={invalidInputs.size > 0 || isSaving}
            footerLoading={isSaving}
            onSave={onSave}
            saveLabel={translations.save}
            title={translations.equipment_add_equipment}
            translations={translations}
        >
            <form className='equipment-dialog-form'>
                <div className='settings-block-row'>
                    <ValidationMultiSelect
                        className='multiselect'
                        component={SortedMultiSelect}
                        containerHeight={300}
                        defaultSelectLabel={translations.ONLINK_SELECT_MODEL}
                        dropUpThreshold={350}
                        error={translations.REQUIRED_FIELD_TEXT}
                        items={modelItems}
                        label={translations.MODEL}
                        multiple={false}
                        name='selectedModel'
                        onChange={(selectedIds) => setSelectedModel(selectedIds[0])}
                        selectedIds={[selectedModel]}
                        setValid={setValid}
                        tabIndex={0}
                    />
                </div>
                <UpsertEquipmentForm
                    equipmentName={equipmentName}
                    serialNumber={serialNumber}
                    setEquipmentName={setEquipmentName}
                    setSerialNumber={setSerialNumber}
                    setValid={setValid}
                    translations={translations}
                />
            </form>
        </FormDialog>
    );
}

EquipmentCreateDialog.propTypes = {
    closeDialog: PropTypes.func,
    fleetId: PropTypes.string,
    invalidInputs: PropTypes.instanceOf(Set),
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    updateFleetEquipment: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.EQUIPMENT_CREATE_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(EquipmentCreateDialog));
