// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import UpsertEquipmentForm from 'OnEquip/equipment/common/upsert-equipment-form';
import {updateEquipment} from 'Services/equipment-service';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import dialogTypes from 'Ui/components/common/dialog-types';

function EquipmentEditDialog(props) {
    const {
        closeDialog,
        equipment,
        invalidInputs,
        setValid,
        translations,
        updateFleetEquipment
    } = props;

    const [equipmentName, setEquipmentName] = React.useState(() => equipment.equipmentName);
    const [isSaving, setIsSaving] = React.useState(() => false);
    const [serialNumber, setSerialNumber] = React.useState(() => equipment.serialNumber);

    async function onSave() {
        const equipmentData = {
            ...equipment,
            equipmentName,
            serialNumber
        };

        setIsSaving(true);

        try {
            await updateEquipment(equipmentData, equipment.equipmentId);
        } catch (e) {
            setIsSaving(false);

            throw e;
        }

        updateFleetEquipment();
        closeDialog();
    }

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closeDialog}
            disableSave={invalidInputs.size > 0 || isSaving}
            footerLoading={isSaving}
            onSave={onSave}
            saveLabel={translations.save}
            title={translations.EDIT_EQUIPMENT}
            translations={translations}
        >
            <form className='equipment-dialog-form'>
                <UpsertEquipmentForm
                    equipmentName={equipmentName}
                    serialNumber={serialNumber}
                    setEquipmentName={setEquipmentName}
                    setSerialNumber={setSerialNumber}
                    setValid={setValid}
                    translations={translations}
                />
            </form>
        </FormDialog>
    );
}

EquipmentEditDialog.propTypes = {
    closeDialog: PropTypes.func,
    equipment: PropTypes.equipment,
    invalidInputs: PropTypes.instanceOf(Set),
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    updateFleetEquipment: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.EQUIPMENT_EDIT_DIALOG));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(EquipmentEditDialog));
