// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {withRouter} from 'react-router-dom';
import Header from 'Ui/features/onlabor/workboards/presentation/header';
import JobsContainer from 'Ui/features/onlabor/workboards/presentation/jobs-container';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import MowingDirections from 'Ui/features/onlabor/workboards/presentation/mowing-directions';
import NoDataMessage from 'Ui/components/common/message/no-data-message';
import StandaloneBackground from 'Ui/components/standalone-background';
import {getDataSourceForMembership} from 'Services/panel-service';
import {getWorkboardPresentation, getUserOrder} from 'Services/work-board-service';
import {getElementScrollY} from 'Ui/features/onlabor/workboards/presentation/utils/presentation-utils';
import {fetchEffectData} from 'Utils/react-utils';
import {setActiveFocusInterval, useRefresh} from 'Utils/refresh-utils';
import {DATE_FORMATS, formatTime} from 'Utils/time-utils';
import {isNullOrUndefined} from 'Common/utils/validation-utils';
import {getTodaysWorkboards, sortJobsBySeq} from 'Utils/workboard-utils';
import {MILLISECONDS_PER_MINUTE, MILLISECONDS_PER_SECOND} from 'Ui/constants/time-constants';
import onlaborImage from 'Ui/images/onlabor-white.png';
import {useGetMembershipLogo} from 'Ui/react-hooks/use-get-membership-logo';

const MAX_SCROLL_MULTIPLIER = 4;
const REFRESH_INTERVAL_THREE_MINS = 3;
const SCROLL_DELAY_FIVE_SECONDS = 5000;
const INTERRUPT_DELAY_TEN_SECONDS = 10_000;
const INTERRUPT_EVENTS = ['mousedown', 'wheel', 'keyup'];

let interruptScroll = false,
    timeOutIntervalId = null;

async function fetchWorkboard(membershipId, workboardId) {
    let unsortedWorkboard;

    if (workboardId) {
        const {
            workboard, userOrder
        } = await getWorkboardPresentation(workboardId);

        unsortedWorkboard = workboard;
        unsortedWorkboard.userOrder = userOrder;
    } else {
        unsortedWorkboard = await getTodaysWorkboards(membershipId);

        if (unsortedWorkboard?.workboardId) {
            const {userOrder} = await getUserOrder(unsortedWorkboard.workboardId);

            unsortedWorkboard.userOrder = userOrder;
        }
    }

    const jobs = sortJobsBySeq(unsortedWorkboard?.jobs);

    return {
        ...unsortedWorkboard,
        jobs
    };
}

async function fetchWeather(membershipId) {
    const dateSelect = formatTime(new Date(), DATE_FORMATS.day);
    const {hourlyForecast} = await getDataSourceForMembership('TWC', dateSelect, membershipId);

    return hourlyForecast;
}

async function autoRefreshData({
    membershipId,
    workboardId,
    setWeather,
    setWorkboard
}, isMounted = () => true) {
    const [weather, workboard] = await Promise.all([
        fetchWeather(membershipId),
        fetchWorkboard(membershipId, workboardId)
    ]);

    if (isMounted()) {
        setWorkboard(workboard);
        setWeather(weather);
    }
}

function scrollForZoom(yDelta, currentScroll, scrollRef) {
    let i = 1;

    do {
        scrollRef.current.scrollBy(0, yDelta * i);

        i += 1;
    } while (currentScroll === getElementScrollY(scrollRef.current) && i <= MAX_SCROLL_MULTIPLIER);
}

function scroll(membershipSpeed, yDelta, callback, scrollRef) {
    if (interruptScroll) {
        clearTimeout(timeOutIntervalId);

        timeOutIntervalId = setTimeout(() => {
            interruptScroll = false;
            scroll(membershipSpeed, yDelta, callback, scrollRef);
        }, INTERRUPT_DELAY_TEN_SECONDS);
        return;
    }

    const currentScroll = getElementScrollY(scrollRef.current);

    scrollForZoom(yDelta, currentScroll, scrollRef);

    clearTimeout(timeOutIntervalId);

    timeOutIntervalId = currentScroll !== getElementScrollY(scrollRef.current) ?
        setTimeout(() => scroll(membershipSpeed, yDelta, callback, scrollRef), membershipSpeed) :
        callback();
}

function scrollDown(membershipSpeed, scrollRef, callback) {
    scroll(membershipSpeed, 1, callback, scrollRef);
}

function scrollUp(membershipSpeed, scrollRef, callback) {
    scroll(membershipSpeed, -1, callback, scrollRef);
}

function autoScroll(membershipSpeed, scrollRef) {
    timeOutIntervalId = setTimeout(() => {
        scrollDown(membershipSpeed, scrollRef, () => {
            setTimeout(() => {
                scrollUp(membershipSpeed, scrollRef, () => {
                    autoScroll(membershipSpeed, scrollRef);
                });
            }, SCROLL_DELAY_FIVE_SECONDS);
        });
    }, SCROLL_DELAY_FIVE_SECONDS);
}

function initializeState() {
    const [loading, setLoading] = React.useState(true);
    const [workboard, setWorkboard] = React.useState(null);
    const [weather, setWeather] = React.useState([]);

    return {
        loading,
        setLoading,
        weather,
        setWeather,
        workboard,
        setWorkboard
    };
}

function interruptScrollHandler() {
    interruptScroll = true;
}

function generateEventListeners() {
    INTERRUPT_EVENTS.forEach((event) => {
        window.addEventListener(event, interruptScrollHandler);
    });
}

function Presentation(props) {
    const {
        translations,
        match,
        wrapperRef,
        workboardMembership
    } = props;

    const {
        loading,
        setLoading,
        weather,
        setWeather,
        workboard,
        setWorkboard
    } = initializeState();

    const {
        membershipId,
        workboardId
    } = match.params;

    const [, refreshFunc] = useRefresh(() => autoRefreshData({
        membershipId,
        workboardId,
        setWeather,
        setWorkboard
    }));

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        await autoRefreshData({
            membershipId,
            workboardId,
            setWeather,
            setWorkboard
        }, isMounted);

        if (isMounted()) {
            setLoading(false);
        }

        const intervalId = setActiveFocusInterval(refreshFunc, REFRESH_INTERVAL_THREE_MINS * MILLISECONDS_PER_MINUTE, MILLISECONDS_PER_SECOND);

        generateEventListeners();

        const membershipSpeed = parseFloat(workboardMembership.properties.workboard_presentation_autoscroll_speed);

        if (membershipSpeed) {
            autoScroll(membershipSpeed, wrapperRef);
        }

        return () => {
            clearInterval(intervalId);
            clearInterval(timeOutIntervalId);

            INTERRUPT_EVENTS.forEach((event) => {
                window.removeEventListener(event, interruptScrollHandler);
            });
        };
    }), []);

    const {
        logoDataUrl, logoLoading
    } = useGetMembershipLogo(membershipId);

    const isPreview = Boolean(workboardId);
    const membershipProperties = workboardMembership?.properties;

    return (
        <LoadingWrapper
            className='dashboard-loading-icon'
            loading={loading || logoLoading}
            size='50px'
        >
            <NoDataMessage
                hasData={!isNullOrUndefined(workboard)}
                imageSrc={onlaborImage}
                noDataMessage={translations.ONLINK_NO_WORKBOARDS_EXIST}
            >
                <div
                    className='presentation-content-wrapper'
                >
                    <Header
                        isPreview={isPreview}
                        membership={workboardMembership}
                        translations={translations}
                        weather={weather}
                        workboard={workboard}
                    />
                    <div className='presentation-content'>
                        <MowingDirections
                            membership={workboardMembership}
                            membershipProperties={membershipProperties}
                            translations={translations}
                            workboard={workboard}
                        />
                        <JobsContainer
                            membership={workboardMembership}
                            translations={translations}
                            workboardData={workboard}
                        />
                    </div>
                </div>
            </NoDataMessage>
            <StandaloneBackground
                dataUrl={logoDataUrl}
                isPreview={isPreview}
                membership={workboardMembership}
                workboard={workboard}
            />
        </LoadingWrapper>
    );
}

Presentation.propTypes = {
    match: PropTypes.object,
    translations: PropTypes.translations,
    workboardMembership: PropTypes.object,
    wrapperRef: PropTypes.reference
};

export default withRouter(Presentation);
