// Unpublished Work © 2021-2024 Deere & Company.

import {Workbook} from 'exceljs';
import {autofitWorksheet, downloadExcelFile} from 'Utils/excel-utils';
import moment from 'moment';
import accounting from 'accounting';
import {sortBy} from 'lodash';
import {capitalizeFirstLetter} from 'Utils/translation-utils';

const HOURS_COLUMN = 8;
const EST_HRS_COLUMN = 6;
const ACT_HRS_COLUMN = 7;

async function exportFleetReport(fleet, translations) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(translations.EQUIPMENT_LIST, {
        views: [
            {
                state: 'frozen',
                ySplit: 1
            }
        ]
    });

    const header = worksheet.addRow([
        translations.NAME,
        translations.TYPE,
        translations.AREA,
        translations.BRAND,
        translations.MODEL,
        translations.ONLINK_SERIAL_NUMBER,
        translations.STATUS,
        translations.HOURS
    ]);

    header.font = {
        bold: true
    };

    worksheet.getColumn(HOURS_COLUMN).style.numFmt = '#,##0.0';

    const rows = fleet.map((equipment) => [
        equipment.equipmentName,
        equipment.equipmentType,
        equipment.equipmentArea,
        equipment.manufacturerName,
        equipment.modelDescription,
        equipment.serialNumber,
        equipment.formattedStatus,
        accounting.parse(equipment.totalHours)
    ]);

    worksheet.addRows(rows);

    autofitWorksheet(worksheet);

    await downloadExcelFile(workbook, `Fleet-Export${moment.utc().format('YYYY-MM-DD')}.xlsx`);
}

async function exportWorkboardReport(workboardReport, translations) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(translations.ONLINK_WORKBOARD_JOB_LIST, {
        views: [
            {
                state: 'frozen',
                ySplit: 1
            }
        ]
    });

    const header = worksheet.addRow([
        translations.OPERATOR,
        translations.DATE,
        translations.ONLINK_WORKBOARD,
        translations.jobs_job,
        translations.IWP_EQUIPMENT_LABEL,
        translations.ONLINK_ROUTE,
        translations.ONLINK_MOWING_DIRECTIONS,
        translations.ONLINK_EST_HRS,
        translations.ONLINK_ACT_HRS,
        translations.ONLINK_PRE_START,
        translations.NOTE
    ]);

    header.font = {
        bold: true
    };

    worksheet.getColumn(EST_HRS_COLUMN).style.numFmt = '#,##0.0';
    worksheet.getColumn(ACT_HRS_COLUMN).style.numFmt = '#,##0.0';

    const rows = workboardReport.map((job) => [
        job.operator,
        job.formattedDate,
        job.workboardTitle,
        job.title,
        job.equipmentNames,
        job.route,
        job.mowingDirection,
        job.estDuration,
        job.actualDuration,
        job.formattedPreStartCheck,
        job.operatorNote
    ]);

    worksheet.addRows(rows);

    autofitWorksheet(worksheet);

    await downloadExcelFile(workbook, `Workboard-Job-Export${moment.utc().format('YYYY-MM-DD')}.xlsx`);
}

function getDateFormatAndReportType(dateSelector, timeScale) {
    switch (timeScale) {
        case 'day':
            return {
                format: 'YYYY-MM-DD',
                reportType: 'DailyReport'
            };
        case 'year':
            return {
                format: 'YYYY',
                reportType: 'YearlyReport'
            };
        default:
            return {
                format: 'MMMM-YYYY',
                reportType: 'MonthlyReport'
            };
    }
}

async function exportLaborWorkboardsReport({
    columnHeaderName,
    dateSelector,
    timeScale,
    title,
    translations,
    workboardsReport,
    hasViewFinancialDataPermission
}) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(translations.ONLINK_WORKBOARDS, {
        views: [
            {
                state: 'frozen',
                ySplit: 1
            }
        ]
    });

    const header = worksheet.addRow(hasViewFinancialDataPermission ? [
        columnHeaderName,
        translations.ONLINK_EST_LABOR_HOURS,
        translations.ONLINK_ESTIMATED_COST,
        translations.ONLINK_ACTUAL_HOURS,
        translations.ONLINK_ACTUAL_COST,
        translations.ONLINK_OVERTIME_HOURS,
        translations.ONLINK_OVERTIME_COST
    ] : [
        columnHeaderName,
        translations.ONLINK_EST_LABOR_HOURS,
        translations.ONLINK_ACTUAL_HOURS,
        translations.ONLINK_OVERTIME_HOURS
    ]);

    header.font = {
        bold: true
    };

    const rows = workboardsReport.map((row) => hasViewFinancialDataPermission ? [
        row.name,
        row.estDuration,
        row.estLaborCost,
        row.actualDuration,
        row.laborCost,
        row.overtimeDuration,
        row.overtimeCost
    ] : [
        row.name,
        row.estDuration,
        row.actualDuration,
        row.overtimeDuration]
    );

    const {
        format,
        reportType
    } = getDateFormatAndReportType(dateSelector, timeScale);

    worksheet.addRows(rows);

    autofitWorksheet(worksheet);

    await downloadExcelFile(workbook, `${title}-${reportType}-${moment(dateSelector).format(format)}.xlsx`);
}

async function exportInventoryReport(partsData, translations, currencySymbol) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(translations.ONLINK_INVENTORY, {
        views: [
            {
                state: 'frozen',
                ySplit: 1
            }
        ]
    });

    const header = worksheet.addRow([
        translations.TYPE,
        translations.NAME,
        translations.ONLINK_LOCATION,
        translations.MANUFACTURER,
        capitalizeFirstLetter(translations.PART_NUMBER),
        `${translations.VALUE} (${currencySymbol})`,
        translations.ONLINK_STOCK,
        `${translations.ONLINK_IN}/${translations.ONLINK_OUT}`
    ]);

    header.font = {
        bold: true
    };

    const rows = sortBy(
        partsData,
        ['partType', (partData) => partData.name.toLowerCase()]
    ).map((row) => [
        row.partType,
        row.name,
        row.binLocation,
        row.manufacturerName,
        row.partNumber,
        row.formattedTotalCost,
        row.formattedStock,
        row.formattedInOutValue
    ]);

    worksheet.addRows(rows);

    autofitWorksheet(worksheet);

    await downloadExcelFile(workbook, `Inventory-Export-${moment.utc().format('YYYY-MM-DD')}.xlsx`);
}

export {
    exportInventoryReport,
    exportFleetReport,
    exportWorkboardReport,
    exportLaborWorkboardsReport
};
