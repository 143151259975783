// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {MultiSelect} from '@deere/isg.component-library/lib/Multiselect';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import SwitchInput from 'Ui/components/common/form/switch-input';
import {useSave} from 'Ui/react-hooks/use-save';
import {updateMembershipMowingDirections} from 'Services/membership-service';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import {updateMembership as updateMembershipRedux} from 'Store/actions/membership';
import dialogTypes from 'Ui/components/common/dialog-types';
import {AREAS} from 'Ui/constants/labor-constants';
import {cloneDeep, sortBy} from 'lodash';

function EditMowingDirectionDialog(props) {
    const {
        closeDialog,
        membership,
        mowingDirectionIndex,
        translations,
        updateMembership
    } = props;

    const mowingDirection = membership.mowingDirections[mowingDirectionIndex];

    const [inactive, setInactive] = React.useState(mowingDirection.inactive);
    const [selectedAreas, setSelectedAreas] = React.useState(() => [...mowingDirection.areasApplied]);
    const [saveFunc, disableSave, isSaving] = useSave(async () => {
        const clonedMowingDirections = cloneDeep(membership.mowingDirections);

        clonedMowingDirections[mowingDirectionIndex] = {
            ...mowingDirection,
            areasApplied: sortBy(selectedAreas),
            inactive
        };

        const membershipData = {
            ...membership,
            mowingDirections: clonedMowingDirections
        };

        await updateMembershipMowingDirections(membershipData, membership.membershipId);

        updateMembership(membershipData);

        closeDialog();
    });

    const areaItems = React.useMemo(() => AREAS.map((area) => ({
        id: area,
        title: translations[area]
    })), []);

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            className='edit-mowing-direction-dialog'
            closeHandler={closeDialog}
            disableSave={disableSave}
            footerLoading={isSaving}
            onSave={saveFunc}
            saveLabel={translations.save}
            title={translations.ONLINK_MOWING_DIRECTION}
            translations={translations}
        >
            <form
                className='settings-body'
                onSubmit={saveFunc}
            >
                <div className='mowing-direction-title'>
                    <div className='directions-label'>
                        {mowingDirection.direction}
                    </div>
                    <SwitchInput
                        checked={inactive}
                        className='mowing-direction-active-switch-input'
                        name='inactive'
                        offLabel={translations.inactive}
                        onChange={() => setInactive(!inactive)}
                        translations={translations}
                    />
                </div>
                <MultiSelect
                    disabled={false}
                    items={areaItems}
                    label={`${translations.ONLINK_APPLIES_TO}:`}
                    labels={{
                        allSelect: translations.ONLINK_ALL_AREAS,
                        multiSelect: translations.ONLINK_AREAS_SELECTED,
                        placeholder: translations.ONLINK_SELECT_AREAS,
                        selectAllItems: translations.ONLINK_ALL_AREAS
                    }}
                    onChange={setSelectedAreas}
                    selectedIds={selectedAreas}
                />
            </form>
        </FormDialog>
    );
}

EditMowingDirectionDialog.propTypes = {
    closeDialog: PropTypes.func,
    membership: PropTypes.membership,
    mowingDirectionIndex: PropTypes.number,
    translations: PropTypes.translations,
    updateMembership: PropTypes.func
};

export function mapStateToProps(state) {
    return {
        membership: state.membership,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.EDIT_MOWING_DIRECTION_DIALOG));
        },
        updateMembership(value) {
            dispatch(updateMembershipRedux(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMowingDirectionDialog);
