// Unpublished Work © 2023-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import DataTable from 'Ui/components/common/data-table/data-table';
import LoadingWrapper from 'Ui/components/common/loading-wrapper';
import MowerStatusBatteryLevel from 'Ui/components/home/electric-mower-status/mower-status-battery-level';
import NoDataMessage from 'Ui/components/panel/no-data-message';
import Panel from 'Ui/components/panel/panel';
import {getMyJDMachineBatteryMeasurement} from 'Services/my-jd-machine-service';
import {fetchEffectData} from 'Utils/react-utils';
import {getElectricMowerThreshold} from 'Utils/threshold-utils';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip/Tooltip';
import {formatTimeOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import {filterSuccessfulResponse, reduceResponseData} from 'Common/utils/response-mapper';
import {ELECTRIC_MACHINE_VIN_PREFIXES, VIN_PREFIX_LENGTH} from 'Ui/constants/equipment-constants';
import {EXTREME_BATTERY_THRESHOLD} from 'Ui/constants/threshold-constants';

function getColumns(translations) {
    return [
        {
            Header: translations.NAME,
            accessor: 'name',
            width: 130,
            Cell(row) {
                const {name} = row.original;

                return (
                    <Tooltip
                        placement='bottom'
                        title={name}
                    >
                        <span className='basic-table-cell-block'>
                            {name}
                        </span>
                    </Tooltip>
                );
            }
        },
        {
            Header: translations.ONLINK_BATTERY_LEVEL,
            accessor: 'value',
            width: 90,
            Cell(row) {
                const {
                    value: level, state
                } = row.original;

                const {backgroundClassName} = getElectricMowerThreshold(level);
                const lowBatteryWarning = level < EXTREME_BATTERY_THRESHOLD && state === 'working' ||
                    level < EXTREME_BATTERY_THRESHOLD && state === 'Not Charging';

                return (
                    <MowerStatusBatteryLevel
                        lowBatteryWarning={lowBatteryWarning}
                        row={row}
                        thresholdClassName={backgroundClassName}
                        translations={translations}
                    />
                );
            }
        },
        {
            Header: translations.ONLINK_MACHINE_STATE,
            accessor: 'state',
            width: 80
        },
        {
            Header: translations.LAST_UPDATED,
            accessor: 'formattedReportTime',
            Cell(row) {
                const {formattedReportTime} = row.original;

                return (
                    <Tooltip
                        placement='bottom'
                        title={formattedReportTime}
                    >
                        <span className='basic-table-cell-block'>{formattedReportTime}</span>
                    </Tooltip>
                );
            }
        }
    ];
}

function ElectricMowerStatus(props) {
    const {
        translations,
        tile: tileData
    } = props;

    const [loading, setLoading] = React.useState(false);
    const [aggregatedMachineData, setAggregatedMachineData] = React.useState([]);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const fleetEquipmentMap = new Map(tileData?.equipment?.map((equipment) => [equipment.serialNumber, equipment]));
        const machines = Array.isArray(tileData.myJdData) ? tileData.myJdData.filter(filterSuccessfulResponse)
            .reduce(reduceResponseData, []) : [];

        const electricMachines = machines.filter((machine) => {
            return fleetEquipmentMap.has(machine.vin) && ELECTRIC_MACHINE_VIN_PREFIXES.includes(machine?.vin?.slice(0, VIN_PREFIX_LENGTH));
        }).map((machine) => {
            return {
                id: machine.id,
                name: fleetEquipmentMap.get(machine.vin).equipmentName
            };
        });

        let aggregatedMachineData = [];

        if (electricMachines.length > 0) {
            const machineMeasurementRequestsPromises = electricMachines.map(async (machine) => {
                const machineAggregatedData = await getMyJDMachineBatteryMeasurement(machine.id);

                return machineAggregatedData?.value ? {
                    ...machineAggregatedData,
                    value: Math.round(machineAggregatedData.value),
                    name: machine.name,
                    formattedReportTime: formatTimeOrDefault(machineAggregatedData.lastUpdated),
                    state: translations[machineAggregatedData.state] || machineAggregatedData.state
                } : null;
            });

            const allMachineMeasurementRequests = await Promise.all(machineMeasurementRequestsPromises);

            aggregatedMachineData = allMachineMeasurementRequests.reduce((combinedMachineMeasurementRequests, machineMeasurementRequests) => combinedMachineMeasurementRequests.concat(machineMeasurementRequests), [])
                .filter((machineData) => machineData);
        }

        if (isMounted()) {
            setAggregatedMachineData(aggregatedMachineData);
            setLoading(false);
        }
    }), [tileData]);

    return (
        <Panel
            title={translations.ONLINK_ELECTRIC_MOWER_STATUS}
            translations={translations}
        >
            <LoadingWrapper
                className='onlink-loading-icon'
                loading={loading}
                size={'50px'}
            >
                {
                    aggregatedMachineData.length ? (
                        <DataTable
                            className='electric-mower-status-table'
                            columns={getColumns(translations)}
                            defaultSorted={[{
                                desc: true,
                                id: 'value'
                            }]}
                            noDataAvailableMessage={translations.NO_EQUIPMENT_FOUND}
                            rows={aggregatedMachineData}
                            translations={translations}
                        />
                    ) : <NoDataMessage translations={translations}/>
                }
            </LoadingWrapper>
        </Panel>
    );
}

ElectricMowerStatus.propTypes = {
    tile: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object)
    ]),
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        translations: state.translations
    };
}

export default connect(mapStateToProps)(ElectricMowerStatus);
