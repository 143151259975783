// Unpublished Work © 2020-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import EditableRoleSettings from 'Ui/components/settings/users/editable-role-settings';
import FormDialog from 'Ui/components/common/form-dialog/form-dialog';
import FormValidator from 'Ui/components/higher-order-components/form-validator';
import {createRole, updateRoleById} from 'Services/role-service';
import {closeDialog as closeReduxDialog} from 'Store/actions/dialogs';
import {updateUserRoles} from 'Store/actions/role';
import dialogTypes from 'Ui/components/common/dialog-types';
import {cloneDeep, sortBy} from 'lodash';

function RoleEditDialog(props) {
    const {
        closeDialog,
        invalidInputs,
        membershipId,
        role,
        setValid,
        translations,
        updateRoles,
        userRoles
    } = props;

    const disableSave = invalidInputs.size > 0;

    const [name, setName] = React.useState(() => role && role.title || '');
    const [permissions, setPermissions] = React.useState(() => {
        const rolePermissions = role && role.permissions || [];

        return new Set(rolePermissions);
    });

    function togglePermission(permission, checked) {
        const clonedPermissions = cloneDeep(permissions);

        checked ?
            clonedPermissions.add(permission) :
            clonedPermissions.delete(permission);

        setPermissions(clonedPermissions);
    }

    async function saveRole() {
        const roleData = {
            title: name,
            permissions: Array.from(permissions)
        };

        if (role) {
            return await updateRoleById(roleData, role.userRoleId);
        }

        return await createRole({
            ...roleData,
            membershipId
        });
    }

    async function onSave(event) {
        event.preventDefault();

        if (!disableSave) {
            const {userRole} = await saveRole();

            const clonedRoles = cloneDeep(userRoles);
            const index = clonedRoles.findIndex((clonedRole) => userRole.userRoleId === clonedRole.userRoleId);

            index === -1 ?
                clonedRoles.push(userRole) :
                clonedRoles.splice(index, 1, userRole);

            const sortedRoles = sortBy(clonedRoles, 'title');

            updateRoles(sortedRoles);

            closeDialog();
        }
    }

    const title = role ? translations.staff_edit_role : translations.ONLINK_ADD_ROLE;

    return (
        <FormDialog
            cancelLabel={translations.CANCEL}
            closeHandler={closeDialog}
            disableSave={disableSave}
            onSave={onSave}
            saveLabel={translations.save}
            title={title}
            translations={translations}
        >
            <EditableRoleSettings
                name={name}
                onChange={(event) => setName(event.target.value)}
                onSave={onSave}
                permissions={permissions}
                setValid={setValid}
                togglePermission={togglePermission}
                translations={translations}
            />
        </FormDialog>
    );
}

RoleEditDialog.propTypes = {
    closeDialog: PropTypes.func,
    invalidInputs: PropTypes.instanceOf(Set),
    membershipId: PropTypes.string,
    role: PropTypes.object,
    setValid: PropTypes.func,
    translations: PropTypes.translations,
    updateRoles: PropTypes.func,
    userRoles: PropTypes.arrayOf(PropTypes.object)
};

export function mapStateToProps(state) {
    return {
        membershipId: state.membership.membershipId,
        userRoles: state.role.userRoles,
        translations: state.translations
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        closeDialog() {
            dispatch(closeReduxDialog(dialogTypes.ROLE_EDIT_DIALOG));
        },
        updateRoles(value) {
            dispatch(updateUserRoles(value));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormValidator(RoleEditDialog));
