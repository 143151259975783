// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import OnLinkDialog from 'Ui/components/common/onlink-dialog/onlink-dialog';
import {getEquipmentDetailsTabs} from 'OnEquip/equipment/equipment-details/utils/equipment-details-helper';
import {getEquipment} from 'Services/equipment-service';
import {fetchEffectData} from 'Utils/react-utils';
import {redirect as redirectTo} from 'Utils/redirect-utils';
import {useEquipmentContext} from 'OnEquip/equipment/context/equipment-context';
import {EQUIPMENT_DETAILS} from 'Common/constants/routes';
import {getHierarchy} from 'OnEquip/equipment/utils/membership-hierarchy-utils';
import {VIEW_EQUIPMENT_DETAILS} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';

function initializeState() {
    const [equipment, setEquipment] = React.useState({});
    const [membershipHierarchy, setMembershipHierarchy] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    return {
        equipment,
        setEquipment,
        loading,
        setLoading,
        membershipHierarchy,
        setMembershipHierarchy
    };
}

function EquipmentDetails(props) {
    const {
        appUserMembershipId,
        equipmentId,
        featureToggles,
        history,
        redirect,
        superUser,
        tab,
        translations,
        isMigrated,
        myJdPermissions
    } = props;

    const {
        equipment,
        setEquipment,
        loading,
        setLoading,
        membershipHierarchy,
        setMembershipHierarchy
    } = initializeState();

    const canViewEquipmentDetails = isAuthorized(
        {
            myJdPermissions: VIEW_EQUIPMENT_DETAILS
        }, {
            isMigrated,
            myJdPermissions
        }
    );

    async function getEquipmentCall(isMounted = () => true) {
        setLoading(true);

        const equipment = await getEquipment(equipmentId);

        const hierarchy = await getHierarchy(superUser, appUserMembershipId);

        if (isMounted()) {
            setMembershipHierarchy(hierarchy);
            setEquipment(equipment);
            setLoading(false);
        }
    }

    const equipmentContext = useEquipmentContext();

    function updateEquipment() {
        getEquipmentCall();
        equipmentContext.current();
    }

    React.useEffect(() => {
        if (canViewEquipmentDetails) {
            fetchEffectData(getEquipmentCall);
        }
    }, [equipmentId, appUserMembershipId, canViewEquipmentDetails]);

    const tabs = getEquipmentDetailsTabs(translations, equipment, membershipHierarchy, loading, updateEquipment, featureToggles, isMigrated, myJdPermissions);

    const tabsWithPath = tabs.map((item) => ({
        ...item,
        path: `${redirect.path}${EQUIPMENT_DETAILS}/${item.id}`
    }));

    return (
        <OnLinkDialog
            activeTab={tab}
            className='equipment-details settings-dialog'
            closeHandler={() => redirectTo(redirect.path, history)}
            content={tabsWithPath}
            large={true}
            onTabClick={(tabId) => redirectTo(`${redirect.path}${EQUIPMENT_DETAILS}/${tabId}?equipmentId=${equipmentId}`, history)}
            routable={true}
            showError={!canViewEquipmentDetails}
            title={translations.equipment_details}
        />
    );
}

EquipmentDetails.propTypes = {
    appUserMembershipId: PropTypes.string,
    equipmentId: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    history: PropTypes.history,
    isMigrated: PropTypes.bool,
    myJdPermissions: PropTypes.myJdPermissions,
    redirect: PropTypes.object,
    superUser: PropTypes.bool,
    tab: PropTypes.string,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        appUserMembershipId: state.account.membershipId,
        featureToggles: state.account.featureToggles,
        superUser: state.account.superUser,
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export default connect(mapStateToProps)(withRouter(EquipmentDetails));
