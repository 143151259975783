// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DataTable from 'Ui/components/common/data-table/data-table';
import {fetchEffectData} from 'Utils/react-utils';
import {alphaNumericCompare, dateCompare} from 'Ui/models/maintenance';
import AdditionalActionButton from 'OnEquip/equipment/common/additional-action-button';
import {exportWorkboardReport} from 'Services/excel-service';
import {IconDownload} from '@deere/icons';
import {getJobsBetweenDates} from 'Services/job-service';
import {formatTime} from 'Utils/time-utils';
import moment from 'moment';
import {getFormattedJobs} from 'OnLabor/report/utils/workboard-report-util';
import DateRangePicker from 'Ui/components/common/date-range-picker/date-range-picker';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import {VIEW_WORKBOARD_SUMMARY_DATA} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';

const DEFAULT_START_TIME = 30;
const SEVEN_DAYS = 7;
const DEFAULT_LAST_30_DAYS = 7;

function getDateRangeOptions(translations) {
    return [
        {
            id: 2,
            title: translations.days_Today,
            startDate: moment(),
            endDate: moment()
        },
        {
            id: 3,
            title: translations.YESTERDAY,
            startDate: moment().subtract(1, 'days'),
            endDate: moment().subtract(1, 'days')
        },
        {
            id: 4,
            title: translations.THIS_WEEK,
            startDate: moment().startOf('week'),
            endDate: moment().endOf('week')
        },
        {
            id: 5,
            title: translations.LAST_WEEK,
            startDate: moment().subtract(1, 'weeks').startOf('week'),
            endDate: moment().subtract(1, 'weeks').endOf('week')
        },
        {
            id: 6,
            title: translations.LAST_7_DAYS,
            startDate: moment().subtract(SEVEN_DAYS, 'days'),
            endDate: moment()
        },
        {
            id: DEFAULT_LAST_30_DAYS,
            title: translations.LAST_30_DAYS,
            startDate: moment().subtract(DEFAULT_START_TIME, 'days'),
            endDate: moment()
        },
        {
            id: 8,
            title: translations.THIS_MONTH,
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month')
        },
        {
            id: 9,
            title: translations.LAST_MONTH,
            startDate: moment().subtract(1, 'months').startOf('month'),
            endDate: moment().subtract(1, 'months').endOf('month')
        }
    ];
}

function getColumns(translations) {
    return [
        {
            Header: translations.OPERATOR,
            accessor: 'operator',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.DATE,
            accessor: 'formattedDate',
            sortMethod: dateCompare
        },
        {
            Header: translations.ONLINK_WORKBOARD,
            accessor: 'workboardTitle',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.jobs_job,
            accessor: 'title',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.EQUIPMENT_LIST,
            accessor: 'equipmentNames',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.ONLINK_ROUTE,
            accessor: 'route',
            sortMethod: alphaNumericCompare,
            width: 80
        },
        {
            Header: translations.ONLINK_MOWING_DIRECTIONS,
            accessor: 'mowingDirection',
            sortMethod: alphaNumericCompare,
            width: 130
        },
        {
            Header: translations.ONLINK_EST_HRS,
            accessor: 'estDuration',
            sortMethod: alphaNumericCompare,
            width: 80
        },
        {
            Header: translations.ONLINK_ACT_HRS,
            accessor: 'actualDuration',
            sortMethod: alphaNumericCompare,
            width: 80
        },
        {
            Header: translations.ONLINK_PRE_START,
            accessor: 'formattedPreStartCheck'
        },
        {
            Header: translations.NOTE,
            accessor: 'operatorNote'
        }
    ];
}

function getAdditionalActionButtons(translations, workboardReport, loading, setDateRange, featureToggles, hasExportDataPermission) {
    return (
        <div className='extra-table-options'>
            <DateRangePicker
                dateRangeOptions={getDateRangeOptions(translations)}
                defaultSelectionId={DEFAULT_LAST_30_DAYS}
                setDateRange={setDateRange}
                showLabel={false}
                translations={translations}
            />
            {
                !featureToggles[ONLINK_NAVIGATION_REDESIGN] && hasExportDataPermission &&
                <div className='export-button'>
                    <AdditionalActionButton
                        className='export-report'
                        disabled={loading}
                        label={translations.EXPORT}
                        onClick={() => exportWorkboardReport(workboardReport, translations)}
                    >
                        <IconDownload
                            iconDownload={{
                                style: {
                                    height: '20px',
                                    width: '20px'
                                }
                            }}
                        />
                    </AdditionalActionButton>
                </div>
            }
        </div>
    );
}

function initializeState() {
    const [workboardReport, setWorkboardReport] = React.useState(() => []);
    const [loading, setLoading] = React.useState(() => true);
    const [dateRange, setDateRange] = React.useState({
        startDate: moment().subtract(DEFAULT_START_TIME, 'days'),
        endDate: moment()
    });

    return {
        workboardReport,
        setWorkboardReport,
        loading,
        setLoading,
        dateRange,
        setDateRange
    };
}

function WorkboardReport(props) {
    const {
        membershipId,
        translations,
        featureToggles,
        isMigrated,
        myJdPermissions
    } = props;

    const {
        workboardReport,
        setWorkboardReport,
        loading,
        setLoading,
        dateRange,
        setDateRange
    } = initializeState();

    const hasExportDataPermission = React.useMemo(() => isAuthorized({
        myJdPermissions: VIEW_WORKBOARD_SUMMARY_DATA
    }, {
        myJdPermissions,
        isMigrated
    }), [isMigrated, myJdPermissions]);

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        if (isMounted()) {
            const endDate = formatTime(dateRange.endDate, 'yyyyMMDD');
            const startDate = formatTime(dateRange.startDate, 'yyyyMMDD');
            const {jobList} = await getJobsBetweenDates(startDate, endDate);

            const workboardReportData = getFormattedJobs(jobList, translations);

            setWorkboardReport(workboardReportData);
            setLoading(false);
        }
    }), [membershipId, dateRange]);

    useNavBarActions(featureToggles[ONLINK_NAVIGATION_REDESIGN] && hasExportDataPermission ? [{
        Icon: <IconDownload
            iconDownload={{
                style: {
                    height: '20px',
                    width: '20px',
                    fill: '#fff'
                }
            }}
        />,
        onClick: () => exportWorkboardReport(workboardReport, translations),
        title: 'EXPORT',
        variant: 'primary'
    }] : []);

    return (
        <div className='workboard-report-container'>
            <div className='onlink-tile workboard-report-tile'>
                <DataTable
                    columns={getColumns(translations)}
                    defaultSorted={[{
                        desc: true,
                        id: 'formattedDate'
                    }]}
                    filterComponent={getAdditionalActionButtons(translations, workboardReport, loading, setDateRange, featureToggles, hasExportDataPermission)}
                    loading={loading}
                    rows={workboardReport}
                    searchable={true}
                    showDeleteColumn={false}
                    showPagination={true}
                    translations={translations}
                />
            </div>
        </div>
    );
}

WorkboardReport.propTypes = {
    featureToggles: PropTypes.featureToggles,
    isMigrated: PropTypes.bool,
    membershipId: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        membershipId: state.membership.membershipId,
        featureToggles: state.account.featureToggles,
        isMigrated: state.membership.isMigrated,
        myJdPermissions: state.account.myJdPermissions
    };
}

export default connect(mapStateToProps)(withRouter(WorkboardReport));
