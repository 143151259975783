// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import {Datepicker, TextArea} from '@deere/form-controls';
import ValidationInput from 'Ui/components/common/form/validation-input';
import {getLocalTimeFormats, validateDate} from 'Ui/components/manual-data/manual-data-utils';
import {MANUAL_DATA_TIME_FORMAT} from 'Utils/time-utils';
import {getLanguagePreference} from 'Utils/unit-conversion-utils';
import moment from 'moment';
import {fetchOperators} from 'OnLabor/workboard/utils/fetch-operators';
import ReadOnlyWrapperComponent from 'Ui/components/common/form/read-only/read-only-wrapper-component';

function enforceMinMaxDate(isEdit, date) {
    if (isEdit) {
        return {
            minDate: date,
            maxDate: date
        };
    }

    return {};
}

function WorkboardDetailsForm(props) {
    const {
        isMobile,
        setLoading,
        setOperators,
        setValid,
        setValues,
        translations,
        values,
        isEdit,
        readOnly
    } = props;

    const validatedDate = React.useMemo(() => validateDate(values.date, setValid), [values.date]);

    function onValuesChange(name, value) {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    }

    function onChange(event) {
        const {
            name, value
        } = event.target;

        onValuesChange(name, value);
    }

    async function workboardDateChange(formattedDate) {
        onValuesChange('date', formattedDate);

        if (validateDate(formattedDate, setValid)) {
            setLoading((prevValues) => ({
                ...prevValues,
                operators: true
            }));

            await fetchOperators({
                date: formattedDate,
                isMounted: () => true,
                setOperators
            });

            setLoading((prevValues) => ({
                ...prevValues,
                operators: false
            }));
        }
    }

    async function workboardDateChangeRaw(date) {
        let updatedDate = date;

        if (isEdit) {
            updatedDate = `${moment(validatedDate).format('YYYY-MM-DD')} ${moment(date).format('HH:mm:ss')}`;
        }

        await workboardDateChange(moment(updatedDate).format(MANUAL_DATA_TIME_FORMAT));
    }

    return (
        <div className='add-workboard-form workboard-details-form'>
            <ReadOnlyWrapperComponent
                label={translations.NAME}
                readOnly={readOnly}
                readOnlyProps={{
                    readOnlyLabelClassName: 'title-1',
                    readOnlyValueClassName: 'workboard-details-readonly-value'
                }}
                value={values.name || ''}
                wrappedComponent={ValidationInput}
                wrappedProps={{
                    name: 'name',
                    onChange,
                    required: true,
                    setValid,
                    tabIndex: 0,
                    type: 'text'
                }}
            />
            <ReadOnlyWrapperComponent
                label={translations.DATE_AND_TIME}
                readOnly={readOnly}
                readOnlyProps={{
                    readOnlyLabelClassName: 'title-1',
                    readOnlyValueClassName: 'workboard-details-readonly-value',
                    value: moment(values.date).format('LLL')
                }}
                wrappedComponent={Datepicker}
                wrappedProps={{
                    dateFormat: 'LLL',
                    error: validatedDate ? '' : translations.INVALIDATED_DATE,
                    ...enforceMinMaxDate(isEdit, validatedDate),
                    large: !isMobile,
                    locale: getLanguagePreference(),
                    onChange: (date) => workboardDateChange(date?.format(MANUAL_DATA_TIME_FORMAT)),
                    onChangeRaw: (event) => workboardDateChangeRaw(event.target.value),
                    selected: validatedDate,
                    showTimeSelect: true,
                    timeFormat: getLocalTimeFormats(),
                    timeIntervals: 15
                }}
            />
            <ReadOnlyWrapperComponent
                label={translations.NOTE}
                readOnly={readOnly}
                readOnlyProps={{
                    readOnlyLabelClassName: 'title-1',
                    readOnlyValueClassName: 'workboard-details-readonly-value'
                }}
                value={values.note || ''}
                wrappedComponent={TextArea}
                wrappedProps={{
                    className: 'notes-text-area',
                    name: 'note',
                    onChange,
                    rows: 3,
                    tabIndex: 0
                }}
            />
        </div>
    );
}

WorkboardDetailsForm.propTypes = {
    isEdit: PropTypes.bool,
    isMobile: PropTypes.bool,
    readOnly: PropTypes.bool,
    setLoading: PropTypes.func,
    setOperators: PropTypes.func,
    setValid: PropTypes.func,
    setValues: PropTypes.func,
    translations: PropTypes.translations,
    values: PropTypes.object
};

export default MediaQuery(MOBILE_MEDIA_QUERY)(WorkboardDetailsForm);
