// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import SearchFilter from 'Ui/components/higher-order-components/search-filter';
import EquipmentList from 'Ui/components/map/sidebar/equipment-list';
import EquipmentListItem from 'Ui/components/map/sidebar/equipment-list-item';
import MachineStateLayer from 'Ui/components/map/location-history/machine-state-layer';
import MapLegend from 'Ui/components/map/map-elements/map-legend';
import MapPage from 'Ui/components/map/map-page';
import MapWrapper from 'Ui/components/map/map-elements/map-wrapper';
import PlaybackControls from 'Ui/components/map/playback-controls/playback-controls';
import {getMemoizedData} from 'Ui/components/map/elements/common/filter-helpers';
import {getMyJDBreadcrumbs, getMyJDMachinesWithBreadcrumbs} from 'Services/my-jd-machine-service';
import {fetchEffectData, useDeepEffect, useDeepMemo} from 'Utils/react-utils';
import {updateModelFilterSelectedIDs, updateTypeFilterSelectedIDs} from 'Store/actions/map';
import moment from 'moment';

function getEquipmentListItems(fleet, selectedEquipment, onEquipmentSelect, translations) {
    return fleet.map((equipment) => (
        <EquipmentListItem
            active={selectedEquipment && equipment.equipmentId === selectedEquipment.equipmentId}
            equipment={equipment}
            key={equipment.serialNumber}
            onEquipmentSelect={onEquipmentSelect}
            translations={translations}
        />
    ));
}

async function getFleetBreadcrumbsData(fleet, dateSelected) {
    const machineIds = fleet.map((equipment) => equipment.myJDId);
    const machinesWithBreadcrumbs = await getMyJDMachinesWithBreadcrumbs(machineIds, dateSelected);

    return fleet.filter((equipment) => machinesWithBreadcrumbs[equipment.myJDId]);
}

function clearSearch(onSearchChange) {
    onSearchChange({
        target: {
            value: ''
        }
    });
}

function initializeState() {
    const [fleetWithBreadcrumbs, setFleetWithBreadcrumbs] = React.useState(() => []);
    const [breadcrumbs, setBreadcrumbs] = React.useState(() => []);
    const [dateSelected, setDate] = React.useState(moment());
    const [trackerValue, setTrackerValue] = React.useState(() => {});

    return {
        fleetWithBreadcrumbs,
        setFleetWithBreadcrumbs,
        breadcrumbs,
        setBreadcrumbs,
        dateSelected,
        setDate,
        trackerValue,
        setTrackerValue
    };
}

function initializeUseEffects(fleetWithMembership, dateSelected, selectedEquipment, filteredFleet, callbacks) {
    const {
        setBreadcrumbsLoading,
        setBreadcrumbs,
        setFleetWithBreadcrumbs,
        setSelectedEquipment,
        onSearchChange
    } = callbacks;

    React.useEffect(() => {
        clearSearch(onSearchChange);
    }, [fleetWithMembership.membership]);

    useDeepEffect(() => fetchEffectData(async (isMounted) => {
        setBreadcrumbsLoading(true);

        if (fleetWithMembership.fleet) {
            const fleetWithBreadcrumbsData = await getFleetBreadcrumbsData(fleetWithMembership.fleet, dateSelected);

            if (isMounted()) {
                setFleetWithBreadcrumbs(fleetWithBreadcrumbsData);
                setBreadcrumbs([]);
                setSelectedEquipment(null);
                clearSearch(onSearchChange);
            }
        }

        if (isMounted()) {
            setBreadcrumbsLoading(false);
        }
    }), [dateSelected, fleetWithMembership.fleet]);

    React.useEffect(() => {
        if (selectedEquipment && !filteredFleet.some((fleet) => fleet.equipmentId === selectedEquipment.equipmentId)) {
            setSelectedEquipment(null);
            setBreadcrumbs([]);
        }
    }, [filteredFleet]);
}

function getEquipmentWithBreadCrumbs(equipment, trackerValue) {
    const equipmentInfo = {
        equipmentName: equipment ? equipment.equipmentName : null,
        icon: equipment ? equipment.icon : null
    };

    return {
        ...equipmentInfo,
        ...trackerValue
    };
}

function LocationHistoryPage(props) {
    const {
        applySearch,
        bottomSheetRef,
        breadcrumbsLoading,
        closeBottomSheet,
        featureToggles,
        fleetWithMembership,
        isMobile,
        loading,
        map,
        modelFilterSelectedIDs,
        onSearchChange,
        search,
        setBreadcrumbsLoading,
        selectedEquipment,
        setMap,
        setSelectedEquipment,
        translations,
        typeFilterSelectedIDs,
        updateModelFilterSelectedIDs,
        updateTypeFilterSelectedIDs,
        isMigrated
    } = props;

    const {
        fleetWithBreadcrumbs,
        setFleetWithBreadcrumbs,
        breadcrumbs,
        setBreadcrumbs,
        dateSelected,
        setDate,
        trackerValue,
        setTrackerValue
    } = initializeState();

    async function onEquipmentSelect(equipment) {
        setBreadcrumbsLoading(true);
        setSelectedEquipment(null);
        closeBottomSheet();

        const breadcrumbsData = await getMyJDBreadcrumbs(equipment.myJDId, dateSelected);
        const breadcrumbsPoints = breadcrumbsData.map((breadcrumb) => ({
            lat: breadcrumb.point.lat,
            lng: breadcrumb.point.lon,
            meta: breadcrumb
        }));

        const tracker = {
            lat: breadcrumbsPoints[breadcrumbsPoints.length - 1].lat,
            lon: breadcrumbsPoints[breadcrumbsPoints.length - 1].lng,
            timeStamp: breadcrumbsPoints[breadcrumbsPoints.length - 1].meta.eventTimestamp
        };

        setBreadcrumbs(breadcrumbsPoints);
        setSelectedEquipment(equipment);
        setTrackerValue(tracker);
        setBreadcrumbsLoading(false);
    }

    const {
        equipmentTypes,
        filteredFleet,
        fleetWithLocationsAndMembership,
        modelNames
    } = getMemoizedData(
        fleetWithBreadcrumbs,
        search,
        modelFilterSelectedIDs,
        typeFilterSelectedIDs,
        {
            applySearch,
            updateTypeFilterSelectedIDs,
            updateModelFilterSelectedIDs
        },
        fleetWithMembership.membership
    );

    initializeUseEffects(fleetWithMembership, dateSelected, selectedEquipment, filteredFleet, {
        setBreadcrumbsLoading,
        setBreadcrumbs,
        setFleetWithBreadcrumbs,
        setSelectedEquipment,
        onSearchChange
    });

    const equipmentList = (
        <EquipmentList
            dateSelected={dateSelected}
            equipmentTypes={equipmentTypes}
            loading={loading || breadcrumbsLoading}
            modelNames={modelNames}
            onSearchChange={onSearchChange}
            search={search}
            setDate={setDate}
            showDateFilter={true}
            translations={translations}
        >
            {getEquipmentListItems(filteredFleet, selectedEquipment, onEquipmentSelect, translations)}
        </EquipmentList>
    );

    const equipmentWithBreadCrumbs = useDeepMemo(
        () => getEquipmentWithBreadCrumbs(selectedEquipment, trackerValue),
        [selectedEquipment, trackerValue]
    );

    const playbackControls = selectedEquipment && (
        <PlaybackControls
            breadcrumbs={breadcrumbs}
            onChange={setTrackerValue}
            selectedEquipment={selectedEquipment}
        />
    );

    const mapWrapper = (
        <MapWrapper
            isMigrated={isMigrated}
            itemLocations={breadcrumbs.length ? breadcrumbs : fleetWithLocationsAndMembership}
            loading={loading || breadcrumbsLoading}
            map={map}
            playbackControls={isMobile ? playbackControls : null}
            setMap={setMap}
            translations={translations}
        >
            <MachineStateLayer
                breadcrumbs={breadcrumbs}
                equipment={equipmentWithBreadCrumbs}
                featureToggles={featureToggles}
                translations={translations}
            />
            <MapLegend translations={translations}/>
            {isMobile ? null : playbackControls}
        </MapWrapper>
    );

    return (
        <MapPage
            bottomSheetRef={bottomSheetRef}
            featureToggles={featureToggles}
            listComponent={equipmentList}
            mapComponent={mapWrapper}
        />
    );
}

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        modelFilterSelectedIDs: state.map.modelFilterSelectedIDs,
        typeFilterSelectedIDs: state.map.typeFilterSelectedIDs,
        featureToggles: state.account.featureToggles
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        updateModelFilterSelectedIDs(value) {
            dispatch(updateModelFilterSelectedIDs(value));
        },
        updateTypeFilterSelectedIDs(value) {
            dispatch(updateTypeFilterSelectedIDs(value));
        }
    };
}

LocationHistoryPage.propTypes = {
    applySearch: PropTypes.func,
    bottomSheetRef: PropTypes.reference,
    breadcrumbsLoading: PropTypes.bool,
    closeBottomSheet: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    fleetWithMembership: PropTypes.itemLocationsWithMembership,
    isMigrated: PropTypes.bool,
    isMobile: PropTypes.bool,
    loading: PropTypes.bool,
    map: PropTypes.map,
    modelFilterSelectedIDs: PropTypes.arrayOf(PropTypes.string),
    onSearchChange: PropTypes.func,
    search: PropTypes.string,
    selectedEquipment: PropTypes.equipment,
    setBreadcrumbsLoading: PropTypes.func,
    setMap: PropTypes.func,
    setSelectedEquipment: PropTypes.func,
    translations: PropTypes.translations,
    typeFilterSelectedIDs: PropTypes.arrayOf(PropTypes.string),
    updateModelFilterSelectedIDs: PropTypes.func,
    updateTypeFilterSelectedIDs: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter(MediaQuery(MOBILE_MEDIA_QUERY)(LocationHistoryPage)));
