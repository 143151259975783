// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import {EXPENSES, HISTORY, JOBS, MAINTENANCE, PARTS} from 'OnEquip/equipment/constants/equipment-details-tabs';
import Header from 'OnEquip/equipment/equipment-details/header/header';
import EquipmentTab from 'OnEquip/equipment/equipment-details/equipment-tab';
import * as history from 'OnEquip/equipment/equipment-details/utils/tabs/history';
import * as maintenance from 'OnEquip/equipment/equipment-details/utils/tabs/maintenance';
import * as expenses from 'OnEquip/equipment/equipment-details/utils/tabs/expenses';
import SecondaryHeader from 'OnEquip/equipment/equipment-details/header/secondary-header';
import * as jobs from 'OnEquip/equipment/equipment-details/utils/tabs/jobs';
import * as parts from 'OnEquip/equipment/equipment-details/utils/tabs/parts';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {
    EDIT_EQUIPMENT_LIST,
    MODIFY_FINANCIAL_DATA,
    TERMINAL_TRANSFER,
    VIEW_FINANCIAL_DATA
} from 'Common/constants/business-activities';

function createTab(sharedProps, tabTableData, tabId, tabLabel) {
    return {
        element: (
            <EquipmentTab
                tableProps={{
                    ...sharedProps,
                    ...tabTableData
                }}
            />
        ),
        id: tabId,
        label: tabLabel
    };
}

function getTabColumns(translations, servicePriority, updateEquipment, equipment, featureToggles, membershipHierarchy, {
    canEditEquipmentList,
    canModifyFinancialData,
    canTransfer
}) {
    const {
        typesSelected,
        typeFilterDropDown
    } = history.getFilterComponent(translations);

    const historyFilterComponent = (
        <SecondaryHeader
            equipment={equipment}
            membershipHierarchy={membershipHierarchy}
            tabSpecificComponent={typeFilterDropDown}
            translations={translations}
            updateEquipment={updateEquipment}
            userAuth={{
                canTransfer,
                canModifyFinancialData,
                canEditEquipmentList
            }}
        />
    );

    return {
        expenses: {
            columns: expenses.getColumns(translations),
            rowsData: () => expenses.getRowData(equipment, translations),
            defaultSorted: [{
                desc: true,
                id: 'formattedDateSelect'
            }],
            className: 'expenses'
        },
        history: {
            columns: history.getColumns(translations, updateEquipment),
            rowsData: () => history.getRowData(equipment, translations),
            showPagination: true,
            defaultSorted: [{
                desc: true,
                id: 'formattedTimeOfEvent'
            }],
            className: 'history',
            filterComponent: historyFilterComponent,
            filterList: typesSelected,
            filterData: (history) => history.filter((row) => typesSelected.includes(row.type))
        },
        jobs: {
            columns: jobs.getColumns(translations),
            rowsData: () => jobs.getRowData(equipment, translations),
            showPagination: true,
            defaultSorted: [{
                desc: true,
                id: 'formattedDateSelect'
            }],
            className: 'jobs'
        },
        maintenance: {
            columns: maintenance.getColumns(translations, updateEquipment),
            rowsData: () => maintenance.getRows(servicePriority, translations),
            defaultSorted: [{
                desc: false,
                id: 'dueProgressBar'
            }],
            className: 'maintenance'
        },
        parts: {
            columns: parts.getColumns(translations),
            noDataAvailableMessage: translations.ONLINK_NO_PARTS_FOUND,
            rowsData: () => parts.getRowData(equipment),
            defaultSorted: [{
                desc: false,
                id: 'partName'
            }],
            className: 'parts'
        }
    };
}

function createDialogTabs(sharedProps, translations, tabColumns, canViewFinancialData) {
    const {
        expenses,
        history,
        jobs,
        maintenance,
        parts
    } = tabColumns;

    const equipmentDetailsTabs = [];

    equipmentDetailsTabs.push(createTab(sharedProps, maintenance, MAINTENANCE, translations.ONLINK_MAINTENANCE));
    equipmentDetailsTabs.push(createTab(sharedProps, history, HISTORY, translations.MACHINE_INSIGHTS_HISTORY));
    equipmentDetailsTabs.push(createTab(sharedProps, jobs, JOBS, translations.JOBS));
    equipmentDetailsTabs.push(createTab(sharedProps, parts, PARTS, translations.PARTS));
    if (canViewFinancialData) {
        equipmentDetailsTabs.push(createTab(sharedProps, expenses, EXPENSES, translations.ONLINK_EXPENSES));
    }

    return equipmentDetailsTabs;
}

export function getEquipmentDetailsTabs(translations, equipmentObject, membershipHierarchy, loading,
    updateEquipment, featureToggles, isMigrated, myJdPermissions
) {
    const [canViewFinancialData, canEditEquipmentList, canModifyFinancialData, canTransfer] = [
        VIEW_FINANCIAL_DATA,
        EDIT_EQUIPMENT_LIST,
        MODIFY_FINANCIAL_DATA,
        TERMINAL_TRANSFER
    ].map((permission) => isAuthorized({
        myJdPermissions: permission
    }, {
        isMigrated,
        myJdPermissions
    }));

    const {
        equipment,
        servicePriority
    } = equipmentObject;

    const header = (
        <Header
            canEditEquipmentList={canEditEquipmentList}
            equipment={equipment}
            translations={translations}
            updateEquipment={updateEquipment}
        />
    );

    const filterComponent = (
        <SecondaryHeader
            equipment={equipment}
            membershipHierarchy={membershipHierarchy}
            translations={translations}
            updateEquipment={updateEquipment}
            userAuth={{
                canTransfer,
                canModifyFinancialData,
                canEditEquipmentList
            }}
        />
    );

    const sharedProps = {
        equipment,
        filterComponent,
        headerComponent: header,
        loading,
        searchable: true,
        translations
    };

    const tabColumns = getTabColumns(translations, servicePriority, updateEquipment, equipmentObject.equipment, featureToggles, membershipHierarchy, {
        canEditEquipmentList,
        canModifyFinancialData,
        canTransfer
    });

    return createDialogTabs(sharedProps, translations, tabColumns, canViewFinancialData);
}
